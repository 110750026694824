import { Avatar, CardContent, CardHeader, Card, IconButton, Popover, ListItem, List, Divider, ListItemText, Box, Typography, Tooltip, useTheme, ListItemSecondaryAction } from "@mui/material";
import { useUser } from "../../../react-submodules/providers/User";
import { AnyBadge } from "../../../react-submodules/components/AnyBadge";
import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { Timeclock } from "./Timeclock";
import { ActiveUsersQuery, useActiveUsersQuery } from "../gql/ActiveUsers.generated";
import { CONFLICT_COLOR, AZURE_COLOR, SCHEDULE_COLOR, UNSET_COLOR, AZURE_COLOR_HOVER, SCHEDULE_COLOR_HOVER } from "../../../react-submodules/utils/schedule";
import { CALENDAR_ICON, FIRST_UP_ICON, PHONE_ICON, TICKET_ICON } from "../../../react-submodules/constances/icons";
import { InlineIcon , Icon } from "@iconify/react/dist/iconify.js";

import { PopoverInner } from "./PopoverInner";

type UserItem = NonNullable<ActiveUsersQuery['findCoreUsersPaginated']['data']>[number];

const WIDTH = '350px';








export const UserMenu = () => {
    const user = useUser();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    return <>
    <IconButton onClick={handleClick}><AnyBadge size="large" name={user?.name || 'Un Assigned'} colorkey={`${user?.email}` || 'no-tech'}  /></IconButton>
    
    <Popover
        sx={{ width: '400px' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
    >
        {Boolean(anchorEl) && <PopoverInner />}
        
    </Popover></>

}