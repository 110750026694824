import { MenuItem, TextField, TextFieldProps } from "@mui/material";

import { FC } from "react";
import { FormField } from "../../../../coretypes/formfields";

export const FormFieldType : FC<{ type : FormField['type'] } & Omit<TextFieldProps , 'value' > > = ({ type ,  ...props }) => {
    return <TextField
        { ...{ label: "Field Type", ...props} }
        value={type}
        select
    >
        <MenuItem value="TEXT" >Text Field</MenuItem>
        <MenuItem value="TEXTAREA" >Text Area Field</MenuItem>
        <MenuItem value="PHONE_NUMBER" >Phone Number Field</MenuItem>
        <MenuItem value="ALERT" >Alert Field</MenuItem>
        <MenuItem value="ENUM" >Enum Field</MenuItem>
    </TextField>
}

