import { Admin } from "./pages/Admin/Admin";
import { Companies } from "./pages/Companies/Companies";
import { Company } from "./pages/Company/Company";
import { FormBuilder } from "./react-submodules/components/formbuilder";
import { FormBuilderSearch } from "./pages/FormBuilderSearch/FormBuilderSearch";
import { GenerateQuote } from "./pages/Quotes/GenerateQuote";
import { InventoryDetails } from "./pages/Inventory/InventoryDetails";
import { InventoryList } from "./pages/Inventory/InventoryList";
import { Locations } from "./pages/Locations/Locations";
import { ManagerView } from "./pages/ManagerView/ManagerView";
import { QueueOrder } from "./pages/QueueOrder";
import { ReactFlowProvider } from "reactflow";
import { RecipeSearch } from "./pages/Recipies/RecipieSearch";
import { RecipieProvider } from "./providers/Recipies.provider";
import { Recipies } from "./pages/Recipies/Recipies";
import { Report } from "./pages/Company/Report";
import { RodsReport } from "./pages/RodsReport";
import { RouteObject } from "react-router-dom";
import { Settings } from "./pages/Settings";
import { TicketApiProvider } from "./react-submodules/providers/TicketApiProvider";
import { TicketQueue } from "./pages/TicketQueue/TicketQueue";
import { TicketQueues } from "./pages/TicketQueues";
import { TicketRouting } from "./pages/ManagerView/pages/TicketRouting/TicketRouting";
import { TimeclockOverview } from "./pages/TimeclockOverview/TimeclockOverview";
import { Test } from "./pages/Test";
import { UserStatusCalendar } from "./pages/UserStatusCalendar/UserStatusCalendar";
import { TechOverview } from "./pages/TechOverview/TechOverview";
import { TicketSearch } from "./pages/TicketSearch/TicketSearch";
import { TimeclockByRole } from "./pages/TimeclockOverview/TimeclockByRole";
import { Calls } from "./pages/Calls/Calls";
import { Contracts } from "./pages/Contracts";
import { CompanyContracts } from "./pages/Company/CompanyContracts/CompanyContracts";
import { BillingReport } from "./pages/Billing/Billing";
import { BillingTicketReport } from "./pages/Billing/BillingTicketReport";
import {  BillingPrintView } from "./pages/Billing/BillingDialog";
import { BillingPrintAll } from "./pages/Billing/BillingPrintAll";
import {  TimePunches } from "./pages/TimePunches/TimePunches";
import { OnCall } from "./pages/Admin/Reports/OnCall";
import { ChillingMan } from "./pages/Admin/Reports/ChillingMan";
import { Popcorn } from "./pages/Admin/Reports/Popcorn";
import { Config, configPages } from "./pages/Config/Config";
import { CONFIG_ICON } from "./react-submodules/constances/icons";
import { CoreTagsLinksConfig } from "./pages/Config/pages/CoreTagLinkssConfig";
import { Reports } from "./pages/Reports/Reports";
import { Forms } from "./pages/Forms/Forms";
import { CoreGroupsConfig } from "./pages/Config/pages/CoreGroups";
import { CoreGroupMembersConfig } from "./pages/Config/pages/CoreGroupMembers";
import { Plan } from "./pages/Schedule/Plan";
import { DailySchedule } from "./pages/Schedule/DailySchedule/DailySchedule";
import { UserActivity } from "./pages/Admin/Reports/UserActivity/UserActivity";


export type LayoutProps = {
  icon?: string,
  title?: string,
  group?: string,
}

export type CoreRouteObject = RouteObject & LayoutProps
export const Groups : LayoutProps[] = [
  {
    icon: 'icon-park-solid:sales-report',
    title: 'Business Management',
    group: 'business',
  },
  {
    icon: 'wpf:administrator',
    title: 'Manager',
    group: 'manager',
  },
  {
    icon: 'dashicons:admin-generic',
    title: 'Admin',
    group: 'admin',
  },
  
  
]
export const AppRoutes : CoreRouteObject[] = [
    {
        icon: 'mdi:company',
        title: 'Companies',
        path: "/companies",
        group: 'business',
        element: <Companies />,
    } , 
    {
        icon: 'solar:money-bag-bold',
        title: 'Billing',
        path: "/billing",
        element: <BillingReport />,
        group: 'business',
    },
    {
      title: "Billing Print",
      path: "/billing/printall",
      element: <BillingPrintAll />,
      group: 'business',
    }, 
    {
      path: "/billing/:id/print",
      element: <BillingPrintView />,
    }, 
    {
      title: "Billing Tickets",
      path: "/billing/tickets",
      element: <BillingTicketReport />,
      group: 'business',
    },
    {
      path: '/formbuilder',
      element: <FormBuilderSearch />,
    },
    {
      path: '/formbuilder/:id',
      element: <FormBuilder />
    },
    {
        path: "/company/:id",
        element: <Company />,
    } , 
    {
        path: '/company/:id/report/:reportId',
        element: <Report />
    },
    {
      path: '/company/:id/contracts',
      element: <CompanyContracts />
  },
    {
        path: '/report/rods/:id',
        element: <RodsReport />
    },
    {
      path: '/ticketqueue/:queueId',
      element: <TicketQueue />,
      title: "Ticket Queue",
      children: [
        {
          path: ':ticketId',
          element: <TicketQueue />,
        },
      ]
    },
    {
      "path" : '/managerview',
      element: <ManagerView />
    },
    {
      "path" : '/managerview/ticketrouting',
      element: <TicketRouting />
    },
    {
      path: '/queueorder/:queueId',
      element: <QueueOrder />,
      title: "Ticket Queue Order"
    },
    {
      path: '/queueorder/:queueId',
      element: <QueueOrder />,
      title: "Ticket Queue Order"
    },
    {
      path: '/ticketqueue',
      element: <TicketQueues />,
      title: "Ticket Queue",
      icon: "icon-park-outline:tickets-two"
    },
    {
        icon: 'gala:settings',
        title: 'Settings',
        path: "/settings",
        element: <Settings />,
        group: 'admin',
      },
      {
        icon: 'vaadin:stock',
        title: "Inventory Management",
        path: "/inventory",
        element: <InventoryList />
      },
      {
        title: "Inventory Management",
        path: "/inventory/:id",
        element: <InventoryDetails />
      },
      {
        icon: "ion:location-sharp",
        title: "Locations",
        path: "/locations",
        element: <Locations />
      }, 
      {
        path: "/quote/new",
        element: <GenerateQuote />

      },
      {
        icon: "clarity:calendar-solid",
        title: "Timeclock Overview",
        path: "/timeclock",
        element: <TimeclockOverview />,
        group: 'manager',
      },
      {
        icon: "clarity:calendar-solid",
        title: "Timeclock By Role",
        path: "/timeclock/byrole",
        group: 'manager',
        element: <TimeclockByRole />,
      },
      {
        icon: "vaadin:calendar-user",
        title: "User Status Calendar",
        path: "/userstatus/calendar",
        group: 'manager',
        element: <UserStatusCalendar />
      },
      {
        icon: "fluent-mdl2:pie-double",
        title: "Tech Overview",
        path: "/techoverview",
        group: 'manager',
        element: <TechOverview />
      },
      {
        path: "/admin",
        element: <Admin />,
        
      },
      {
        path: "/admin/reports/oncall",
        element: <OnCall />
      },
      {
        path: "/admin/reports/chillingman",
        element: <ChillingMan />
      },
      {
        path: "/admin/reports/popcorn",
        element: <Popcorn />
      },
      {
        path: "/admin/reports/useractivity",
        element: <UserActivity />
      },
      {
        path: "/test",
        element: <Test />
      },
      {
        path: "/recipes",
        element: <RecipeSearch />
      },
      {
        path: '/recipe/:id',
        element: <TicketApiProvider queueId="all" getNextTicket={()=>{}}><ReactFlowProvider><RecipieProvider><Recipies /></RecipieProvider></ReactFlowProvider></TicketApiProvider>
      },
      {
        title: "Time Punches",
        path: "/timepunches",
        icon: "vaadin:user-clock",
        element: <TimePunches />
      },
      {
        icon: "mdi:ticket",
        title: "Ticket Search",
        path: '/ticketqueue/tickets',
        element: <TicketSearch />
      },{
        icon: "mdi:phone",
        title: "Calls",
        path: '/calls',
        element: <Calls />
      },
      {
        icon: "clarity:contract-solid",
        title: "Contracts",
        path: '/contracts',
        element: <Contracts />
      },
      {
        icon: "entypo:bar-graph",
        title: "Reports",
        path: '/reports',
        group: 'manager',
        element: <Reports />
      },
      {
        path: '/reports/:reportId',
        element: <Reports />
      },
      {
        icon: CONFIG_ICON,
        title: "Config",
        path: '/config',
        group: 'admin',
        element: <Config />,
      },
      ...configPages.map((page) => ({
        path: `/config/${page.group}/${page.item}`,
        element: <page.Component />
      })),
      
      {
        path: '/forms',
        element: <Forms />
      },
      {
        path: '/forms/:id',
        element: <Forms />
      }
      ,
      {
        path: '/forms/:id/instance/:instanceId',
        element: <Forms />
      }
      ,
      {
        path: '/schedule/plan',
        element: <Plan />,
        title: "Schedule Plan",
        icon: 'mdi:airplane-schedule',
        group: 'admin',
      },
      {
        path: '/schedule/daily',
        element: <DailySchedule />,
        title: "Schedule Daily",
        icon: 'uis:schedule',
      }

];

