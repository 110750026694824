import { Edge, Node } from "reactflow"
import { z } from "zod"
import { GetContext } from "../types";
import { baseCoreRecipeContextSchema, getParentContext } from "../Nodes/utils";
import { QueueTicketSchema } from "../../../react-submodules/types/ticketqueue";
import { FormItem } from "../useNodeContext";

const getZodTypeForFieldType = (type: string) => {
    switch (type) {
        case 'TEXT':
            return z.string().optional();
        case 'ENUM':
            return z.string().optional();
        case 'NUMBER':
            return z.number().optional();
        case 'BOOLEAN':
            return z.boolean().optional();
        case 'DATE':
            return z.string().optional();
        default:
            return z.any().optional();
    }
}

export const getFormNodeContext : GetContext = ( nodeId: string , nodes: Node[] , edges: Edge[] , forms: FormItem[] ) => {
    const formNode = nodes.find( n => n.id === nodeId );
    const formId = formNode?.data?.formId;
    const form : FormItem | undefined = forms?.find( f => Number(f.id) === Number(formId) );
    const parentContext = getParentContext( nodeId , nodes , edges , forms );
    
    if (!form) {
        return baseCoreRecipeContextSchema.extend({
            context: z.literal('form')
        });
    }

    const formFields = form.FormFields?.reduce((acc, field) => {
        acc[field.name] = getZodTypeForFieldType(field.type);
        return acc;
    }, {} as Record<string, z.ZodType>);

    // @ts-ignore
    return parentContext.extend({
        context: z.literal('form'),
        [form.identifier]: z.object(formFields || {}).optional()
    });
}