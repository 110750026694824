import './DailySchedule.css';
import FullCalendar from "@fullcalendar/react";
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import luxon3Plugin from "@fullcalendar/luxon3";
import { useGetAllUsersQuery } from "./gql/GetAllUsers.generated";
import { useEffect, useMemo, useState } from "react";
import { useGetAllGroupsQuery } from "./gql/GetAllGroups.generated";
import { ButtonGroup, Button, Grid, Tooltip } from "@mui/material";
import { useGetScheduleInstancesQuery } from "./gql/GetScheduleInstances.generated";
import { DateTime } from "luxon";
import { gql, useMutation } from "@apollo/client";
import { useUpdateScheduleInstanceMutation } from "./gql/UpdateScheduleInstance.generated";
import { QuickEditSchedulePrimaryProps, QuickScheduleEdit } from "./forms/QuickScheduleEdit";
import { ItemView } from "./forms/ItemView";
import { Icon } from "@iconify/react";
import { getScheduleInstanceColor } from '../../../react-submodules/utils/schedule';
const TODAY = DateTime.now().toFormat('yyyy-MM-dd');

const TimeLabel = ({ date }: { date: Date }) => {
    const cdt =  DateTime.fromJSDate(date).setZone('America/Chicago').toFormat('h a');
    const edt = DateTime.fromJSDate(date).setZone('America/New_York').toFormat('h a');
    const pht = DateTime.fromJSDate(date).setZone('Asia/Manila').toFormat('h a');
  return <Tooltip title={`${cdt} CDT / ${edt} EDT / ${pht} PHT`}><span className="custom-time-label">{cdt}</span></Tooltip>;
};

const AUTOSCHEDULE = gql`
mutation AutoSchedule($date: String!) {
    autoScheduleDate(date: $date)
}
`




export const DailySchedule = () => {
    const [ date , setDate ] = useState<DateTime>(DateTime.now());    
    const [ autoScheduling , setAutoScheduling ] = useState(false);
    const [ autoScheduleQueryCore  ] = useMutation(AUTOSCHEDULE);
    
    const [ quickEditSchedule , setQuickEditSchedule ] = useState<QuickEditSchedulePrimaryProps | null>(null);
    const [ updateScheduleInstance ] = useUpdateScheduleInstanceMutation();
    const [ selectedInstanceId , setSelectedInstanceId ] = useState<number | null>(null);
    const { data } = useGetAllUsersQuery({
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: false
    });
    const { data : rawGroups } = useGetAllGroupsQuery({
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: false
    });
    const [ selectedGroups , setSelectedGroups ] = useState<number[]>([]);


    const autoScheduleQuery = ( ) => {
        setAutoScheduling(true);
        return autoScheduleQueryCore({ variables : { date : date.toFormat('yyyy-MM-dd') } }).finally( () => refetch().finally( () => setAutoScheduling(false) ) );
    }

    const groups = useMemo(() => {
        return rawGroups?.findCoreGroupPaginated?.data?.map( d => ({
            id : d.id || NaN,
            title : d.name || 'unset',
        })) ?? [];
    }, [rawGroups]);

    const toggleGroup = ( groupId : number ) => {
        setSelectedGroups( prev => prev.includes(groupId) ? prev.filter( id => id !== groupId ) : [ ...prev, groupId ] );
    }

    useEffect(() => {
        if( selectedGroups.length === 0 ) {
            setSelectedGroups(groups.map( g => g.id ));
        }
    }, [groups]);

    const resources = useMemo(() => {
        const r =  data?.findCoreUsersPaginated?.data?.filter( d => {
            const usersGroups = d.groups?.map( g => g.group.id ) || [];
            if( selectedGroups.length === 0 ) {
                return false;
            }
            return selectedGroups.some( groupId => usersGroups.includes(groupId) );
        } ).map( d => ({
            id : d.id || 'unset',
            title : (d.firstName ? `${d.firstName} ${d.lastName}` : d.email ) || 'unset',
        })) ?? [];
        return [ { id : 'unset', title : 'Unscheduled' } ,  ...r ];
    }, [data , selectedGroups]);


    const { data : scheduleInstances , refetch } = useGetScheduleInstancesQuery({
        variables : {
            startDateTime : date.startOf('day').toJSDate(),
            endDateTime : date.endOf('day').toJSDate(),
        },
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: false,
    });

    const instances = useMemo(() => {
        return scheduleInstances?.findScheduleInstancePaginated?.data?.map( d => {
            const extra : Record<string,any> = {
                backgroundColor : getScheduleInstanceColor( d ),
            };
            if( d.available ){
                extra.display = 'background';
            } 
            
            
            return {
                id : d.id || NaN,
                title : d.title ||  d.SchedulePlan?.name || d.AzureCalendar?.subject || '',
                start : d.startDateTime,
                end : d.endDateTime,
                resourceId : d.coreUserId || 'unset',
                extendedProps: d,
                durationEditable : !d.lockedDateTime,
                startEditable : !d.lockedDateTime,
                ...extra,
            }
        }) ?? [];
    }, [scheduleInstances]);



    return <>
    <Grid container spacing={2}>
        <Grid item sm={12}>
            <Button variant="contained" color="error" disabled={autoScheduling} onClick={() => {
                autoScheduleQuery();
            }}>Auto Schedule {autoScheduling && <Icon icon="mdi:loading" />}</Button>
            <Button variant="contained" color="secondary" onClick={() => {
                setQuickEditSchedule({ date : date.toFormat('yyyy-MM-dd') });
            }}>Quick Add</Button>
        </Grid>
        <Grid item sm={12}>
            
    </Grid>
    </Grid>
    <QuickScheduleEdit afterSubmit={() => refetch()} onClose={() => setQuickEditSchedule(null)} open={quickEditSchedule !== null} {...(quickEditSchedule ?? {})} />
    <ItemView instanceId={selectedInstanceId} onClose={() => {setSelectedInstanceId(null); refetch();}} />
  <FullCalendar
        nowIndicator
        initialView={"resourceTimeGridDay"}
        slotLabelContent={(arg) => <TimeLabel date={arg.date} />}
        headerToolbar={{
          right:
            "prev,next today resourceTimeGridDay,resourceTimeGridDayFull",
        }}
        views={{
          resourceTimeGridDay: {
            type: 'resourceTimeGrid',
            slotMinTime: "05:00:00",
            slotMaxTime: "18:00:00",
            buttonText: 'Day (Business Hours)'
          },
          resourceTimeGridDayFull: {
            type: 'resourceTimeGrid',
            slotMinTime: "00:00:00",
            slotMaxTime: "24:00:00",
            buttonText: 'Day (24 Hours)'
          }
        }}
        editable={true}
        initialDate={date.toJSDate()}
        datesSet={(dateInfo) => {
            setDate(DateTime.fromJSDate(dateInfo.start));
        }}
        plugins={[resourceTimeGridPlugin, luxon3Plugin, interactionPlugin]}
        resources={resources}
        contentHeight="auto"
        stickyFooterScrollbar={true}
        stickyHeaderDates={true}
        resourceOrder="title"
        eventClick={(item) => {
            const prop : { id : number } & Record<string,any> = item.event.extendedProps as any;
            setSelectedInstanceId( prop.id );
            
        }}
        eventDrop={( draggedEvent , ...p ) => {
            const instance : { id : number } & Record<string,any> = draggedEvent.event.extendedProps as any ;
            const resource = draggedEvent.newResource?.id;
            const update : { id : number , coreUserId? : string | null , startDateTime? : string , endDateTime? : string } = { id : instance.id };
            if( resource && resource !== instance.resourceId ){
                update.coreUserId = resource === 'unset' ? null : resource;
            }
            if( draggedEvent.event.start && draggedEvent.event.start !== instance.start ){
                update.startDateTime = draggedEvent.event.start.toISOString();
            }
            if( draggedEvent.event.end && draggedEvent.event.end !== instance.end ){
                update.endDateTime = draggedEvent.event.end.toISOString();
            }
            updateScheduleInstance( { variables : update } ).then( () => refetch() );
        }}
        eventChange={(eventInfo) => {
            const instance = eventInfo.event.extendedProps;
            const update : { id : number , startDateTime? : string , endDateTime? : string } = { id : instance.id };
            
            if( eventInfo.event.start && eventInfo.event.start !== instance.start && !instance?.lockedDateTime ){
                update.startDateTime = eventInfo.event.start.toISOString();
            }
            if( eventInfo.event.end && eventInfo.event.end !== instance.end && !instance?.lockedDateTime ){
                update.endDateTime = eventInfo.event.end.toISOString();
            }
            if( Object.keys(update).length > 1 ){
                updateScheduleInstance( { variables : update } ).then( () => refetch() );
            } else { 
                eventInfo.revert();
            }
        }}
        
        eventAllow={( dropInfo , draggedEvent ) => {
            const instance = draggedEvent?.extendedProps;
            const sameTime = new Date( instance?.startDateTime ).getTime() === dropInfo.start.getTime();
            if( instance?.lockedDateTime && !sameTime ){
                return false;
            }
            if( instance?.lockedUser && instance?.coreUserId !== dropInfo.resource?.id ){
                return false;
            }
            return true;
        }}
        events={instances as any }
        resourceAreaWidth="20%"
        schedulerLicenseKey="0535070809-fcs-1715174460"
        eventContent={(eventInfo) => {
          const instance = eventInfo.event.extendedProps;
          return {
            html: `
              <div class="fc-event-main-frame">
                <div class="fc-event-title-container">
                  <div class="fc-event-title fc-sticky">
                    
                    ${eventInfo.event.title} 
                    ${(!instance.overidePlan && instance.schedulePlanId ) ? `<div class="iconify-icon auto-schedule-icon"></div>` : ''}
                    
                  </div>
                </div>
              </div>
            `
          };
        }}
      />
      <ButtonGroup>
                {groups.map( g => <Button key={g.id} variant="contained" color={selectedGroups.includes(g.id) ? 'info' : 'inherit'} onClick={() => {
            toggleGroup(g.id);
        }}>{g.title}</Button>)}
    </ButtonGroup>
  </>;
}