import { PropsWithChildren, createContext, useContext, useEffect,  useState } from "react";
import KeycloakFactory from "keycloak-js";
import LogRocket from "logrocket";
import { KEYCLOAK_URL, KeycloakContext } from "./Keycloak.common";


const keycloak = new KeycloakFactory({
    url: KEYCLOAK_URL, /* 'https://auth.timwoods.app/auth/', */
    realm: 'enlightenedmsp',
    clientId: 'core-frontend'
});
keycloak.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
});
export const KeycloakProvider :React.FC<PropsWithChildren> = ( {children}) => {
   

    const [ ready , setReady ] = useState( false );
    const [ token , setToken ] = useState( '' );
    const [ tokenParsed , setTokenParsed ] = useState<Record<string,any>>( {} );
    const [ firstLoop  , setFirstLoop ] = useState( true );
    useEffect( () => {
        if( !ready ){
            setTimeout( () => {
                if( !ready && !keycloak.authenticated ){
                    keycloak.login()
                }
            } , 1500 );
        }
        
    } , [ready])

    useEffect( () => {
        let loaded = true;
        const iv = setInterval( () => { 
            if( !loaded ) return;
            
            if( keycloak.authenticated !== ready ){
                setReady( keycloak.authenticated || false );
            }
            if( keycloak.token !== token ){
                setToken( keycloak.token || '' );
            }
            if( JSON.stringify( keycloak.tokenParsed ) !== JSON.stringify( tokenParsed ) ){
                setTokenParsed( keycloak.tokenParsed || {} );
            }
            const now =  Math.round(new Date().getTime() / 1000 );
            if( !firstLoop && (keycloak.tokenParsed?.exp || 0 ) - ( now + 60  ) <= 0 ){
                keycloak.updateToken && keycloak.updateToken( 60 * 15 );
            }
            if( firstLoop){
                setFirstLoop( false );
            }
            
        } , 1000 );    
        keycloak.onTokenExpired = () => {
            keycloak.updateToken && keycloak.updateToken( 60 * 15 );
        } 
        return () =>{
            loaded = false;
            clearInterval( iv );
        }
    } , [token])

    const login = () => {
        keycloak.login();
    }
    const logout = () => {
        keycloak.logout();
    }
    useEffect( () => {
        if ( !tokenParsed?.email ){
            return;
        }

        LogRocket.identify('enlightened/enlightenedmsp', {
            name: `${tokenParsed?.given_name} ${tokenParsed?.family_name}`,
            email: tokenParsed?.email,
            AUTOTASK_ID : tokenParsed?.AUTOTASK_ID,
            CORE_COMPANY_ID: tokenParsed?.CORE_COMPANY_ID,
          }); 
    } , [JSON.stringify( tokenParsed )])
    
    
    return (
        <KeycloakContext.Provider value={{ login , logout , ready , token , tokenParsed  }}>{children}</KeycloakContext.Provider>
    )
}