import { FC } from "react"
import { FormField } from "../../../../coretypes/formfields"
import { TextField } from "@mui/material"
import { useTextFieldStyles } from "./TextFieldStyles";

const formatPhoneNumber = (value: string , showNextFormat: boolean = true): string => {
    const numbers = value.replace(/[^0-9]/g, '');
    if (numbers.length === 0) return '';
    if (numbers.length <= ( 2 + ( showNextFormat ? 0 : 1 ) ) ) return `(${numbers}`;
    if (numbers.length <= ( 5 + ( showNextFormat ? 0 : 1 ) ) ) return `(${numbers.slice(0, 3)}) ${numbers.slice(3)}`;
    return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`;
};

export const FormPhoneNumber: FC<{ readonly?: boolean, viewMode?: boolean, field: FormField, value: any, onChange: (value: any) => void }> = ({ readonly, viewMode, field, value, onChange }) => {
    const sx = useTextFieldStyles({ viewMode });
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
        onChange(rawValue);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if( e.key === 'Backspace' && value.length > 0 ){
            onChange(value.slice(0, -1));
            e.preventDefault();
        }
    }

    return (
        <TextField
            sx={sx}
            disabled={readonly}
            fullWidth
            label={field.label}
            onKeyDown={handleKeyDown}
            value={formatPhoneNumber(value || ''  )}
            onChange={handleChange}
            placeholder="(XXX) XXX-XXXX"
        />
    );
}

