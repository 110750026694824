import { useEdges, useNodes } from "reactflow";
import { getContextRetreaver } from "./Nodes/utils";
import { GetFormsQuery, useGetFormsQuery } from "./gql/GetForms.generated";


export type FormItem = NonNullable<GetFormsQuery['findFormsFormPaginated']['data']>[number];

export const useNodeContext = ( nodeId: string ) => {
    const { data } = useGetFormsQuery();
    const nodes = useNodes();
    const edges = useEdges();
    const node = nodes.find( n => n.id === nodeId );
    
    const getContext = () => {
        const getContext = getContextRetreaver( node?.type || "" );
        const forms = data?.findFormsFormPaginated.data || [];
        console.log( "useNodeContext", { forms });
        return getContext( nodeId , nodes , edges , data?.findFormsFormPaginated.data || [] , [] );
    }
    return { getContext };
}