import { FC } from "react"
import { FormField } from "../../../../coretypes/formfields"
import { TextField } from "@mui/material"
import { useTextFieldStyles } from "./TextFieldStyles";

export const FormFieldTextArea : FC<{ viewMode?: boolean, readonly?: boolean, field : FormField , value : any , onChange: ( value : any ) => void }> = ({ viewMode, readonly, field , value , onChange }) => {
    const sx = useTextFieldStyles({ viewMode });
    return <TextField disabled={readonly} fullWidth multiline rows={field?.config?.rows || 4} label={field?.label} value={value} onChange={(e) => {
        onChange( e.target.value );
    }} sx={sx} />
}

