import { StyledComponentProps, SxProps, TextFieldProps, useTheme } from "@mui/material"

export const useTextFieldStyles = ( { viewMode } : { viewMode?: boolean } )  : SxProps => {
    const theme = useTheme();
    if( !viewMode ) return {};
    return {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: theme.palette.text.primary,
            color: theme.palette.text.primary,
            opacity: 1,
        },
        '& .MuiOutlinedInput-root.Mui-disabled': {
            '& > fieldset': {
                borderColor: theme.palette.divider,
            },
            backgroundColor: theme.palette.background.paper,
        },
        '& .MuiInputLabel-root.Mui-disabled': {
            color: theme.palette.text.secondary,
        }
    }
}