


export const timeToNumber = (time: string): number => {
    const [hours, minutes] = time.split(':');
    return parseInt(hours) + (Math.round( parseInt(minutes) / 60 * 100 ) / 100 );
}

const ppzero = ( number : number ) => {
    return number < 10 ? `0${number}` : `${number}`;
}


export const numberToTime = (number: number , format = '24hr'): string => {
    // console.log( {number} );
    if( isNaN( number ) ){
        return '';    
    }
    const hours = Math.floor(number);
    const minutes = Math.round( ( number - hours ) * 60 );

    // console.log({hours});
    // console.log({minutes});
    switch( format ){
        default:
        case '24hr': // I'm not getting here but I'm getting to the case of 12hrs
            return `${ppzero(hours)}:${ppzero(minutes)}`;
        case '12hr':
            return `${ppzero(hours % 12 || 12 )}:${ppzero(minutes)} ${hours >= 12 ? 'PM' : 'AM'} CST`;
    }
}