import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Menu, MenuItem, PaletteColor, TextField, Typography, createTheme, useTheme, Alert, SxProps, Theme } from "@mui/material";
import { FC, MouseEventHandler, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { TTodoAssignee, TTodosTodo } from "../../types/todos.dto";

import { AnswerTodoDialog } from "../AnswerTodoDialog";
import { AnyBadge } from "../AnyBadge";
import { BadgeAndName } from "../TicketPartials/BadgeAndName";
import { EditTodoDialog } from "../TicketFullCard/EditTodoDialog";
import { Icon } from "@iconify/react";
import { MarkdownView } from "../../MarkdownView";
import { useApi } from "../../providers/Api/Api";
import { useRecipeApi } from "../../providers/Recipe";
import { useTicketApi } from "../../providers/TicketApiProvider";
import { numberToTime } from "../../utils/time.utils";
import { LabeledBox } from "../LabeldBox";
import { FormView } from "../FormView/FormView";
import { ContainedIconButton } from "../ContainedIconButton";
import { PHONE_ICON } from "../../constances/icons";

const AssigneeBadge : FC<{assignee: TTodoAssignee}> = ( {assignee} ) => {
    if( assignee.group_name ){
        return <Box sx={{
            paddingTop: '4px',
            marginLeft: 0.6
        }}><AnyBadge size="small" name={assignee.group_name} colorkey={`${assignee.coreGroupId}`} /></Box>    
    }
    if( assignee.user_email ){
        return <Box sx={{
            paddingTop: '4px',
            marginLeft: 0.6
        }}><AnyBadge size="small" name={`${assignee.user_firstName} ${assignee.user_lastName}`} colorkey={`${assignee.coreUserId}`} /></Box>    
    }
        return <>Unknown</>
    
}


const formatPhoneNumber = ( phone  : number | null | undefined  ) => {
    if( !phone ) return '';
    switch( phone.toString().length ){
        case 11:
            return `(${phone.toString().slice(1,4)}) ${phone.toString().slice(4,7)}-${phone.toString().slice(7,11)}`;
        case 10:
            return `(${phone.toString().slice(0,3)}) ${phone.toString().slice(3,6)}-${phone.toString().slice(6,10)}`;
        case 7:
            return `${phone.toString().slice(0,3)}-${phone.toString().slice(3,6)}`;
        default:
            return phone.toString();
    }
}


const InfoSquare : FC<PropsWithChildren & { sx?: SxProps<Theme> }> = ( {  children , sx } ) => {
return <Grid item xs={6}><Box sx={{ 
        ...sx ,
        display: 'flex' , 
        flexDirection: 'row' , 
        justifyContent: 'space-between' , 
        alignItems: 'center' , 
        
        width: 'calc( 100% - 8px )' , 
        
    }}>{children}
        
    </Box></Grid>
}

const InfoBox : FC<{ description: string  } & PropsWithChildren > = ( { description , children  } ) => {
    return <InfoSquare sx={{
        border: '1px solid rgba( 0 , 0 , 0 , .2)' , 
        background: 'rgba( 255 , 255 , 255 , .1)' ,
        borderRadius: 2 , 
        paddingLeft: 1 ,
        paddingRight: 1 ,
        paddingTop: 0.5 ,
        paddingBottom: 0.5 
    }}>
    <Typography variant="body2" >{description}</Typography>
    <Typography variant="body2">{children}</Typography>
    </InfoSquare>
}

const todoTheme = createTheme({
    palette: {
        primary: {
          main: '#fc2',
        },
        secondary: {
          main: '#C90',
        },
      }
  });
  const greyTheme = createTheme({
    palette: {
        primary: {
          main: '#fff',
          contrastText: '#444'
        },
        secondary: {
          main: '#C90',
        },
      }
  })

const getColor = ( todo : TTodosTodo ) : PaletteColor  => {
    
    if( todo?.complete && ( todo.noteType === "task" || todo.noteType === null )){
        return greyTheme.palette.primary;
    }
    if( todo?.complete && todo.noteType === "question"){
       return todoTheme.palette.secondary;
    }
    if( todo.noteType === "question"){
        return todoTheme.palette.primary;
     }
     return todoTheme.palette.info
    // const mainColor : PaletteColor = todo?.complete ? theme.palette.success : theme.palette.info;
}

const formatTodoDate = ( date : string | null ) => {
    if( !date ) return '';
    return new Date( date ).toLocaleDateString('en-US' , { day: 'numeric', month: 'short', timeZone: 'UTC' });

}



export const TodoItem: FC<{ todo: TTodosTodo, onChange?: ( ) => any }> = ({ todo, onChange }) => {
    const theme = useTheme();
    const { nextTicket  } = useTicketApi();
    const isNextTodo = useMemo( () => {
        if( todo.id === nextTicket?.todoId ){
            return true;
        }
        return false;
    } , [nextTicket?.id , todo?.id , nextTicket?.todoId]);
    const mainColor : PaletteColor = getColor(todo);
    // const [ showDetails , setShowDetails ] = useState(false);

    const { submitRecipe } = useRecipeApi();


    const [completeAnchorEl, setCompleteAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(completeAnchorEl);

    const [ openDialog , setOpenDialog ] = useState(false);

    const completeOptions = useMemo( () => {
        return todo?.recipe_node_data?.completeOptions || [];
    }, [JSON.stringify(todo?.recipe_node_data?.completeOptions)]);

    const isRecipeTodo = todo?.recipeInstanceId !== null;

    const api = useApi();

    const { resources } = useTicketApi();

    const [ answerDialog , setAnswerDialog ] = useState(false);

    const [ showDetails , setShowDetails ] = useState(false);
    useEffect( () => {
        if( isNextTodo  && !showDetails && !todo.complete){
            setShowDetails( true );
        }
    } , [isNextTodo , showDetails , todo.complete]);
    
    const [ editTodo , setEditTodo ] = useState(false);

    const labelIcon = showDetails ? 'mingcute:down-fill' : 'mingcute:right-fill';

    const createdByUser = useMemo( () => {
        if( !todo.createdByUser ) return null;
        return resources.find( r => r.user_id === todo.createdByUser);
    } , [JSON.stringify( resources) , todo.createdByUser])
    const completedByUser = useMemo( () => {
        if( !todo.completeByUser ) return null;
        return resources.find( r => r.user_id === todo.completeByUser);
    } , [JSON.stringify( resources) , todo.completeByUser])
    const hasDetails = todo.description || todo.response;

    const typeIcon = todo.noteType === 'task' ? 'carbon:task-star' : 'uil:comment-alt-question';

    const submitRecipeComplete = ( resultHandleId : string ) => {
        if( !todo?.recipeInstanceId ){
            console.error( 'No recipe instance id' );
            return;
        }
        submitRecipe(  todo?.recipeInstanceId , { 
            resultHandleId 
        } ).then( ()=> {
            onChange && onChange();
        });
        handleClose();
    }

    const clickComplete = ( event : React.MouseEvent<HTMLButtonElement, MouseEvent> )=>{
        if( !todo.complete && isRecipeTodo && completeOptions.length > 0){
            setCompleteAnchorEl(event.currentTarget);
            return;
        }
        if( !todo.complete && isRecipeTodo ){
            submitRecipeComplete('generic_complete');
            return;
        }
        if( todo.noteType === 'question' && !todo.complete ){
            
            setAnswerDialog( true );
            return;
        }
        if( !todo.complete ) {
            api.patch(`/todos/todo` , { id : todo.id, complete: !todo.complete }).then( ()=>{
                onChange && onChange();
            });
            return;
        }
        
        setEditTodo( true );
    }
    const handleClose = () => {
        setCompleteAnchorEl(null);
      };



    return <>
    <EditTodoDialog open={editTodo} onClose={()=>{ setEditTodo( false ); onChange && onChange()}} todo={todo} />
    <AnswerTodoDialog open={answerDialog} 
        onClose={()=>{
            setAnswerDialog( false );
            onChange && onChange();
        }} 
        todo={todo}
    />


    <Box sx={
        {
            display: 'flex' , 
            background: mainColor.main , 
            color: mainColor.contrastText ,
            margin: 0.5,
            flexDirection: 'column',
            padding: 0.25,
            borderRadius: 2
        }}>
            <Box sx={{
                display: 'flex',
                width: '100%',
            }}>
            <IconButton sx={{
            color: 'white',
        }} size="small" onClick={clickComplete}><Icon 
            icon={ todo.complete ? 'pepicons-pop:open' : ( completeOptions?.length === 0 ?  'ic:round-done-outline' : 'fa-solid:list-alt' )} 
            /></IconButton>
            
            {todo?.assignees?.map( a => <AssigneeBadge  key={a.id} assignee={a}  />)}
            
            <Typography onClick={()=>{
                // setOpenDialog( true );
                setShowDetails( !showDetails );
            }} sx={{ cursor: 'pointer', flexGrow: 1 ,  marginLeft: 1 , textDecoration: todo.complete ? 'line-through' : 'none', fontWeight: todo.complete ? 'normal' : 'bold',}} variant="body1" >{todo.title}</Typography>&nbsp;&nbsp;

            <Typography sx={{marginRight: 0.5 }}>{formatTodoDate(todo.dueDate)}</Typography>
            { Boolean(todo.isOutboundCall) && <IconButton sx={{
            color: 'white',
        }}  size="small" onClick={()=>{
                setOpenDialog( true );
            }}><Icon icon="tabler:phone" /></IconButton>}
            </Box>
            
            { showDetails && <Box sx={{ background: 'rgba( 180 , 180, 180 , .8)' , borderBottomLeftRadius: 8 , borderBottomRightRadius: 8  }}>
            { isNextTodo && <Alert sx={{margin: 0.5 }} severity="success">You where routed to this ticket for this todo</Alert>}
            { todo.description && <Box sx={{marginTop: 0.5 , padding: 0.5 , width: '100%'}}>
                <Box sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    background: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    padding: 0.5,
                    borderRadius: 2,
                    width: 'calc( 100% )'
                }}>
                <MarkdownView size="small">{todo.description}</MarkdownView>
                </Box>
               
            </Box> }
            { todo.response && <LabeledBox fontSx={{fontWeight: 'bold' , color: theme.palette.text.primary , background: 'transparent'}} label="Response" sx={{marginTop: 0.5 , padding: 0.5 , width: '100%'}}>
                <Box sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    background: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    padding: 0.5,
                    borderRadius: 2,
                    width: 'calc( 100% )'
                }}>
                <MarkdownView size="small">{todo.response}</MarkdownView>
                </Box>
               
            </LabeledBox> }
            <Grid container sx={{ margin: 0.5 }}>
                <InfoBox description="Next Action Date">
                {Boolean(todo.dueDate) ? formatTodoDate(todo.dueDate) : 'From Ticket'}
            </InfoBox>
            <InfoBox description="Next Action Type">
                {Boolean(todo.nextActionType) ? todo.nextActionType : 'From Ticket'}
            </InfoBox>
            <InfoBox description="Next Action Time">
                {Boolean(todo.nextActionTime) ? numberToTime( todo.nextActionTime || NaN , "12hr" ) : 'From Ticket'}
            </InfoBox>
            
            { todo.outboundCallNumber && todo.outboundCallNumber > 1000000000 && <>
                <InfoSquare><Button fullWidth size="small" variant="contained" color="success" startIcon={<Icon icon={PHONE_ICON} />} onClick={()=>{
                    window.open( `tel:${todo.outboundCallNumber}` , 'outbound_call' );
                }}>Call {formatPhoneNumber(todo.outboundCallNumber )}</Button></InfoSquare>
            </>}
            
            
            { todo.formInstanceId && <Box sx={{marginTop: 0.5, marginRight: 1, padding: 0.5 , width: '100%' , background: theme.palette.background.paper , borderRadius: 2}}>
                <FormView formInstanceId={todo.formInstanceId} />
            </Box> }
            </Grid>
            
            <Grid container sx={{ margin: 0.5 , display: 'flex' , flexDirection: 'row' , justifyContent: 'space-between' , alignItems: 'left' }}>
                <IconButton sx={{marginRight: 0.5 }} size="small" color="primary" onClick={()=>{
                    setOpenDialog( true );
                }}><Icon icon="tabler:list-details" /></IconButton>
                <ContainedIconButton  sx={{marginRight: 0.5 }} size="small" color="secondary"  onClick={()=>{
                    setEditTodo( true );
                }}><Icon icon="tabler:edit" /></ContainedIconButton >
                
                <Box sx={{flexGrow: 1}}>&nbsp;</Box>
                {!todo.complete && <>
                { completeOptions.length === 0 && <Button variant="contained" color="success" onClick={clickComplete}>Complete</Button> }
                {completeOptions?.map( ( option , i ) => {
            return <Button sx={{marginRight: 0.5}} size="small" variant="contained" color="warning" key={option.id} onClick={()=>{
                submitRecipeComplete( option.id);

            }}>{option.value}</Button>
      })}
      </>}
                <Box sx={{marginLeft: 0.5}}>&nbsp;</Box>
            </Grid>
            </Box>}
        

        
    </Box>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle></DialogTitle>
            <DialogContent>
            <Grid container>
                <Grid item xs={12} sx={{ display: 'flex'}}>
                    <Typography variant="body2" sx={{fontWeight: 'bold' , flexGrow: 1}}>Details</Typography>
                    <Box><IconButton size="small" onClick={()=>{
                        setEditTodo( true );
                    }}><Icon icon="tabler:edit" /></IconButton></Box>
                </Grid>
                <Grid item xs={4}>
                    Assigned To 
                </Grid>
                <Grid item xs={8}>
                    {todo?.assignees?.map( a => <AssigneeBadge  key={a.id} assignee={a}  />)}
                </Grid>
                <Grid item xs={4}>
                    Next Action Date
                </Grid>
                <Grid item xs={8}>
                    {formatTodoDate(todo.dueDate)}
                </Grid>
                <Grid item xs={4}>
                    Created At
                </Grid>
                <Grid item xs={8}>
                {todo.createdAt && new Date( todo.createdAt).toLocaleDateString()} {todo.createdAt && new Date( todo.createdAt).toLocaleTimeString()}
                </Grid>
                <Grid item xs={4}>
                    Created By
                </Grid>
                <Grid item xs={8}>
                {createdByUser && <BadgeAndName size="small" colorkey={`user:${createdByUser.user_email}`} displayName={`${createdByUser.firstName} ${createdByUser.lastName}`} />}
                </Grid>
                <Grid item xs={4}>
                    Completed By
                </Grid>
                <Grid item xs={8}>
                {completedByUser && <BadgeAndName size="small" colorkey={`user:${completedByUser.user_email}`} displayName={`${completedByUser.firstName} ${completedByUser.lastName}`} />}
                </Grid>
            </Grid>
            { todo.description  && <Box sx={{  marginBottom: 1, padding:1 , border: '1px solid rgba( 0 , 0 , 0 , .2)' , borderRadius: 2 }}>
                <Typography variant="body2" sx={{fontWeight: 'bold'}}>Description</Typography>
            <MarkdownView>{todo.description || ''}</MarkdownView>
            </Box> }
            { todo.response && <Box sx={{  padding:1 , border: '1px solid rgba( 0 , 0 , 0 , .2)' , borderRadius: 2 }}>
                <Typography variant="body2" sx={{fontWeight: 'bold'}}>Response</Typography>
            <MarkdownView>{todo.response || ''}</MarkdownView>
            </Box> }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenDialog(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    <Menu
        anchorEl={completeAnchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      > {completeOptions?.map( ( option , i ) => {
            return <MenuItem key={option.id} onClick={()=>{
                submitRecipeComplete( option.id);

            }}>{option.value}</MenuItem>
      })}
      </Menu>
        
    </>
}