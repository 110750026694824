import { Box, Button, MenuItem, TextField, TextFieldProps, Typography } from "@mui/material"
import { FieldSettingProps } from "./types"

const defaultTextareaProps: Partial<TextFieldProps> = {
    size: "small",
    fullWidth: true,
    sx: { marginTop: 1 }
}

export const NumberFieldSetting: React.FC<FieldSettingProps> = ({ extra, id, value, interpreter, onChange, onDelete }) => {
    return <Box sx={{ border: '1px solid', borderColor: '#777', borderRadius: 2, p: 1, position: 'relative', m: 1, marginBottom: 2 }}>
        {interpreter === "HANDLEBARS" ? (
            <TextField
                label="value"
                {...defaultTextareaProps}
                value={value || ""}
                onChange={(e) => {
                    onChange({ value: e.target.value })
                }}
            />
        ) : (
            <TextField
                label="value"
                type="number"
                {...defaultTextareaProps}
                value={value || ""}
                onChange={(e) => {
                    const val = e.target.value === "" ? null : Number(e.target.value);
                    onChange({ value: val })
                }}
            />
        )}

        <TextField
            label="interpreter"
            {...defaultTextareaProps}
            select
            value={interpreter}
            onChange={(e) => { onChange({ interpreter: e.target.value as any }) }}
        >
            <MenuItem value="DIRECT">DIRECT</MenuItem>
            <MenuItem value="HANDLEBARS">HANDLEBARS</MenuItem>
        </TextField>

        <Box sx={{ position: 'absolute', top: -16, left: 10, background: 'white', p: 0.5, borderRadius: 1 }}>
            <Typography>{id}</Typography>
        </Box>
        <Button onClick={onDelete} color="error">Delete</Button>
    </Box>
} 