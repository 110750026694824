import { Box, Button, Card, CardContent, Divider, Grid, IconButton, InputAdornment, Menu, MenuItem, TextField, Toolbar, Typography, useTheme } from '@mui/material';
import { ExtraFieldSetting, FieldSettingOnChange, FieldSettingProps } from '../FieldSetting/types';
import { Handle, Position, useUpdateNodeInternals } from 'reactflow';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNodeDataProperty, useRecipie } from '../../../providers/Recipies.provider';

import { FieldSetting } from '../FieldSetting/FieldSetting';
import { Icon } from '@iconify/react';
import { useTicketApi } from '../../../react-submodules/providers/TicketApiProvider';
import {v4} from 'uuid';

type CompleteOption = {
  value : string;
  id : string;
}

type TodoExtraField = {
  id: string;
  label: string;
  type: 'text' | 'number' | 'boolean' | 'date' | 'enum';
  nullable?: boolean;
  defaultValue?: string | number | boolean | null;
  values?: string[];
  multiline?: boolean;
}

const todoExtraFields : TodoExtraField[] = [
  { id: 'dueDate' , label: 'Due Date' , type: 'date' , nullable : true  } ,
  { id: 'description' , label: 'Description' , type: 'text', multiline : true  } ,
  { id: 'response' , label: 'Response' , type: 'text' , multiline : true } ,  
  { id: 'outboundCallNumber' , label: 'Outbound Call Number' , type: 'number' , nullable : true } ,
  { id : 'isOutboundCall' , label: 'Is Outbound Call' , type: 'boolean' , defaultValue: false } ,
  { id : 'isOnsite' , label: 'Is Onsite' , type: 'boolean' , defaultValue: false } ,
  { id : 'formInstanceId' , label: 'Form Instance Id' , type: 'number' , nullable : true } ,
  /*{ id: 'noteType' , label: 'Type' , type: 'enum' , values: ['task' , 'question'] , defaultValue : 'task' } ,
  { id: 'ticketBlocking' , label: 'Ticket Blocking' , type: 'boolean' , defaultValue : false } ,
  { id: 'completed' , label: 'Completed' , type: 'boolean' } ,
  { id: 'autotaskTicketId' , label: 'Autotask Ticket Id' , type: 'number' } ,   */
]



type FunctionManipulator<T = any > = (data: T) => T ;

export const  TodoNode = ({ id , data } : any ) => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  

  const completeOptions : CompleteOption[]  = data.completeOptions || [];
  const extraFields : ExtraFieldSetting[]  = data.extraFields || [];
  const useableExtraFields = todoExtraFields.filter( m => !extraFields.some( n => n.id === m.id ));
  const [ groupId , setGroup ] = useNodeDataProperty<number | null | string >( id , 'groupId' , 0 );
  const [ groupIdInterpreter , setGroupIdInterpreter ] = useNodeDataProperty<string>( id , 'groupIdInterpreter' , 'DIRECT' );

  const { groups } = useTicketApi();

  const updateNodeInternals = useUpdateNodeInternals();
  const { updateNodeData } = useRecipie();


  const setCompleteOptions = ( completeOptions : CompleteOption[] | FunctionManipulator<CompleteOption[]>) => {
    if( typeof completeOptions === 'function' ){
      updateNodeData( id , ( data : Record<string,any> ) => {
        return { ...data , completeOptions: completeOptions( data.completeOptions ) }
      } );
    } else {
      updateNodeData( id , {completeOptions} );
    }
  }
  const setExtraFields = ( newExtraFields : ExtraFieldSetting[] | FunctionManipulator<ExtraFieldSetting[]>) => {
    if( typeof newExtraFields === 'function' ){
      updateNodeData( id , ( data : Record<string,any> ) => {
        return { ...data , extraFields: newExtraFields( data.extraFields ) }
      } );
    } else {
      updateNodeData( id , {extraFields : newExtraFields } );
    }
  }
 
  const addExtraField = ( extraField : TodoExtraField ) => {
    const { id , type ,  defaultValue , multiline } = extraField;
    setExtraFields( op =>{
      return [...(op ||[]) , { value: defaultValue , id , type , interpreter: 'DIRECT' , multiline }]
    });
    handleClose();
  }
  const updateExtraField = useCallback((id : string , value : FieldSettingOnChange ) => {
    if( typeof value === 'function' ){
      setExtraFields( ef => ef.map( _ => _.id === id ? { ..._ , ...value( _ ) } : _ ));
    } else {
      setExtraFields( ef => ef.map( _ => _.id === id ? { ..._ , ...value } : _ ));
    }
    setExtraFields( ef => ef.map( _ => _.id === id ? { ..._ , ...value } : _ ));
   } , [setCompleteOptions]);

  useEffect( () => {
    updateNodeInternals(id);
  } , [JSON.stringify(completeOptions )])

  const addCompleteOption = useCallback(() => {
    const id = v4().substring( 0 , 12 );
    setCompleteOptions( op => [...op , { value: `Item ${op.length}` , id }]);
  } , [setCompleteOptions]);


   const updateCompleteOption = useCallback((id : string , value : string) => {
    setCompleteOptions( op => op.map( _ => _.id === id ? { ..._ , value } : _ ));
   } , [setCompleteOptions]);

   const removeCompleteOption = useCallback((id : string) => {  
    setCompleteOptions( op => op.filter( _ => _.id !== id  ));
   }  , [setCompleteOptions]);
   const groupIdUsesHandlebars = useMemo( () => groupIdInterpreter === 'HANDLEBARS' , [groupIdInterpreter]);
  return (
    <Card sx={{maxWidth: '600px' , cursor: 'default'}}>
      <CardContent>
      <Handle
          type="target"
          position={Position.Left}
          id={`handle-source`}
        />
      {completeOptions.map((_, index) => (<Handle
          key={_.id}
          type="source"
          position={Position.Right}
          id={_.id}
          style={{ bottom: (( ( completeOptions.length - index - 1 ) * 56) + 48 ) , top: 'auto' }}
        />
      ))}
      
      
      <Typography className="drag-handle" variant="h6" sx={{padding: '2px' , cursor: 'grab'}}>Todo</Typography>
      <TextField sx={{padding: '2px'}} size="small" label="Title" value={data.title} onChange={(e) => {
        updateNodeData(id , { title: e.target.value });
      }} fullWidth />
      { groups.length && <Box sx={{display: 'flex' , flexDirection: 'row' , gap: 1, marginTop: 1 , marginBottom: 1}}>
          <TextField 
                    InputLabelProps={{ shrink: true  }} 
                    error={!groupId} 
                    required 
                    fullWidth 
                    size="small"  
                    label="Group" 
                    value={groupId} 
                    select={groupIdUsesHandlebars ? false : true}
                    onChange={(e)=>setGroup( groupIdUsesHandlebars ? `${e.target.value}` : parseInt( `${e.target.value}`) || null )}
                >
                    {!groupIdUsesHandlebars ? [
                      <MenuItem key="select" value={0}>Select</MenuItem>,
                      ...groups.map(t => <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>)
                    ] : null}
                </TextField>
                <TextField sx={{minWidth: '100px'}} size="small" value={groupIdInterpreter || 'DIRECT'} onChange={(e)=>setGroupIdInterpreter(e.target.value)} select >
                            <MenuItem value="DIRECT">Direct</MenuItem>
                            <MenuItem value="HANDLEBARS">Handlebars</MenuItem>
                          </TextField>
                </Box> }

      <Toolbar><Typography variant="h6" sx={{padding: '2px'}}>Extra Fields</Typography> <IconButton onClick={handleClick} aria-label="add" size="small" ><Icon icon="carbon:add-filled" /></IconButton></Toolbar>
        {extraFields.map( m => <FieldSetting onDelete={()=>{
          setExtraFields( ef => ef.filter( e => e.id !== m.id ) );
        }} onChange={(v)=>{updateExtraField(m.id , v)}} key={m.id} {...m} /> )}
      <Toolbar><Typography variant="h6" sx={{padding: '2px'}}>Complete Options</Typography> <IconButton onClick={(e) => {
        addCompleteOption();
        
      }} aria-label="add" size="small" ><Icon icon="carbon:add-filled" /></IconButton></Toolbar>
      
      <Grid container>
      {completeOptions.length === 0 && <Grid item xs={12}>
        <Typography variant='body2' sx={{textAlign: 'right', fontWeight: 'bold'}} >Complete</Typography>  
        <Handle
          key="generic_complete"
          type="source"
          position={Position.Right}
          id="generic_complete"
          style={{ bottom: 32 , top: 'auto' }}
        />
    </Grid> }
      {completeOptions.map((_, index) => (
        <Grid item xs={12} key={_.id}>
          <TextField onChange={(e)=>{
            
            updateCompleteOption(_.id , e.target.value);

          }} value={_.value} sx={{padding: 1}} size="small" label={_.id} fullWidth InputProps={{endAdornment:
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    removeCompleteOption(_.id);
                  }}
                  edge="end"
                >
                  <Icon icon="bi:trash" />
                </IconButton>
              </InputAdornment>
           }} />
        </Grid>
      ))}
      </Grid>
      <Menu
        id="extra-fields-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {useableExtraFields.map( m => (
          <MenuItem key={m.id} onClick={() => { addExtraField(m) }} disableRipple>
            {m.label}
          </MenuItem>
        ))}
      </Menu>
      </CardContent>
    </Card>
  );
}