import { Box, Button, TextField, Typography, useTheme, IconButton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormField, EnumOption, EnumFormFieldConfig, isEnumFormField } from "../../../../coretypes/formfields";
import { FormFieldType } from "./FormFieldType";
import { Icon } from "@iconify/react";
import { useApi } from "../../../providers/Api/Api";

export const EnumFormField: FC<{
    field: FormField,
    onFieldChange?: (field: Partial<FormField> & Pick<FormField, 'id'>) => void
    onFieldSave?: (field: Partial<FormField> & Pick<FormField, 'id'>) => void
    onFieldDelete?: (field: FormField) => void
    disableAutosave?: boolean
    controlled?: boolean
}> = ({ field, onFieldChange, onFieldDelete, disableAutosave, controlled, onFieldSave }) => {
    const [formFieldDirty, setFormFieldDirty] = useState(false);
    const [formFieldCore, setFormFieldCore] = useState(field);

    const theme = useTheme();
    const formField = controlled ? field : formFieldCore;
    const api = useApi();

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (formFieldDirty && !disableAutosave) {
                setFormFieldDirty(false);
                api.patch(`/forms/field/${formField.id}`, formField);
                onFieldSave && onFieldSave(formField);
            }
        }, 2000);
        return () => clearTimeout(timeout);
    }, [formFieldDirty, disableAutosave]);

    const updateFormParam = (key: keyof FormField, value: any) => {
        setFormFieldDirty(true);
        if (field) {
            if (key === 'name') {
                value = value.toLowerCase().replace(/ /g, '_');
            }
            onFieldChange && onFieldChange({ id: formField.id, [key]: value } as Partial<FormField> & Pick<FormField, 'id'>);
            setFormFieldCore(currentField => { return { ...currentField, [key]: value } });
        }
    }

    const updateFormConfigParam = <K extends keyof EnumFormFieldConfig>(
        key: K,
        value: EnumFormFieldConfig[K]
    ) => {
        if (!isEnumFormField(formField)) return;
        
        setFormFieldDirty(true);
        const newConfig = {
            ...formField.config,
            [key]: value
        } as EnumFormFieldConfig;
        
        onFieldChange?.({
            id: formField.id,
            config: newConfig
        });
        
        setFormFieldCore(currentField => ({
            ...currentField,
            config: newConfig
        }));
    };

    const updateEnumOption = (index: number, key: 'label' | 'value', value: string) => {
        const options = [...(formField.config?.options || [])];
        if (!options[index]) {
            options[index] = { label: '', value: '' };
        }
        options[index][key] = value;
        updateFormConfigParam('options', options);
    }

    const addOption = () => {
        const options = [...(formField.config?.options || []), { label: '', value: '' }];
        updateFormConfigParam('options', options);
    }

    const removeOption = (index: number) => {
        const options = formField.config?.options?.filter((_: EnumOption, i: number) => i !== index) || [];
        updateFormConfigParam('options', options);
    }

    const deleteField = () => {
        if (disableAutosave) {
            onFieldDelete && onFieldDelete(formField);
            return;
        }
        api.delete(`/forms/field/${formField.id}`).then(() => {
            onFieldDelete && onFieldDelete(formField);
        });
    }

    return <Box sx={{
        display: 'flex', flexDirection: 'column', gap: 2, margin: 2, padding: 2,
        border: '1px solid', borderColor: formFieldDirty ? theme.palette.warning.light : '#777',
        borderRadius: 2
    }}>
        <Typography variant="h6">Enum Field</Typography>
        <FormFieldType type={formField.type} onChange={e => updateFormParam('type', e.target.value)} />
        <TextField
            value={formField.name}
            label="Name"
            onChange={e => updateFormParam('name', e.target.value)}
        />
        <TextField
            value={formField.label}
            label="Label"
            onChange={e => updateFormParam('label', e.target.value)}
        />
        <TextField
            value={formField?.config?.defaultValue || ''}
            label="Default Value"
            onChange={e => updateFormConfigParam('defaultValue', e.target.value)}
        />
        
        <Typography variant="subtitle1">Options</Typography>
        {formField.config?.options?.map((option: EnumOption, index: number) => (
            <Box key={index} sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <TextField
                    value={option.label}
                    label="Label"
                    onChange={e => updateEnumOption(index, 'label', e.target.value)}
                />
                <TextField
                    value={option.value}
                    label="Value"
                    onChange={e => updateEnumOption(index, 'value', e.target.value)}
                />
                <IconButton color="error" onClick={() => removeOption(index)}>
                    <Icon icon="mdi:delete" />
                </IconButton>
            </Box>
        ))}
        <Button variant="outlined" onClick={addOption}>Add Option</Button>
        
        <Box><Button color="error" onClick={() => deleteField()}>Delete</Button></Box>
    </Box>;
}; 