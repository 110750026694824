import { Avatar, CardContent, CardHeader, Card, IconButton, Popover, ListItem, List, Divider, ListItemText, Box, Typography, Tooltip, useTheme, ListItemSecondaryAction } from "@mui/material";
import { useUser } from "../../../react-submodules/providers/User";
import { AnyBadge } from "../../../react-submodules/components/AnyBadge";
import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { Timeclock } from "./Timeclock";
import { ActiveUsersQuery, useActiveUsersQuery } from "../gql/ActiveUsers.generated";
import { CONFLICT_COLOR, AZURE_COLOR, SCHEDULE_COLOR, UNSET_COLOR, AZURE_COLOR_HOVER, SCHEDULE_COLOR_HOVER } from "../../../react-submodules/utils/schedule";
import { CALENDAR_ICON, FIRST_UP_ICON, PHONE_ICON, TICKET_ICON } from "../../../react-submodules/constances/icons";
import { InlineIcon , Icon } from "@iconify/react/dist/iconify.js";

import { StatusToColor, StatusToIcon } from "../../../react-submodules/components/TicketPartials/status.util";

const getColor = (user : UserItem) => {
    if(user.activeScheduleInstances?.filter(si => si.available !== true ).length || 0 > 0){
                
        return '#0000ff';
    };

    if(( user.activeTimeclocks?.length || 0 ) > 0) return "yellow";
    if(user.activeScheduleInstances?.filter(si => si.available === true ).length || 0 > 0) return 'yellow';
    
    return '#ccc';
}
export const UserIcons : FC<{ user : UserItem }> = ({ user }) => {
    return <Box>
        { user.activeTimeclocks?.filter( tc => tc.ticket?.id ).map( tc => {
            return <Tooltip title={`${tc.ticket?.ticketNumber} - ${tc.ticket?.title}`}>
                    <IconButton onClick={() => { window.open( `/ticketqueue/all/${tc.ticket?.id}` , `ticket_${tc.ticket?.id}` ) }} size="small" sx={{ color: 'black', backgroundColor: StatusToColor( tc?.ticket?.autotaskTicketStatus?.name || 'Open') , width: '24px' , height: '24px' , marginRight: 0.25}} key={tc.id}>
                        <InlineIcon icon={StatusToIcon( tc?.ticket?.autotaskTicketStatus?.name || 'Open' )} />
                    </IconButton>
                </Tooltip>
        })}
        {user?.activeScheduleInstances?.filter( si => si.available !== true ).map( si => {
            return <Tooltip title={`${si.title || si.AzureCalendar?.subject || si.SchedulePlan?.name}`}>
                    <IconButton onClick={() => { window.open( `/schedule/daily` , 'schedule_daily' ) }} size="small" sx={{ 
                        color: 'white', 
                        backgroundColor: si?.AzureCalendar?.subject ? AZURE_COLOR : SCHEDULE_COLOR, 
                        width: '24px' , 
                        height: '24px' , 
                        marginRight: 0.25,
                        '&:hover': {
                            backgroundColor: si?.AzureCalendar?.subject ? AZURE_COLOR_HOVER : SCHEDULE_COLOR_HOVER,
                        }
                    }} key={si.id}>
                        <InlineIcon icon={CALENDAR_ICON} />
                    </IconButton>
                </Tooltip>
        })}
    </Box>
}

type UserItem = NonNullable<ActiveUsersQuery['findCoreUsersPaginated']['data']>[number];

const BadgeWithBorder : FC<{ children : ReactNode , color? : string , user : UserItem   }> = ({ children , color , user }) => {
    const isFirstUp = useMemo(() => {
        return user?.activeScheduleInstances?.some(si => String( si?.SchedulePlan?.name ).toLocaleLowerCase()?.includes('first up') )
    }, [ JSON.stringify(user?.activeScheduleInstances) ]);
    const theme = useTheme();
    return <Box sx={{ position: 'relative' }}>
        <Box sx={{
        border: '1px solid #ccc',
        
        borderRadius: '50%',
        padding: '2px',
        boxSizing: 'border-box',
        backgroundColor: color,
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        '& > *': { // Target direct children
            width: '100%',
            border: '1px solid #fff',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }}>{children}</Box>
    { isFirstUp && <Box sx={{
        position: 'absolute',
        width: '16px',
        height: '16px',
        padding: '2px',
        bottom: -4,
        right: -4,
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <Icon fontSize="14px" icon={FIRST_UP_ICON} />
    </Box> }
    { user?.isOnCall && <Box sx={{
        position: 'absolute',
        width: '16px',
        height: '16px',
        padding: '2px',
        bottom: -4,
        left: -4,
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <Icon fontSize="14px" icon={PHONE_ICON} />
    </Box> }
    </Box>
}

const UserListItem = ({ user } : { user : UserItem }) => {
    return <ListItem key={user.id}>
    <BadgeWithBorder color={getColor(user)} user={user}>
        <AnyBadge size="large" name={user.firstName + ' ' + user.lastName} colorkey={user.email || ''}  />
    </BadgeWithBorder>
    <ListItemText sx={{ paddingLeft: 2 }} primary={user.firstName + ' ' + user.lastName} secondary={<UserIcons user={user} />} />
</ListItem>
}

export const PopoverInner = () => {
    const user = useUser();
    const { data : activeUsersData , refetch} = useActiveUsersQuery({pollInterval: 15000});
    const activeUsers = activeUsersData?.findCoreUsersPaginated?.data || [];

    const { online , offline , scheduled , unscheduled } = useMemo(() => {
        const online = activeUsers.filter(user => (user.activeTimeclocks?.length || 0) > 0);
        const offline = activeUsers.filter(user => (user.activeTimeclocks?.length || 0) === 0);
        const scheduled = offline.filter(user => (user.activeScheduleInstances?.filter(si => si.available || false).length || 0) > 0);
        const unscheduled = offline.filter(user => (user.activeScheduleInstances?.filter(si => si.available || false).length || 0) === 0);
        return { online , offline , scheduled , unscheduled };
    }, [ JSON.stringify(activeUsers) ]);

    const myself = useMemo(() => {
        return activeUsers.find(u => { 
            return u.email === user?.email && user?.email; } );
    }, [JSON.stringify(activeUsers), user?.email]);

    return <List sx={{ width: '350px' }}>
    {myself && <UserListItem user={myself} /> }
    <Timeclock />
    <Divider />
    {online.map(user => {
        return <UserListItem key={user.id} user={user} />
    })}
    {scheduled.length > 0 && <><Typography variant="caption" sx={{ paddingLeft: 2 }}>Scheduled</Typography><Divider /></>}
    {scheduled.map(user => {
        return <UserListItem key={user.id} user={user} />
    })}
    {unscheduled.length > 0 && <><Typography variant="caption" sx={{ paddingLeft: 2 }}>Unscheduled</Typography><Divider /></>}
    
    {unscheduled.filter( u => ( u.activeScheduleInstances?.length || 0) > 0).map(user => {
        return <UserListItem key={user.id} user={user} />
    })}
</List>
}