import { ExtraFieldSetting, FieldSettingProps } from "./types"

import { Box } from "@mui/material"
import { DateFieldSetting } from "./DateFieldSetting"
import { TextFieldSetting } from "./TextFieldSetting"
import { BooleanFieldSetting } from "./BooleanFieldSetting"
import { NumberFieldSetting } from "./NumberFieldSetting"

export const FieldSetting : React.FC<FieldSettingProps> =  ({ type , ...props }) => {
    switch( type ){
        case 'date':
            return <DateFieldSetting type={type} {...props} />
        case 'text':
            return <TextFieldSetting type={type} {...props} />
        case 'boolean':
            return <BooleanFieldSetting type={type} {...props} />
        case 'number':
            return <NumberFieldSetting type={type} {...props} />
    }

    return <Box>{props.id} {type}</Box>
  }
  

