import { Button, IconButton, ListItemSecondaryAction, ListItem, ListItemText, useTheme } from "@mui/material";
import { useTimeclock } from "../../../react-submodules/providers/Timeclock";
import { useEffect, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { gql, useMutation } from "@apollo/client";
import { useActivePayrollTimeclocksQuery } from "../../../generated/graphql";
import { useUser } from "../../../react-submodules/providers/User";

const CLOCK_IN = gql`
  mutation TimeclockClockin {
    timeclockClockin(isPayroll: true) {
      id
      start
      end
      coreUserId
      isPayroll
    }
  }
`;

const CLOCK_OUT = gql`
  mutation TimeclockClockout($id: Float!) {
    timeclockClockout(id: $id) {
      id
      start
      end
      coreUserId
      isPayroll
    }
  }
`;


export const Timeclock = () => {
    
    const user = useUser();
    const { data, loading, error , refetch } = useActivePayrollTimeclocksQuery({
        variables: {
            coreUserId: user?.tokenParsed?.sub || ''
        }, 
        pollInterval: 15000
    });
    const [now, setNow] = useState(new Date().getTime());
    const [clockIn] = useMutation(CLOCK_IN, {
        onCompleted: () => {
            refetch();
        }
    });
    const [clockOut] = useMutation(CLOCK_OUT, {
        onCompleted: () => {
            refetch();
        }
    });

    // Update time every 15 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date().getTime());
        }, 15000);
        return () => clearInterval(interval);
    }, []);

    // Find active payroll punch
    const payrollPunch = useMemo(() => {
        return data?.timeclocks?.data?.find(punch => !punch.end && punch.isPayroll);
    }, [data]);

    // Calculate duration
    const duration = useMemo(() => {
        if (!payrollPunch) return null;
        const start = new Date(payrollPunch.start).getTime();
        const secondsRaw = (now - start) / 1000;
        const seconds = secondsRaw < 0 ? 0 : secondsRaw;
        const minutes = Math.floor(seconds / 60) % 60;
        const hours = Math.floor(seconds / 60 / 60);
        if (hours) {
            return `${hours}h ${minutes}m`;
        }
        return `${minutes}m`;
    }, [payrollPunch?.start, now]);

    const handleClockIn = () => {
        clockIn();
    }

    const handleClockOut = () => {
        if (!payrollPunch) return;
        clockOut({
            variables: {
                id: payrollPunch.id
            }
        });
    }
    const theme = useTheme();

    if (payrollPunch) {
        return (
            <>
            <ListItem>
                <ListItemText sx={{ paddingLeft: 2 }} primary="Payroll Timeclock" secondary={duration} />
                <ListItemSecondaryAction>
                    <IconButton 
                    onClick={handleClockOut} 
                    size="small" 
                    sx={{ 
                        color: theme.palette.warning.contrastText, 
                        backgroundColor: theme.palette.warning.light, 
                        width: '24px' , 
                        height: '24px' , 
                        marginRight: 0.25,
                        '&:hover': {
                            backgroundColor: theme.palette.warning.light,
                        }
                    }} key={`timeclock_payroll`}>
                        <Icon icon="mdi:stop" />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            </>
        );
    }

    return (
        <ListItem>
            <ListItemText sx={{ paddingLeft: 2 }} primary="Payroll Timeclock" />
            <ListItemSecondaryAction>
                <IconButton
                    color="success"
                    size="small"
                    onClick={handleClockIn}
                    sx={{ 
                        color: theme.palette.success.contrastText, 
                        backgroundColor: theme.palette.success.main, 
                        width: '24px' , 
                        height: '24px' , 
                        marginRight: 0.25,
                        '&:hover': {
                            backgroundColor: theme.palette.success.light,
                        }
                    }}
                >
                    <Icon icon="mdi:play" />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}