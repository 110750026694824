import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  DateTimeIso: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type AiChat = {
  __typename?: 'AIChat';
  aiChatMessages?: Maybe<Array<AiChatMessages>>;
  coreUserId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ticket?: Maybe<AutotaskTickets>;
  ticketId?: Maybe<Scalars['Float']['output']>;
  user?: Maybe<CoreUsers>;
};

export type AiChatMessages = {
  __typename?: 'AIChatMessages';
  chat?: Maybe<AiChat>;
  chatId: Scalars['Float']['output'];
  coreUserId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  message: Scalars['String']['output'];
  rawMessage: Scalars['String']['output'];
  role: Scalars['String']['output'];
  runWithContexts?: Maybe<Scalars['String']['output']>;
  user?: Maybe<CoreUsers>;
};

export type ActionLogAction = {
  __typename?: 'ActionLogAction';
  actionData?: Maybe<Scalars['JSON']['output']>;
  actionTime?: Maybe<Scalars['DateTimeIso']['output']>;
  azureEmailId?: Maybe<Scalars['String']['output']>;
  coreUserId?: Maybe<Scalars['String']['output']>;
  databaseAction?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  loggedFunction?: Maybe<Scalars['String']['output']>;
  module?: Maybe<Scalars['String']['output']>;
  moduleIdNumber?: Maybe<Scalars['Float']['output']>;
  moduleIdString?: Maybe<Scalars['String']['output']>;
  originalData?: Maybe<Scalars['JSON']['output']>;
  ticketId?: Maybe<Scalars['Float']['output']>;
  userActionId?: Maybe<Scalars['String']['output']>;
};

export type AutotaskCompanies = {
  __typename?: 'AutotaskCompanies';
  additionalAddressInformation: Scalars['String']['output'];
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  alternatePhone1: Scalars['String']['output'];
  alternatePhone2: Scalars['String']['output'];
  apiVendorID?: Maybe<Scalars['Int']['output']>;
  assetValue?: Maybe<Scalars['Int']['output']>;
  billToAdditionalAddressInformation: Scalars['String']['output'];
  billToAddressToUse: Scalars['Int']['output'];
  billToAttention: Scalars['String']['output'];
  billToCity: Scalars['String']['output'];
  billToCompanyLocationID?: Maybe<Scalars['Int']['output']>;
  billToCountryID?: Maybe<Scalars['Int']['output']>;
  billToState: Scalars['String']['output'];
  billToZipCode: Scalars['String']['output'];
  billingAddress1: Scalars['String']['output'];
  billingAddress2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  classification?: Maybe<Scalars['Int']['output']>;
  companyCategoryID: Scalars['Int']['output'];
  companyName: Scalars['String']['output'];
  companyNumber: Scalars['String']['output'];
  companyType: Scalars['Int']['output'];
  competitorID?: Maybe<Scalars['Int']['output']>;
  coreCompanyId?: Maybe<Scalars['Int']['output']>;
  countryID?: Maybe<Scalars['Int']['output']>;
  createDate: Scalars['DateTime']['output'];
  createdByResourceID: Scalars['Int']['output'];
  currencyID: Scalars['Int']['output'];
  fax: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  impersonatorCreatorResourceID?: Maybe<Scalars['Int']['output']>;
  invoiceEmailMessageID: Scalars['Int']['output'];
  invoiceMethod?: Maybe<Scalars['Int']['output']>;
  invoiceNonContractItemsToParentCompany?: Maybe<Scalars['Boolean']['output']>;
  invoiceTemplateID: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isClientPortalActive: Scalars['Boolean']['output'];
  isEnabledForComanaged: Scalars['Boolean']['output'];
  isTaskFireActive: Scalars['Boolean']['output'];
  isTaxExempt: Scalars['Boolean']['output'];
  lastActivityDate: Scalars['DateTime']['output'];
  lastTrackedModifiedDateTime: Scalars['DateTime']['output'];
  marketSegmentID?: Maybe<Scalars['Int']['output']>;
  ownerResourceID: Scalars['Int']['output'];
  parentCompanyID?: Maybe<Scalars['Int']['output']>;
  phone: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  purchaseOrderTemplateID?: Maybe<Scalars['Int']['output']>;
  quoteEmailMessageID: Scalars['Int']['output'];
  quoteTemplateID: Scalars['Int']['output'];
  sicCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  stockMarket: Scalars['String']['output'];
  stockSymbol: Scalars['String']['output'];
  surveyCompanyRating?: Maybe<Scalars['Int']['output']>;
  taxID: Scalars['String']['output'];
  taxRegionID?: Maybe<Scalars['Int']['output']>;
  territoryID?: Maybe<Scalars['Int']['output']>;
  tickets: Array<AutotaskTickets>;
  userDefinedFields: Scalars['JSON']['output'];
  webAddress?: Maybe<Scalars['String']['output']>;
};

export type AutotaskConfigurationItems = {
  __typename?: 'AutotaskConfigurationItems';
  DattormmDevice?: Maybe<DattormmDevices>;
  apiVendorID?: Maybe<Scalars['Float']['output']>;
  companyID?: Maybe<Scalars['Float']['output']>;
  companyLocationID?: Maybe<Scalars['Float']['output']>;
  configurationItemCategoryID?: Maybe<Scalars['Float']['output']>;
  configurationItemType?: Maybe<Scalars['Float']['output']>;
  contactID?: Maybe<Scalars['Float']['output']>;
  contractID?: Maybe<Scalars['Float']['output']>;
  contractServiceBundleID?: Maybe<Scalars['Float']['output']>;
  contractServiceID?: Maybe<Scalars['Float']['output']>;
  createDate?: Maybe<Scalars['DateTimeIso']['output']>;
  createdByPersonID?: Maybe<Scalars['Float']['output']>;
  dailyCost?: Maybe<Scalars['Float']['output']>;
  dattoAvailableKilobytes?: Maybe<Scalars['Float']['output']>;
  dattoDeviceMemoryMegabytes?: Maybe<Scalars['Float']['output']>;
  dattoDrivesErrors?: Maybe<Scalars['Boolean']['output']>;
  dattoHostname?: Maybe<Scalars['String']['output']>;
  dattoInternalIP?: Maybe<Scalars['String']['output']>;
  dattoKernelVersionID?: Maybe<Scalars['Float']['output']>;
  dattoLastCheckInDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  dattoNICSpeedKilobitsPerSecond?: Maybe<Scalars['Float']['output']>;
  dattoNumberOfAgents?: Maybe<Scalars['Float']['output']>;
  dattoNumberOfDrives?: Maybe<Scalars['Float']['output']>;
  dattoNumberOfVolumes?: Maybe<Scalars['Float']['output']>;
  dattoOSVersionID?: Maybe<Scalars['Float']['output']>;
  dattoOffsiteUsedBytes?: Maybe<Scalars['Float']['output']>;
  dattoPercentageUsed?: Maybe<Scalars['Float']['output']>;
  dattoProtectedKilobytes?: Maybe<Scalars['Float']['output']>;
  dattoRemoteIP?: Maybe<Scalars['String']['output']>;
  dattoSerialNumber?: Maybe<Scalars['String']['output']>;
  dattoUptimeSeconds?: Maybe<Scalars['Float']['output']>;
  dattoUsedKilobytes?: Maybe<Scalars['Float']['output']>;
  dattoZFSVersionID?: Maybe<Scalars['Float']['output']>;
  deviceNetworkingID?: Maybe<Scalars['Float']['output']>;
  domain?: Maybe<Scalars['Float']['output']>;
  domainExpirationDateTime?: Maybe<Scalars['Float']['output']>;
  domainLastUpdatedDateTime?: Maybe<Scalars['Float']['output']>;
  domainRegistrarID?: Maybe<Scalars['Float']['output']>;
  domainRegistrationDateTime?: Maybe<Scalars['Float']['output']>;
  hourlyCost?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  impersonatorCreatorResourceID?: Maybe<Scalars['Float']['output']>;
  installDate?: Maybe<Scalars['DateTimeIso']['output']>;
  installedByContactID?: Maybe<Scalars['Float']['output']>;
  installedByID?: Maybe<Scalars['Float']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  lastActivityPersonID?: Maybe<Scalars['Float']['output']>;
  lastActivityPersonType?: Maybe<Scalars['Float']['output']>;
  lastModifiedTime?: Maybe<Scalars['DateTimeIso']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  monthlyCost?: Maybe<Scalars['Float']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  numberOfUsers?: Maybe<Scalars['Float']['output']>;
  parentConfigurationItemID?: Maybe<Scalars['Float']['output']>;
  perUseCost?: Maybe<Scalars['Float']['output']>;
  productID?: Maybe<Scalars['Float']['output']>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  referenceTitle?: Maybe<Scalars['String']['output']>;
  rmmDeviceAuditAntivirusStatusID?: Maybe<Scalars['Float']['output']>;
  rmmDeviceAuditArchitectureID?: Maybe<Scalars['Float']['output']>;
  rmmDeviceAuditBackupStatusID?: Maybe<Scalars['Float']['output']>;
  rmmDeviceAuditDescription?: Maybe<Scalars['String']['output']>;
  rmmDeviceAuditDeviceTypeID?: Maybe<Scalars['Float']['output']>;
  rmmDeviceAuditDisplayAdaptorID?: Maybe<Scalars['Float']['output']>;
  rmmDeviceAuditDomainID?: Maybe<Scalars['Float']['output']>;
  rmmDeviceAuditExternalIPAddress?: Maybe<Scalars['String']['output']>;
  rmmDeviceAuditHostname?: Maybe<Scalars['String']['output']>;
  rmmDeviceAuditIPAddress?: Maybe<Scalars['String']['output']>;
  rmmDeviceAuditLastUser?: Maybe<Scalars['String']['output']>;
  rmmDeviceAuditMacAddress?: Maybe<Scalars['String']['output']>;
  rmmDeviceAuditManufacturerID?: Maybe<Scalars['Float']['output']>;
  rmmDeviceAuditMemoryBytes?: Maybe<Scalars['Float']['output']>;
  rmmDeviceAuditMissingPatchCount?: Maybe<Scalars['Float']['output']>;
  rmmDeviceAuditMobileNetworkOperatorID?: Maybe<Scalars['Float']['output']>;
  rmmDeviceAuditMobileNumber?: Maybe<Scalars['String']['output']>;
  rmmDeviceAuditModelID?: Maybe<Scalars['Float']['output']>;
  rmmDeviceAuditMotherboardID?: Maybe<Scalars['Float']['output']>;
  rmmDeviceAuditOperatingSystem?: Maybe<Scalars['String']['output']>;
  rmmDeviceAuditPatchStatusID?: Maybe<Scalars['Float']['output']>;
  rmmDeviceAuditProcessorID?: Maybe<Scalars['Float']['output']>;
  rmmDeviceAuditSNMPContact?: Maybe<Scalars['String']['output']>;
  rmmDeviceAuditSNMPLocation?: Maybe<Scalars['String']['output']>;
  rmmDeviceAuditSNMPName?: Maybe<Scalars['String']['output']>;
  rmmDeviceAuditServicePackID?: Maybe<Scalars['Float']['output']>;
  rmmDeviceAuditSoftwareStatusID?: Maybe<Scalars['Float']['output']>;
  rmmDeviceAuditStorageBytes?: Maybe<Scalars['Float']['output']>;
  rmmDeviceID?: Maybe<Scalars['Float']['output']>;
  rmmDeviceUID?: Maybe<Scalars['String']['output']>;
  rmmOpenAlertCount?: Maybe<Scalars['Float']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  serviceBundleID?: Maybe<Scalars['Float']['output']>;
  serviceID?: Maybe<Scalars['Float']['output']>;
  serviceLevelAgreementID?: Maybe<Scalars['Float']['output']>;
  setupFee?: Maybe<Scalars['Float']['output']>;
  sourceChargeID?: Maybe<Scalars['Float']['output']>;
  sourceChargeType?: Maybe<Scalars['Float']['output']>;
  sslCommonName?: Maybe<Scalars['Float']['output']>;
  sslIssuedBy?: Maybe<Scalars['Float']['output']>;
  sslLocation?: Maybe<Scalars['Float']['output']>;
  sslOrganization?: Maybe<Scalars['Float']['output']>;
  sslOrganizationUnit?: Maybe<Scalars['Float']['output']>;
  sslSerialNumber?: Maybe<Scalars['Float']['output']>;
  sslSignatureAlgorithm?: Maybe<Scalars['Float']['output']>;
  sslSource?: Maybe<Scalars['Float']['output']>;
  sslValidFromDateTime?: Maybe<Scalars['Float']['output']>;
  sslValidUntilDateTime?: Maybe<Scalars['Float']['output']>;
  userDefinedFields?: Maybe<Scalars['JSON']['output']>;
  vendorID?: Maybe<Scalars['Float']['output']>;
  warrantyExpirationDate?: Maybe<Scalars['DateTimeIso']['output']>;
};

export type AutotaskContacts = {
  __typename?: 'AutotaskContacts';
  Contact?: Maybe<CoreContact>;
  additionalAddressInformation: Scalars['String']['output'];
  addressLine: Scalars['String']['output'];
  addressLine1: Scalars['String']['output'];
  alternatePhone?: Maybe<Scalars['String']['output']>;
  apiVendorID?: Maybe<Scalars['Float']['output']>;
  bulkEmailOptOutTime?: Maybe<Scalars['DateTimeIso']['output']>;
  city: Scalars['String']['output'];
  companyID: Scalars['Float']['output'];
  companyLocationID?: Maybe<Scalars['Float']['output']>;
  coreCompanyId?: Maybe<Scalars['Float']['output']>;
  coreContactId?: Maybe<Scalars['Float']['output']>;
  countryID?: Maybe<Scalars['Float']['output']>;
  createDate: Scalars['DateTimeIso']['output'];
  emailAddress: Scalars['String']['output'];
  emailAddress2?: Maybe<Scalars['String']['output']>;
  emailAddress3?: Maybe<Scalars['String']['output']>;
  extension?: Maybe<Scalars['String']['output']>;
  externalID: Scalars['String']['output'];
  facebookUrl: Scalars['String']['output'];
  faxNumber: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  impersonatorCreatorResourceID?: Maybe<Scalars['Float']['output']>;
  isActive: Scalars['Float']['output'];
  isOptedOutFromBulkEmail: Scalars['Boolean']['output'];
  lastActivityDate: Scalars['DateTimeIso']['output'];
  lastModifiedDate: Scalars['DateTimeIso']['output'];
  lastName: Scalars['String']['output'];
  linkedInUrl: Scalars['String']['output'];
  middleInitial?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  namePrefix?: Maybe<Scalars['Float']['output']>;
  nameSuffix?: Maybe<Scalars['Float']['output']>;
  note: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  primaryContact: Scalars['Boolean']['output'];
  receivesEmailNotifications: Scalars['Boolean']['output'];
  roomNumber: Scalars['String']['output'];
  solicitationOptOut: Scalars['Boolean']['output'];
  solicitationOptOutTime?: Maybe<Scalars['DateTimeIso']['output']>;
  state: Scalars['String']['output'];
  surveyOptOut: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  twitterUrl: Scalars['String']['output'];
  userDefinedFields: Scalars['JSON']['output'];
  zipCode: Scalars['String']['output'];
};

export type AutotaskTicketQueues = {
  __typename?: 'AutotaskTicketQueues';
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isSystem: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parentValue: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
  tickets: Array<AutotaskTickets>;
};

export type AutotaskTicketStatuses = {
  __typename?: 'AutotaskTicketStatuses';
  id?: Maybe<Scalars['Float']['output']>;
  isActive: Scalars['Boolean']['output'];
  isSystem: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parentValue: Scalars['String']['output'];
  sortOrder: Scalars['Float']['output'];
  tickets?: Maybe<Array<AutotaskTickets>>;
};

export type AutotaskTickets = {
  __typename?: 'AutotaskTickets';
  ChildTickets?: Maybe<Array<AutotaskTickets>>;
  CoreCompany?: Maybe<CoreCompanies>;
  ParentTicket?: Maybe<AutotaskTickets>;
  PhoneCalls?: Maybe<Array<PhonesCalls>>;
  ScheduleInstances?: Maybe<Array<ScheduleInstance>>;
  SchedulePlans?: Maybe<Array<SchedulePlan>>;
  TicketTimelines?: Maybe<Array<TicketTimeline>>;
  aisummary?: Maybe<Scalars['String']['output']>;
  apiVendorID?: Maybe<Scalars['Float']['output']>;
  archived: Scalars['Boolean']['output'];
  assignedResourceID?: Maybe<Scalars['Float']['output']>;
  assignedResourceRoleID?: Maybe<Scalars['Float']['output']>;
  autotaskCompany?: Maybe<AutotaskCompanies>;
  autotaskTicketQueue?: Maybe<AutotaskTicketQueues>;
  autotaskTicketStatus?: Maybe<AutotaskTicketStatuses>;
  billingCodeID?: Maybe<Scalars['Float']['output']>;
  changeApprovalBoard?: Maybe<Scalars['Float']['output']>;
  changeApprovalStatus?: Maybe<Scalars['Float']['output']>;
  changeApprovalType?: Maybe<Scalars['Float']['output']>;
  changeInfoField1?: Maybe<Scalars['String']['output']>;
  changeInfoField2: Scalars['String']['output'];
  changeInfoField3: Scalars['String']['output'];
  changeInfoField4: Scalars['String']['output'];
  changeInfoField5: Scalars['String']['output'];
  chats: Array<AiChat>;
  companyID?: Maybe<Scalars['Float']['output']>;
  companyLocationID?: Maybe<Scalars['Float']['output']>;
  completedBy?: Maybe<Scalars['String']['output']>;
  completedByResourceID?: Maybe<Scalars['Float']['output']>;
  completedDate?: Maybe<Scalars['DateTimeIso']['output']>;
  configurationItemID?: Maybe<Scalars['Float']['output']>;
  contact?: Maybe<AutotaskContacts>;
  contactID?: Maybe<Scalars['Float']['output']>;
  contactlessStart?: Maybe<Scalars['Boolean']['output']>;
  contractID?: Maybe<Scalars['Float']['output']>;
  contractServiceBundleID?: Maybe<Scalars['Float']['output']>;
  contractServiceID?: Maybe<Scalars['Float']['output']>;
  coreCompanyId?: Maybe<Scalars['Float']['output']>;
  createDate: Scalars['DateTimeIso']['output'];
  createdByContactID?: Maybe<Scalars['Float']['output']>;
  createdByCoreUserId?: Maybe<Scalars['String']['output']>;
  createdDateTime: Scalars['DateTime']['output'];
  creatorResourceID: Scalars['Float']['output'];
  creatorType: Scalars['Float']['output'];
  currentServiceThermometerRating?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dueDateTime: Scalars['DateTimeIso']['output'];
  enlightendTicketClass?: Maybe<Scalars['String']['output']>;
  estimatedHours?: Maybe<Scalars['Float']['output']>;
  externalID?: Maybe<Scalars['String']['output']>;
  firstResponseAssignedResourceID?: Maybe<Scalars['Float']['output']>;
  firstResponseDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  firstResponseDueDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  firstResponseInitiatingResourceID?: Maybe<Scalars['Float']['output']>;
  fromAutotask: Scalars['Boolean']['output'];
  hoursToBeScheduled?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  impersonatorCreatorResourceID?: Maybe<Scalars['Float']['output']>;
  isAssignedToComanaged: Scalars['Boolean']['output'];
  isVisibleToComanaged: Scalars['Boolean']['output'];
  issueType?: Maybe<Scalars['Float']['output']>;
  lastActivityDate: Scalars['DateTimeIso']['output'];
  lastActivityPersonType: Scalars['Float']['output'];
  lastActivityResourceID: Scalars['Float']['output'];
  lastCustomerNotificationDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  lastCustomerVisibleActivityDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  lastImportanceSync?: Maybe<Scalars['DateTimeIso']['output']>;
  lastTrackedModificationDateTime: Scalars['DateTimeIso']['output'];
  maxImportanceRank?: Maybe<Scalars['Float']['output']>;
  mergeToTicketId?: Maybe<Scalars['Float']['output']>;
  monitorID?: Maybe<Scalars['Float']['output']>;
  monitorTypeID?: Maybe<Scalars['Float']['output']>;
  nextActionDate?: Maybe<Scalars['DateTimeIso']['output']>;
  nextActionNote?: Maybe<Scalars['String']['output']>;
  nextActionTime?: Maybe<Scalars['Float']['output']>;
  nextActionType?: Maybe<Scalars['String']['output']>;
  opportunityID?: Maybe<Scalars['Float']['output']>;
  organizationalLevelAssociationID?: Maybe<Scalars['Float']['output']>;
  parentTicketId?: Maybe<Scalars['Float']['output']>;
  previousServiceThermometerRating?: Maybe<Scalars['Float']['output']>;
  priority?: Maybe<Scalars['Float']['output']>;
  problemTicketId?: Maybe<Scalars['Float']['output']>;
  projectID?: Maybe<Scalars['Float']['output']>;
  purchaseOrderNumber?: Maybe<Scalars['String']['output']>;
  queueID?: Maybe<Scalars['Float']['output']>;
  recipeInstanceId?: Maybe<Scalars['Float']['output']>;
  resolution?: Maybe<Scalars['String']['output']>;
  resolutionPlanDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  resolutionPlanDueDateTime?: Maybe<Scalars['Float']['output']>;
  resolvedDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  resolvedDueDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  resyncImportanceRank: Scalars['Boolean']['output'];
  rmaStatus?: Maybe<Scalars['Float']['output']>;
  rmaType?: Maybe<Scalars['Float']['output']>;
  rmmAlertID?: Maybe<Scalars['String']['output']>;
  serviceLevelAgreementHasBeenMet?: Maybe<Scalars['Float']['output']>;
  serviceLevelAgreementID?: Maybe<Scalars['Float']['output']>;
  serviceLevelAgreementPausedNextEventHours?: Maybe<Scalars['Float']['output']>;
  serviceThermometerTemperature?: Maybe<Scalars['Float']['output']>;
  source?: Maybe<Scalars['Float']['output']>;
  status: Scalars['Float']['output'];
  subIssueType?: Maybe<Scalars['Float']['output']>;
  tech?: Maybe<CoreUsers>;
  ticketCategory: Scalars['Float']['output'];
  ticketNumber: Scalars['String']['output'];
  ticketType?: Maybe<Scalars['Float']['output']>;
  timeclocks?: Maybe<Array<CoreTimeclock>>;
  title: Scalars['String']['output'];
  updatedByCoreUserId?: Maybe<Scalars['String']['output']>;
  updatedDateTime: Scalars['DateTime']['output'];
  userDefinedFields?: Maybe<Scalars['JSON']['output']>;
};

export type AzureCalendar = {
  __typename?: 'AzureCalendar';
  AzureCalendarParticipants?: Maybe<Array<AzureCalendarParticipant>>;
  ScheduleInstances?: Maybe<Array<ScheduleInstance>>;
  bodyPreview: Scalars['String']['output'];
  createdDateTime: Scalars['DateTimeIso']['output'];
  end: Scalars['DateTimeIso']['output'];
  id?: Maybe<Scalars['String']['output']>;
  isAllDay: Scalars['Boolean']['output'];
  isCancelled: Scalars['Boolean']['output'];
  lastModifiedDateTime: Scalars['DateTimeIso']['output'];
  mailbox: Scalars['String']['output'];
  onlineMeetingUrl?: Maybe<Scalars['String']['output']>;
  start: Scalars['DateTimeIso']['output'];
  subject?: Maybe<Scalars['String']['output']>;
  syncId?: Maybe<Scalars['String']['output']>;
  webLink?: Maybe<Scalars['String']['output']>;
};

export type AzureCalendarParticipant = {
  __typename?: 'AzureCalendarParticipant';
  AzureCalendar?: Maybe<AzureCalendar>;
  calendarId?: Maybe<Scalars['String']['output']>;
};

export type AzureDirectoryRoleMembers = {
  __typename?: 'AzureDirectoryRoleMembers';
  AzureDirectoryRole?: Maybe<AzureDirectoryRoles>;
  AzureUser?: Maybe<AzureUsers>;
  azureDirectoryRoleId?: Maybe<Scalars['String']['output']>;
  azureUserId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

export type AzureDirectoryRoles = {
  __typename?: 'AzureDirectoryRoles';
  AzureDirectoryRoleMembers?: Maybe<Array<AzureDirectoryRoleMembers>>;
  AzureTenant?: Maybe<AzureTenants>;
  azureTenantId?: Maybe<Scalars['String']['output']>;
  createdByCoreUserId?: Maybe<Scalars['String']['output']>;
  createdDateTime: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastSync?: Maybe<Scalars['DateTimeIso']['output']>;
  roleTemplateId?: Maybe<Scalars['String']['output']>;
  updatedByCoreUserId?: Maybe<Scalars['String']['output']>;
  updatedDateTime: Scalars['DateTime']['output'];
};

export type AzureEmail = {
  __typename?: 'AzureEmail';
  AzureEmailAttachments?: Maybe<Array<AzureEmailAttachment>>;
  AzureEmailParticipants?: Maybe<Array<AzureEmailParticipatnt>>;
  TicketTimelines?: Maybe<Array<TicketTimeline>>;
  attachmentsSynced: Scalars['Boolean']['output'];
  autotaskTicketId?: Maybe<Scalars['Float']['output']>;
  bodyHtml?: Maybe<Scalars['String']['output']>;
  bodyMarkdown?: Maybe<Scalars['String']['output']>;
  bodyPreview?: Maybe<Scalars['String']['output']>;
  conversationId?: Maybe<Scalars['String']['output']>;
  conversationIndex?: Maybe<Scalars['String']['output']>;
  coreCompanyId?: Maybe<Scalars['Float']['output']>;
  createdDateTime: Scalars['DateTimeIso']['output'];
  id?: Maybe<Scalars['String']['output']>;
  lastModifiedDateTime: Scalars['DateTimeIso']['output'];
  mailbox?: Maybe<Scalars['String']['output']>;
  markdownComplete: Scalars['Boolean']['output'];
  mergeFromTicketId?: Maybe<Scalars['Float']['output']>;
  parentFolderId?: Maybe<Scalars['String']['output']>;
  receivedDateTime: Scalars['DateTimeIso']['output'];
  subject: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
  webhookRan: Scalars['Boolean']['output'];
};

export type AzureEmailAttachment = {
  __typename?: 'AzureEmailAttachment';
  AzureEmail?: Maybe<AzureEmail>;
  contentId?: Maybe<Scalars['String']['output']>;
  contentLocation?: Maybe<Scalars['String']['output']>;
  contentType?: Maybe<Scalars['String']['output']>;
  emailId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isInline?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AzureEmailParticipatnt = {
  __typename?: 'AzureEmailParticipatnt';
  AzureEmail?: Maybe<AzureEmail>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  emailId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  userRole?: Maybe<Scalars['String']['output']>;
};

export type AzureGroupLabel = {
  __typename?: 'AzureGroupLabel';
  labelId: Scalars['String']['output'];
  labelName: Scalars['String']['output'];
};

export type AzureGroupLicense = {
  __typename?: 'AzureGroupLicense';
  licenseId: Scalars['String']['output'];
};

export type AzureGroupProvisioningError = {
  __typename?: 'AzureGroupProvisioningError';
  error: Scalars['String']['output'];
};

export type AzureGroups = {
  __typename?: 'AzureGroups';
  AzureGroupMembers?: Maybe<Array<AzureGroupsMembers>>;
  AzureTenant?: Maybe<AzureTenants>;
  allowExternalSenders?: Maybe<Scalars['Boolean']['output']>;
  assignedLabels?: Maybe<Array<AzureGroupLabel>>;
  assignedLicenses?: Maybe<Array<AzureGroupLicense>>;
  autoSubscribeNewMembers?: Maybe<Scalars['Boolean']['output']>;
  azureTenantId?: Maybe<Scalars['String']['output']>;
  classification?: Maybe<Scalars['String']['output']>;
  createdByCoreUserId?: Maybe<Scalars['String']['output']>;
  createdDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  deletedDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  expirationDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  groupTypes?: Maybe<Array<Scalars['String']['output']>>;
  hasMembersWithLicenseErrors?: Maybe<Scalars['Boolean']['output']>;
  hideFromAddressLists?: Maybe<Scalars['Boolean']['output']>;
  hideFromOutlookClients?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isAssignableToRole?: Maybe<Scalars['Boolean']['output']>;
  isSubscribedByMail?: Maybe<Scalars['Boolean']['output']>;
  lastSync?: Maybe<Scalars['DateTimeIso']['output']>;
  licenseProcessingState?: Maybe<Scalars['String']['output']>;
  mail?: Maybe<Scalars['String']['output']>;
  mailEnabled?: Maybe<Scalars['Boolean']['output']>;
  mailNickname?: Maybe<Scalars['String']['output']>;
  membershipRule?: Maybe<Scalars['String']['output']>;
  membershipRuleProcessingState?: Maybe<Scalars['String']['output']>;
  onPremisesDomainName?: Maybe<Scalars['String']['output']>;
  onPremisesLastSyncDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  onPremisesNetBiosName?: Maybe<Scalars['String']['output']>;
  onPremisesProvisioningErrors?: Maybe<Array<AzureGroupProvisioningError>>;
  onPremisesSamAccountName?: Maybe<Scalars['String']['output']>;
  onPremisesSecurityIdentifier?: Maybe<Scalars['String']['output']>;
  onPremisesSyncEnabled?: Maybe<Scalars['Boolean']['output']>;
  preferredDataLocation?: Maybe<Scalars['String']['output']>;
  preferredLanguage?: Maybe<Scalars['String']['output']>;
  proxyAddresses?: Maybe<Array<Scalars['String']['output']>>;
  renewedDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  securityEnabled?: Maybe<Scalars['Boolean']['output']>;
  securityIdentifier?: Maybe<Scalars['String']['output']>;
  serviceProvisioningErrors?: Maybe<Array<AzureGroupProvisioningError>>;
  theme?: Maybe<Scalars['String']['output']>;
  uniqueName?: Maybe<Scalars['String']['output']>;
  unseenCount?: Maybe<Scalars['Float']['output']>;
  updatedByCoreUserId?: Maybe<Scalars['String']['output']>;
  updatedDateTime: Scalars['DateTime']['output'];
  visibility?: Maybe<Scalars['String']['output']>;
};

export type AzureGroupsMembers = {
  __typename?: 'AzureGroupsMembers';
  AzureGroup?: Maybe<AzureGroups>;
  AzureUser?: Maybe<AzureUsers>;
  azureGroupId?: Maybe<Scalars['String']['output']>;
  azureUserId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

export type AzureLicense = {
  __typename?: 'AzureLicense';
  AzureUser?: Maybe<AzureUsers>;
  azureUserId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  skuId: Scalars['String']['output'];
  skuPartNumber: Scalars['String']['output'];
};

export type AzureTenants = {
  __typename?: 'AzureTenants';
  AzureDirectoryRoles?: Maybe<Array<AzureDirectoryRoles>>;
  AzureGroups?: Maybe<Array<AzureGroups>>;
  AzureUsers?: Maybe<Array<AzureUsers>>;
  CoreCompany?: Maybe<CoreCompanies>;
  coreCompanyId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  licensed?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  verified: Scalars['Boolean']['output'];
};

export type AzureUsers = {
  __typename?: 'AzureUsers';
  AzureDirectoryRoleMembers?: Maybe<Array<AzureDirectoryRoleMembers>>;
  AzureDirectoryRoles?: Maybe<Array<AzureDirectoryRoles>>;
  AzureGroupMembers?: Maybe<Array<AzureGroupsMembers>>;
  AzureGroups?: Maybe<Array<AzureGroups>>;
  AzureLicenses?: Maybe<Array<AzureLicense>>;
  AzureTenant?: Maybe<AzureTenants>;
  Contact?: Maybe<CoreContact>;
  accountEnabled?: Maybe<Scalars['Boolean']['output']>;
  authenticationMethods?: Maybe<Scalars['JSON']['output']>;
  businessPhones: Scalars['JSON']['output'];
  coreContactId?: Maybe<Scalars['Float']['output']>;
  displayName: Scalars['String']['output'];
  givenName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isMfaRegistered?: Maybe<Scalars['Boolean']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastMfaCheckDate?: Maybe<Scalars['DateTimeIso']['output']>;
  lastSynced?: Maybe<Scalars['DateTimeIso']['output']>;
  mail?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  officeLocation?: Maybe<Scalars['String']['output']>;
  preferredLanguage?: Maybe<Scalars['String']['output']>;
  surname?: Maybe<Scalars['String']['output']>;
  tenantId: Scalars['String']['output'];
  userPrincipalName: Scalars['String']['output'];
};

export type BooleanProp = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  not?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BvoipCalls = {
  __typename?: 'BvoipCalls';
  ActualEndTime?: Maybe<Scalars['DateTimeIso']['output']>;
  ActualStartTime?: Maybe<Scalars['DateTimeIso']['output']>;
  CCS_ID?: Maybe<Scalars['String']['output']>;
  CRMCompany?: Maybe<Scalars['String']['output']>;
  CRMContactCompany?: Maybe<Scalars['String']['output']>;
  CRMContactCompanyID?: Maybe<Scalars['String']['output']>;
  CRMContactID?: Maybe<Scalars['String']['output']>;
  CRMContactLink?: Maybe<Scalars['String']['output']>;
  CRMDetailsLink?: Maybe<Scalars['String']['output']>;
  CRMPlatform?: Maybe<Scalars['String']['output']>;
  CRMTicketID?: Maybe<Scalars['String']['output']>;
  CRMTicketLink?: Maybe<Scalars['String']['output']>;
  CallID?: Maybe<Scalars['Float']['output']>;
  DNSummary?: Maybe<Scalars['String']['output']>;
  Destination?: Maybe<Scalars['String']['output']>;
  DestinationName?: Maybe<Scalars['String']['output']>;
  DownloadRecording?: Maybe<Scalars['String']['output']>;
  Entry_Date?: Maybe<Scalars['DateTimeIso']['output']>;
  ExtensionName?: Maybe<Scalars['String']['output']>;
  ExtensionNumber?: Maybe<Scalars['String']['output']>;
  HourNumber?: Maybe<Scalars['Float']['output']>;
  IVR?: Maybe<Scalars['String']['output']>;
  IVRName?: Maybe<Scalars['String']['output']>;
  Inbound?: Maybe<Scalars['String']['output']>;
  IntervalNumber?: Maybe<Scalars['Float']['output']>;
  MinuteNumber?: Maybe<Scalars['Float']['output']>;
  NC_ID?: Maybe<Scalars['String']['output']>;
  OriginatedBy?: Maybe<Scalars['String']['output']>;
  OriginatedByName?: Maybe<Scalars['String']['output']>;
  PhoneCall?: Maybe<PhonesCalls>;
  Queue?: Maybe<Scalars['String']['output']>;
  QueueName?: Maybe<Scalars['String']['output']>;
  QueueWaitSeconds?: Maybe<Scalars['Float']['output']>;
  RecordingPlayer?: Maybe<Scalars['String']['output']>;
  RecordingURL?: Maybe<Scalars['String']['output']>;
  ReferenceDate?: Maybe<Scalars['DateTimeIso']['output']>;
  RingGroup?: Maybe<Scalars['String']['output']>;
  RingGroupName?: Maybe<Scalars['String']['output']>;
  SecondsTillFirstTalk?: Maybe<Scalars['Float']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  TalkTime?: Maybe<Scalars['String']['output']>;
  TalkTimeSeconds?: Maybe<Scalars['Float']['output']>;
  Transcription?: Maybe<Scalars['String']['output']>;
  Transferred?: Maybe<Scalars['String']['output']>;
  call_history_id?: Maybe<Scalars['String']['output']>;
  errorDownloadingCount?: Maybe<Scalars['Float']['output']>;
  localPath?: Maybe<Scalars['String']['output']>;
  phonesCallId?: Maybe<Scalars['Float']['output']>;
  recordingSaved?: Maybe<Scalars['Boolean']['output']>;
  recordingSummarized?: Maybe<Scalars['Boolean']['output']>;
  recordingSummarizing?: Maybe<Scalars['Boolean']['output']>;
  recordingTranscribed?: Maybe<Scalars['Boolean']['output']>;
  recordingTranscribing?: Maybe<Scalars['Boolean']['output']>;
  transcribingBackendId?: Maybe<Scalars['String']['output']>;
  uploadAttempts?: Maybe<Scalars['Float']['output']>;
  uploadingBackendId?: Maybe<Scalars['String']['output']>;
};

export type ContractsAssignments = {
  __typename?: 'ContractsAssignments';
  coreContactId?: Maybe<Scalars['Float']['output']>;
  coreLocationId?: Maybe<Scalars['Float']['output']>;
  device?: Maybe<DattormmDevices>;
  deviceId?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  instance?: Maybe<ContractsInstances>;
  instanceId: Scalars['Float']['output'];
};

export type ContractsContracts = {
  __typename?: 'ContractsContracts';
  additionalPrice: Scalars['Float']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  iconColor?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  instances?: Maybe<Array<ContractsInstances>>;
  internalDescription: Scalars['String']['output'];
  internalUnitCost?: Maybe<Scalars['Float']['output']>;
  laborIncluded: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  publicDescription: Scalars['String']['output'];
  service?: Maybe<Scalars['String']['output']>;
  unitPrice: Scalars['Float']['output'];
  unitsMeasured: Scalars['String']['output'];
};

export type ContractsInstances = {
  __typename?: 'ContractsInstances';
  CoreCompanies?: Maybe<Array<CoreCompanies>>;
  additionalPrice?: Maybe<Scalars['Float']['output']>;
  approvedUnits: Scalars['Int']['output'];
  assignments?: Maybe<Array<ContractsAssignments>>;
  autoAproveUnits: Scalars['Boolean']['output'];
  contract?: Maybe<ContractsContracts>;
  contractId: Scalars['Int']['output'];
  coreCompany?: Maybe<CoreCompanies>;
  coreCompanyId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  unitPrice?: Maybe<Scalars['Float']['output']>;
  usedUnits?: Maybe<Scalars['Int']['output']>;
};

export type CoreCompanies = {
  __typename?: 'CoreCompanies';
  AutotaskTicketss?: Maybe<Array<AutotaskTickets>>;
  AzureTenants?: Maybe<Array<AzureTenants>>;
  ContractsInstances?: Maybe<ContractsInstances>;
  CoreLocations?: Maybe<Array<CoreLocations>>;
  CoreUsers?: Maybe<Array<CoreUsers>>;
  DattormmSites?: Maybe<DattormmSites>;
  PhoneCalls?: Maybe<Array<PhonesCalls>>;
  active: Scalars['Boolean']['output'];
  autotaskCompanyId?: Maybe<Scalars['Float']['output']>;
  billingConfigured: Scalars['Boolean']['output'];
  contractsInstances?: Maybe<Scalars['Float']['output']>;
  dattormmSites?: Maybe<Scalars['Float']['output']>;
  defaultHourlyRate: Scalars['Float']['output'];
  defaultTicketLevel: Scalars['String']['output'];
  id?: Maybe<Scalars['Float']['output']>;
  instances?: Maybe<Array<ContractsInstances>>;
  isOwnCompany: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  sites?: Maybe<Array<DattormmSites>>;
};

export type CoreContact = {
  __typename?: 'CoreContact';
  AutotaskContactss?: Maybe<Array<AutotaskContacts>>;
  AzureUsers?: Maybe<Array<AzureUsers>>;
  PhoneCallLegs?: Maybe<Array<PhonesCallLegs>>;
  PhoneCalls?: Maybe<Array<PhonesCalls>>;
  active?: Maybe<Scalars['Boolean']['output']>;
  alternatePhone?: Maybe<Scalars['String']['output']>;
  coreCompanyId: Scalars['Float']['output'];
  coreLocationId?: Maybe<Scalars['Float']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mobilePhone?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneExtension?: Maybe<Scalars['String']['output']>;
};

export type CoreGroup = {
  __typename?: 'CoreGroup';
  ScheduleInstances?: Maybe<Array<ScheduleInstance>>;
  SchedulePlans?: Maybe<Array<SchedulePlan>>;
  assignees?: Maybe<Array<TodosAssignee>>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  members?: Maybe<Array<CoreGroupsMembers>>;
  name: Scalars['String']['output'];
};

export type CoreGroupsMembers = {
  __typename?: 'CoreGroupsMembers';
  coreUserId?: Maybe<Scalars['String']['output']>;
  group: CoreGroup;
  groupId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  user: CoreUsers;
};

export type CoreLocations = {
  __typename?: 'CoreLocations';
  CoreCompany?: Maybe<CoreCompanies>;
  active: Scalars['Boolean']['output'];
  address1: Scalars['String']['output'];
  address2: Scalars['String']['output'];
  city: Scalars['String']['output'];
  coreCompanyId?: Maybe<Scalars['Float']['output']>;
  extension?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  isPrimary: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  state: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type CoreTag = {
  __typename?: 'CoreTag';
  autotaskTicketId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Date']['output'];
  createdByService?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  tagLink?: Maybe<CoreTagLink>;
  updatedAt: Scalars['Date']['output'];
};

export type CoreTagLink = {
  __typename?: 'CoreTagLink';
  createdAt: Scalars['Date']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
  tags: Array<CoreTag>;
  updatedAt: Scalars['Date']['output'];
};

export type CoreTimeclock = {
  __typename?: 'CoreTimeclock';
  TicketTimelines?: Maybe<Array<TicketTimeline>>;
  autotaskUserId?: Maybe<Scalars['Float']['output']>;
  coreUserId?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['DateTimeIso']['output']>;
  fromRole?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  isDispatchedTicket?: Maybe<Scalars['Boolean']['output']>;
  isPayroll?: Maybe<Scalars['Boolean']['output']>;
  isSynced: Scalars['Boolean']['output'];
  lastActive?: Maybe<Scalars['DateTimeIso']['output']>;
  mergeFromTicketId?: Maybe<Scalars['Float']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  originalEnd?: Maybe<Scalars['DateTimeIso']['output']>;
  originalStart?: Maybe<Scalars['DateTimeIso']['output']>;
  start?: Maybe<Scalars['DateTimeIso']['output']>;
  ticket?: Maybe<AutotaskTickets>;
  ticketCompleted?: Maybe<Scalars['Boolean']['output']>;
  ticketId?: Maybe<Scalars['Float']['output']>;
  ticketUpdateData?: Maybe<Scalars['JSON']['output']>;
  todoId?: Maybe<Scalars['Float']['output']>;
  user?: Maybe<CoreUsers>;
};

export type CoreUsers = {
  __typename?: 'CoreUsers';
  CoreCompany?: Maybe<CoreCompanies>;
  PhoneCallLegs?: Maybe<Array<PhonesCallLegs>>;
  PhoneCalls?: Maybe<Array<PhonesCalls>>;
  ScheduleInstances?: Maybe<Array<ScheduleInstance>>;
  SchedulePlans?: Maybe<Array<SchedulePlan>>;
  Todos?: Maybe<Array<TodosTodo>>;
  active?: Maybe<Scalars['Boolean']['output']>;
  activeScheduleInstances?: Maybe<Array<ScheduleInstance>>;
  activeTimeclocks?: Maybe<Array<CoreTimeclock>>;
  assignees?: Maybe<Array<TodosAssignee>>;
  autotaskId?: Maybe<Scalars['Float']['output']>;
  autotaskLocationId?: Maybe<Scalars['Float']['output']>;
  bvoipExtention?: Maybe<Scalars['Float']['output']>;
  canContactCustomers?: Maybe<Scalars['Boolean']['output']>;
  coreCompanyId?: Maybe<Scalars['Float']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  getUserByAutotaskId?: Maybe<CoreUsers>;
  groups?: Maybe<Array<CoreGroupsMembers>>;
  id?: Maybe<Scalars['String']['output']>;
  isDispatchable?: Maybe<Scalars['Boolean']['output']>;
  isOnCall?: Maybe<Scalars['Boolean']['output']>;
  isRoleTier1?: Maybe<Scalars['Boolean']['output']>;
  isRoleTier2?: Maybe<Scalars['Boolean']['output']>;
  isRoleTier2FirstUp?: Maybe<Scalars['Boolean']['output']>;
  isRoleTriage?: Maybe<Scalars['Boolean']['output']>;
  isScheduler?: Maybe<Scalars['Boolean']['output']>;
  isSherpaChampion?: Maybe<Scalars['Boolean']['output']>;
  isTech?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  techTier?: Maybe<Scalars['Float']['output']>;
  theme: Scalars['String']['output'];
  timwoodsId?: Maybe<Scalars['String']['output']>;
  useNewRank?: Maybe<Scalars['Boolean']['output']>;
  workDayEnd?: Maybe<Scalars['String']['output']>;
  workDayStart?: Maybe<Scalars['String']['output']>;
};

export type DattormmDeviceMemory = {
  __typename?: 'DattormmDeviceMemory';
  DattormmDevice?: Maybe<DattormmDevices>;
  bank?: Maybe<Scalars['String']['output']>;
  dattormmDeviceId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  index?: Maybe<Scalars['Float']['output']>;
  module?: Maybe<Scalars['String']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  speed?: Maybe<Scalars['Float']['output']>;
};

export type DattormmDevices = {
  __typename?: 'DattormmDevices';
  AutotaskConfigurationItemss?: Maybe<Array<AutotaskConfigurationItems>>;
  DattormmDeviceMemory?: Maybe<Array<DattormmDeviceMemory>>;
  a64Bit?: Maybe<Scalars['Boolean']['output']>;
  antivirusProduct?: Maybe<Scalars['String']['output']>;
  antivirusStatus?: Maybe<Scalars['String']['output']>;
  archived: Scalars['Boolean']['output'];
  assignments?: Maybe<Array<ContractsAssignments>>;
  cDriveFreeSpace?: Maybe<Scalars['Float']['output']>;
  cDriveTotalSpace?: Maybe<Scalars['Float']['output']>;
  cagVersion?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  creationDate: Scalars['DateTimeIso']['output'];
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  deviceClass: Scalars['String']['output'];
  displayVersion?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  extIpAddress?: Maybe<Scalars['String']['output']>;
  hostname: Scalars['String']['output'];
  id?: Maybe<Scalars['Float']['output']>;
  intIpAddress: Scalars['String']['output'];
  lastAuditDate: Scalars['DateTimeIso']['output'];
  lastLoggedInUser?: Maybe<Scalars['String']['output']>;
  lastReboot: Scalars['DateTimeIso']['output'];
  lastSeen: Scalars['DateTimeIso']['output'];
  online: Scalars['Boolean']['output'];
  operatingSystem?: Maybe<Scalars['String']['output']>;
  patchStatus?: Maybe<Scalars['String']['output']>;
  patchesApprovedPending: Scalars['Float']['output'];
  patchesInstalled?: Maybe<Scalars['Float']['output']>;
  patchesNotApproved: Scalars['Float']['output'];
  patchingPolicies?: Maybe<Array<DattormmPatchingPolicies>>;
  portalUrl: Scalars['String']['output'];
  rebootRequired?: Maybe<Scalars['Boolean']['output']>;
  site?: Maybe<DattormmSites>;
  siteId: Scalars['Float']['output'];
  siteName: Scalars['String']['output'];
  siteUid: Scalars['String']['output'];
  snmpEnabled: Scalars['Boolean']['output'];
  softwareStatus: Scalars['String']['output'];
  suspended: Scalars['Boolean']['output'];
  syncId?: Maybe<Scalars['String']['output']>;
  totalPhysicalMemory?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  udf1?: Maybe<Scalars['String']['output']>;
  udf2?: Maybe<Scalars['String']['output']>;
  udf3?: Maybe<Scalars['String']['output']>;
  udf4?: Maybe<Scalars['String']['output']>;
  udf5?: Maybe<Scalars['String']['output']>;
  udf6?: Maybe<Scalars['String']['output']>;
  udf7?: Maybe<Scalars['String']['output']>;
  udf8?: Maybe<Scalars['String']['output']>;
  udf9?: Maybe<Scalars['String']['output']>;
  udf10?: Maybe<Scalars['String']['output']>;
  udf11?: Maybe<Scalars['String']['output']>;
  udf12?: Maybe<Scalars['String']['output']>;
  udf13?: Maybe<Scalars['String']['output']>;
  udf14?: Maybe<Scalars['String']['output']>;
  udf15?: Maybe<Scalars['String']['output']>;
  udf16?: Maybe<Scalars['String']['output']>;
  udf17?: Maybe<Scalars['String']['output']>;
  udf18?: Maybe<Scalars['String']['output']>;
  udf19?: Maybe<Scalars['String']['output']>;
  udf20?: Maybe<Scalars['String']['output']>;
  udf21?: Maybe<Scalars['String']['output']>;
  udf22?: Maybe<Scalars['String']['output']>;
  udf23?: Maybe<Scalars['String']['output']>;
  udf24?: Maybe<Scalars['String']['output']>;
  udf25?: Maybe<Scalars['String']['output']>;
  udf26?: Maybe<Scalars['String']['output']>;
  udf27?: Maybe<Scalars['String']['output']>;
  udf28?: Maybe<Scalars['String']['output']>;
  udf29?: Maybe<Scalars['String']['output']>;
  udf30?: Maybe<Scalars['String']['output']>;
  uid: Scalars['String']['output'];
  warrantyDate?: Maybe<Scalars['String']['output']>;
};

export type DattormmPatchingPolicies = {
  __typename?: 'DattormmPatchingPolicies';
  device: DattormmDevices;
  deviceId: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  lastRun?: Maybe<Scalars['Date']['output']>;
  lastSync: Scalars['Date']['output'];
  name: Scalars['String']['output'];
  schedule?: Maybe<Scalars['String']['output']>;
};

export type DattormmSites = {
  __typename?: 'DattormmSites';
  CoreCompanies?: Maybe<Array<CoreCompanies>>;
  accountUid: Scalars['String']['output'];
  autotaskCompanyId?: Maybe<Scalars['String']['output']>;
  autotaskCompanyName?: Maybe<Scalars['String']['output']>;
  coreCompany?: Maybe<CoreCompanies>;
  coreCompanyId?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  devices?: Maybe<Array<DattormmDevices>>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  numberOfDevices: Scalars['Float']['output'];
  numberOfOfflineDevices: Scalars['Float']['output'];
  numberOfOnlineDevices: Scalars['Float']['output'];
  onDemand: Scalars['Boolean']['output'];
  portalUrl: Scalars['String']['output'];
  proxySettings?: Maybe<Scalars['Float']['output']>;
  splashtopAutoInstall: Scalars['Boolean']['output'];
  uid: Scalars['String']['output'];
};

export type FeedbackInstance = {
  __typename?: 'FeedbackInstance';
  autotaskTicketId?: Maybe<Scalars['Int']['output']>;
  coreCompanyId?: Maybe<Scalars['Int']['output']>;
  coreContactId?: Maybe<Scalars['Int']['output']>;
  createdByCoreUserId?: Maybe<Scalars['String']['output']>;
  createdDateTime: Scalars['DateTime']['output'];
  customerEmail?: Maybe<Scalars['String']['output']>;
  customerFeedback?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  score?: Maybe<FeedbackScore>;
  scoreId?: Maybe<Scalars['Int']['output']>;
  updatedByCoreUserId?: Maybe<Scalars['String']['output']>;
  updatedDateTime: Scalars['DateTime']['output'];
};

export type FeedbackScore = {
  __typename?: 'FeedbackScore';
  createdByCoreUserId?: Maybe<Scalars['String']['output']>;
  createdDateTime: Scalars['DateTime']['output'];
  customerResp: Scalars['String']['output'];
  customerRespTitle: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  instances?: Maybe<Array<FeedbackInstance>>;
  name: Scalars['String']['output'];
  updatedByCoreUserId?: Maybe<Scalars['String']['output']>;
  updatedDateTime: Scalars['DateTime']['output'];
};

export type FormsForm = {
  __typename?: 'FormsForm';
  FormFields?: Maybe<Array<FormsFormField>>;
  FormInstances?: Maybe<Array<FormsInstance>>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type FormsFormField = {
  __typename?: 'FormsFormField';
  Form?: Maybe<FormsForm>;
  config?: Maybe<Scalars['JSON']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type FormsInstance = {
  __typename?: 'FormsInstance';
  Form?: Maybe<FormsForm>;
  TicketTimelines?: Maybe<Array<TicketTimeline>>;
  Todos?: Maybe<Array<TodosTodo>>;
  autotaskTicketId?: Maybe<Scalars['Float']['output']>;
  complete: Scalars['Boolean']['output'];
  context?: Maybe<Scalars['JSON']['output']>;
  created_at?: Maybe<Scalars['DateTimeIso']['output']>;
  formId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  recipeInstanceId?: Maybe<Scalars['Float']['output']>;
  todoId?: Maybe<Scalars['Float']['output']>;
  updated_at?: Maybe<Scalars['DateTimeIso']['output']>;
  values?: Maybe<Scalars['JSON']['output']>;
};

export type GetAutotaskCompaniesArgs = {
  coreCompanyId?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
};

export type GetCoreCompaniesSubfieldArgs = {
  active?: InputMaybe<BooleanProp>;
  billingConfigured?: InputMaybe<BooleanProp>;
  contractsInstances?: InputMaybe<NumberProp>;
  dattormmSites?: InputMaybe<NumberProp>;
  defaultHourlyRate?: InputMaybe<NumberProp>;
  defaultTicketLevel?: InputMaybe<StringProp>;
  id?: InputMaybe<NumberProp>;
  isOwnCompany?: InputMaybe<BooleanProp>;
  name?: InputMaybe<StringProp>;
};

export type GetTicketsArgs = {
  apiVendorID?: InputMaybe<NumberProp>;
  archived?: InputMaybe<BooleanProp>;
  assignedResourceID?: InputMaybe<NumberProp>;
  assignedResourceRoleID?: InputMaybe<NumberProp>;
  autotaskCompany?: InputMaybe<GetAutotaskCompaniesArgs>;
  billingCodeID?: InputMaybe<NumberProp>;
  changeApprovalBoard?: InputMaybe<NumberProp>;
  changeApprovalStatus?: InputMaybe<NumberProp>;
  changeApprovalType?: InputMaybe<NumberProp>;
  changeInfoField1?: InputMaybe<StringProp>;
  changeInfoField2?: InputMaybe<StringProp>;
  changeInfoField3?: InputMaybe<StringProp>;
  changeInfoField4?: InputMaybe<StringProp>;
  changeInfoField5?: InputMaybe<StringProp>;
  company?: InputMaybe<GetAutotaskCompaniesArgs>;
  companyID?: InputMaybe<NumberProp>;
  companyLocationID?: InputMaybe<NumberProp>;
  completedBy?: InputMaybe<StringProp>;
  completedByResourceID?: InputMaybe<NumberProp>;
  completedDate?: InputMaybe<TimeQueryProp>;
  configurationItemID?: InputMaybe<NumberProp>;
  contactID?: InputMaybe<NumberProp>;
  contactlessStart?: InputMaybe<BooleanProp>;
  contractID?: InputMaybe<NumberProp>;
  contractServiceBundleID?: InputMaybe<NumberProp>;
  contractServiceID?: InputMaybe<NumberProp>;
  coreCompanyId?: InputMaybe<NumberProp>;
  createDate?: InputMaybe<TimeQueryProp>;
  createdByContactID?: InputMaybe<NumberProp>;
  creatorResourceID?: InputMaybe<NumberProp>;
  creatorType?: InputMaybe<NumberProp>;
  currentServiceThermometerRating?: InputMaybe<NumberProp>;
  dueDateTime?: InputMaybe<TimeQueryProp>;
  enlightendTicketClass?: InputMaybe<StringProp>;
  estimatedHours?: InputMaybe<NumberProp>;
  externalID?: InputMaybe<StringProp>;
  firstResponseAssignedResourceID?: InputMaybe<NumberProp>;
  firstResponseDateTime?: InputMaybe<TimeQueryProp>;
  firstResponseDueDateTime?: InputMaybe<TimeQueryProp>;
  firstResponseInitiatingResourceID?: InputMaybe<NumberProp>;
  fromAutotask?: InputMaybe<BooleanProp>;
  hoursToBeScheduled?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  impersonatorCreatorResourceID?: InputMaybe<NumberProp>;
  issueType?: InputMaybe<NumberProp>;
  lastActivityDate?: InputMaybe<TimeQueryProp>;
  lastActivityPersonType?: InputMaybe<NumberProp>;
  lastActivityResourceID?: InputMaybe<NumberProp>;
  lastCustomerNotificationDateTime?: InputMaybe<TimeQueryProp>;
  lastCustomerVisibleActivityDateTime?: InputMaybe<TimeQueryProp>;
  lastImportanceSync?: InputMaybe<TimeQueryProp>;
  lastTrackedModificationDateTime?: InputMaybe<TimeQueryProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxImportanceRank?: InputMaybe<NumberProp>;
  mergeToTicketId?: InputMaybe<NumberProp>;
  monitorID?: InputMaybe<NumberProp>;
  monitorTypeID?: InputMaybe<NumberProp>;
  nextActionDate?: InputMaybe<TimeQueryProp>;
  nextActionTime?: InputMaybe<NumberProp>;
  nextActionType?: InputMaybe<StringProp>;
  opportunityID?: InputMaybe<NumberProp>;
  order?: InputMaybe<SortTicketsArgs>;
  organizationalLevelAssociationID?: InputMaybe<NumberProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
  parentTicketId?: InputMaybe<NumberProp>;
  previousServiceThermometerRating?: InputMaybe<NumberProp>;
  priority?: InputMaybe<NumberProp>;
  problemTicketId?: InputMaybe<NumberProp>;
  projectID?: InputMaybe<NumberProp>;
  purchaseOrderNumber?: InputMaybe<StringProp>;
  queueID?: InputMaybe<NumberProp>;
  recipeInstanceId?: InputMaybe<NumberProp>;
  resolution?: InputMaybe<StringProp>;
  resolutionPlanDateTime?: InputMaybe<TimeQueryProp>;
  resolutionPlanDueDateTime?: InputMaybe<NumberProp>;
  resolvedDateTime?: InputMaybe<TimeQueryProp>;
  resolvedDueDateTime?: InputMaybe<TimeQueryProp>;
  resyncImportanceRank?: InputMaybe<BooleanProp>;
  rmaStatus?: InputMaybe<NumberProp>;
  rmaType?: InputMaybe<NumberProp>;
  rmmAlertID?: InputMaybe<StringProp>;
  search?: InputMaybe<Scalars['String']['input']>;
  serviceLevelAgreementHasBeenMet?: InputMaybe<NumberProp>;
  serviceLevelAgreementID?: InputMaybe<NumberProp>;
  serviceLevelAgreementPausedNextEventHours?: InputMaybe<NumberProp>;
  serviceThermometerTemperature?: InputMaybe<NumberProp>;
  source?: InputMaybe<NumberProp>;
  status?: InputMaybe<NumberProp>;
  subIssueType?: InputMaybe<NumberProp>;
  ticketCategory?: InputMaybe<NumberProp>;
  ticketNumber?: InputMaybe<StringProp>;
  ticketType?: InputMaybe<NumberProp>;
  title?: InputMaybe<StringProp>;
};

export type GetUsersArgs = {
  autotaskId?: InputMaybe<NumberProp>;
  id?: InputMaybe<StringProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  autoScheduleDate: Scalars['JSON']['output'];
  createAIChat: AiChat;
  createAIChatMessages: AiChatMessages;
  createActionLogAction: ActionLogAction;
  createAutotaskConfigurationItems: AutotaskConfigurationItems;
  createAutotaskContacts: AutotaskContacts;
  createAutotaskTicketStatuses: AutotaskTicketStatuses;
  createAutotaskTickets: AutotaskTickets;
  createAzureLicense: AzureLicense;
  createAzureTenants: AzureTenants;
  createAzureUsers: AzureUsers;
  createContractsAssignments: ContractsAssignments;
  createContractsContracts: ContractsContracts;
  createContractsInstances: ContractsInstances;
  createCoreCompanies: CoreCompanies;
  createCoreContact: CoreContact;
  createCoreGroup: CoreGroup;
  createCoreGroupsMembers: CoreGroupsMembers;
  createCoreTag: CoreTag;
  createCoreTagLink: CoreTagLink;
  createCoreUsers: CoreUsers;
  createDattormmDevices: DattormmDevices;
  createDattormmPatchingPolicies: DattormmPatchingPolicies;
  createDattormmSites: DattormmSites;
  createFeedbackInstance: FeedbackInstance;
  createFeedbackScore: FeedbackScore;
  createFormsForm: FormsForm;
  createFormsInstance: FormsInstance;
  createPhonesCallLegs: PhonesCallLegs;
  createPhonesCalls: PhonesCalls;
  createRecipeNode: RecipeNode;
  createRocketcyberAccounts: RocketcyberAccounts;
  createRocketcyberAgents: RocketcyberAgents;
  createRocketcyberIncidents: RocketcyberIncidents;
  createScheduleInstance: ScheduleInstance;
  createSchedulePlan: SchedulePlan;
  createTicketTimeline: TicketTimeline;
  createTodosTodo: TodosTodo;
  deleteAIChat: Scalars['Boolean']['output'];
  deleteAIChatMessages: Scalars['Boolean']['output'];
  deleteActionLogAction: Scalars['Boolean']['output'];
  deleteAutotaskConfigurationItems: Scalars['Boolean']['output'];
  deleteAutotaskContacts: Scalars['Boolean']['output'];
  deleteAutotaskTicketStatuses: Scalars['Boolean']['output'];
  deleteAutotaskTickets: Scalars['Boolean']['output'];
  deleteAzureLicense: Scalars['Boolean']['output'];
  deleteAzureTenants: Scalars['Boolean']['output'];
  deleteAzureUsers: Scalars['Boolean']['output'];
  deleteContractsAssignments: Scalars['Boolean']['output'];
  deleteContractsContracts: Scalars['Boolean']['output'];
  deleteContractsInstances: Scalars['Boolean']['output'];
  deleteCoreCompanies: Scalars['Boolean']['output'];
  deleteCoreContact: Scalars['Boolean']['output'];
  deleteCoreGroup: Scalars['Boolean']['output'];
  deleteCoreGroupsMembers: Scalars['Boolean']['output'];
  deleteCoreTag: Scalars['Boolean']['output'];
  deleteCoreTagLink: Scalars['Boolean']['output'];
  deleteCoreUsers: Scalars['Boolean']['output'];
  deleteDattormmDevices: Scalars['Boolean']['output'];
  deleteDattormmPatchingPolicies: Scalars['Boolean']['output'];
  deleteDattormmSites: Scalars['Boolean']['output'];
  deleteFeedbackInstance: Scalars['Boolean']['output'];
  deleteFeedbackScore: Scalars['Boolean']['output'];
  deleteFormsForm: Scalars['Boolean']['output'];
  deleteFormsInstance: Scalars['Boolean']['output'];
  deletePhonesCallLegs: Scalars['Boolean']['output'];
  deletePhonesCalls: Scalars['Boolean']['output'];
  deleteRecipeNode: Scalars['Boolean']['output'];
  deleteRocketcyberAccounts: Scalars['Boolean']['output'];
  deleteRocketcyberAgents: Scalars['Boolean']['output'];
  deleteRocketcyberIncidents: Scalars['Boolean']['output'];
  deleteScheduleInstance: Scalars['Boolean']['output'];
  deleteSchedulePlan: Scalars['Boolean']['output'];
  deleteTicketTimeline: Scalars['Boolean']['output'];
  deleteTodosTodo: Scalars['Boolean']['output'];
  mergeCoreCompanies: Scalars['Boolean']['output'];
  optimizeSchedule: Scalars['JSON']['output'];
  rescheduleScheduleDate: Scalars['JSON']['output'];
  syncBvoipPhoneCall: Scalars['Boolean']['output'];
  syncDattormmDeviceInfo: DattormmDevices;
  syncUsersCalendarsToSchedule: Scalars['JSON']['output'];
  testProcessTicketCreationNode: Scalars['Boolean']['output'];
  timeclockClockin: CoreTimeclock;
  timeclockClockout: CoreTimeclock;
  updateAIChat: AiChat;
  updateAIChatMessages: AiChatMessages;
  updateActionLogAction: ActionLogAction;
  updateAutotaskConfigurationItems: AutotaskConfigurationItems;
  updateAutotaskContacts: AutotaskContacts;
  updateAutotaskTicketStatuses: AutotaskTicketStatuses;
  updateAutotaskTickets: AutotaskTickets;
  updateAzureLicense: AzureLicense;
  updateAzureTenants: AzureTenants;
  updateAzureUsers: AzureUsers;
  updateContractsAssignments: ContractsAssignments;
  updateContractsContracts: ContractsContracts;
  updateContractsInstances: ContractsInstances;
  updateCoreCompanies: CoreCompanies;
  updateCoreContact: CoreContact;
  updateCoreGroup: CoreGroup;
  updateCoreGroupsMembers: CoreGroupsMembers;
  updateCoreTag: CoreTag;
  updateCoreTagLink: CoreTagLink;
  updateCoreUsers: CoreUsers;
  updateDattormmDevices: DattormmDevices;
  updateDattormmPatchingPolicies: DattormmPatchingPolicies;
  updateDattormmSites: DattormmSites;
  updateFeedbackInstance: FeedbackInstance;
  updateFeedbackScore: FeedbackScore;
  updateFormsForm: FormsForm;
  updateFormsInstance: FormsInstance;
  updatePhonesCallLegs: PhonesCallLegs;
  updatePhonesCalls: PhonesCalls;
  updateRecipeNode: RecipeNode;
  updateRocketcyberAccounts: RocketcyberAccounts;
  updateRocketcyberAgents: RocketcyberAgents;
  updateRocketcyberIncidents: RocketcyberIncidents;
  updateScheduleInstance: ScheduleInstance;
  updateSchedulePlan: SchedulePlan;
  updateTicketTimeline: TicketTimeline;
  updateTimeclock: CoreTimeclock;
  updateTodosTodo: TodosTodo;
  verifyAzureTenant: AzureTenants;
};


export type MutationAutoScheduleDateArgs = {
  date: Scalars['String']['input'];
};


export type MutationCreateAiChatArgs = {
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ticketId?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationCreateAiChatMessagesArgs = {
  chatId: Scalars['Float']['input'];
  coreUserId?: InputMaybe<Scalars['Float']['input']>;
  message: Scalars['String']['input'];
  role: Scalars['String']['input'];
};


export type MutationCreateActionLogActionArgs = {
  actionTime?: InputMaybe<Scalars['Date']['input']>;
  azureEmailId?: InputMaybe<Scalars['String']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  databaseAction?: InputMaybe<Scalars['String']['input']>;
  loggedFunction?: InputMaybe<Scalars['String']['input']>;
  module?: InputMaybe<Scalars['String']['input']>;
  moduleIdNumber?: InputMaybe<Scalars['Int']['input']>;
  moduleIdString?: InputMaybe<Scalars['String']['input']>;
  ticketId?: InputMaybe<Scalars['Int']['input']>;
  userActionId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateAutotaskConfigurationItemsArgs = {
  apiVendorID?: InputMaybe<Scalars['Int']['input']>;
  companyID?: InputMaybe<Scalars['Int']['input']>;
  companyLocationID?: InputMaybe<Scalars['Int']['input']>;
  configurationItemCategoryID?: InputMaybe<Scalars['Int']['input']>;
  configurationItemType?: InputMaybe<Scalars['Int']['input']>;
  contactID?: InputMaybe<Scalars['Int']['input']>;
  contractID?: InputMaybe<Scalars['Int']['input']>;
  contractServiceBundleID?: InputMaybe<Scalars['Int']['input']>;
  contractServiceID?: InputMaybe<Scalars['Int']['input']>;
  createDate?: InputMaybe<Scalars['Date']['input']>;
  createdByPersonID?: InputMaybe<Scalars['Int']['input']>;
  dailyCost?: InputMaybe<Scalars['Int']['input']>;
  dattoAvailableKilobytes?: InputMaybe<Scalars['Int']['input']>;
  dattoDeviceMemoryMegabytes?: InputMaybe<Scalars['Int']['input']>;
  dattoDrivesErrors?: InputMaybe<Scalars['Boolean']['input']>;
  dattoHostname?: InputMaybe<Scalars['String']['input']>;
  dattoInternalIP?: InputMaybe<Scalars['String']['input']>;
  dattoKernelVersionID?: InputMaybe<Scalars['Int']['input']>;
  dattoLastCheckInDateTime?: InputMaybe<Scalars['Date']['input']>;
  dattoNICSpeedKilobitsPerSecond?: InputMaybe<Scalars['Int']['input']>;
  dattoNumberOfAgents?: InputMaybe<Scalars['Int']['input']>;
  dattoNumberOfDrives?: InputMaybe<Scalars['Int']['input']>;
  dattoNumberOfVolumes?: InputMaybe<Scalars['Int']['input']>;
  dattoOSVersionID?: InputMaybe<Scalars['Int']['input']>;
  dattoOffsiteUsedBytes?: InputMaybe<Scalars['Int']['input']>;
  dattoPercentageUsed?: InputMaybe<Scalars['Int']['input']>;
  dattoProtectedKilobytes?: InputMaybe<Scalars['Int']['input']>;
  dattoRemoteIP?: InputMaybe<Scalars['String']['input']>;
  dattoSerialNumber?: InputMaybe<Scalars['String']['input']>;
  dattoUptimeSeconds?: InputMaybe<Scalars['Int']['input']>;
  dattoUsedKilobytes?: InputMaybe<Scalars['Int']['input']>;
  dattoZFSVersionID?: InputMaybe<Scalars['Int']['input']>;
  deviceNetworkingID?: InputMaybe<Scalars['Int']['input']>;
  domain?: InputMaybe<Scalars['Int']['input']>;
  domainExpirationDateTime?: InputMaybe<Scalars['Int']['input']>;
  domainLastUpdatedDateTime?: InputMaybe<Scalars['Int']['input']>;
  domainRegistrarID?: InputMaybe<Scalars['Int']['input']>;
  domainRegistrationDateTime?: InputMaybe<Scalars['Int']['input']>;
  hourlyCost?: InputMaybe<Scalars['Int']['input']>;
  impersonatorCreatorResourceID?: InputMaybe<Scalars['Int']['input']>;
  installDate?: InputMaybe<Scalars['Date']['input']>;
  installedByContactID?: InputMaybe<Scalars['Int']['input']>;
  installedByID?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastActivityPersonID?: InputMaybe<Scalars['Int']['input']>;
  lastActivityPersonType?: InputMaybe<Scalars['Int']['input']>;
  lastModifiedTime?: InputMaybe<Scalars['Date']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  monthlyCost?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  numberOfUsers?: InputMaybe<Scalars['Int']['input']>;
  parentConfigurationItemID?: InputMaybe<Scalars['Int']['input']>;
  perUseCost?: InputMaybe<Scalars['Int']['input']>;
  productID?: InputMaybe<Scalars['Int']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  referenceTitle?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditAntivirusStatusID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditArchitectureID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditBackupStatusID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditDescription?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditDeviceTypeID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditDisplayAdaptorID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditDomainID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditExternalIPAddress?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditHostname?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditIPAddress?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditLastUser?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditMacAddress?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditManufacturerID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditMemoryBytes?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditMissingPatchCount?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditMobileNetworkOperatorID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditMobileNumber?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditModelID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditMotherboardID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditOperatingSystem?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditPatchStatusID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditProcessorID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditSNMPContact?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditSNMPLocation?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditSNMPName?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditServicePackID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditSoftwareStatusID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditStorageBytes?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceUID?: InputMaybe<Scalars['String']['input']>;
  rmmOpenAlertCount?: InputMaybe<Scalars['Int']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  serviceBundleID?: InputMaybe<Scalars['Int']['input']>;
  serviceID?: InputMaybe<Scalars['Int']['input']>;
  serviceLevelAgreementID?: InputMaybe<Scalars['Int']['input']>;
  setupFee?: InputMaybe<Scalars['Int']['input']>;
  sourceChargeID?: InputMaybe<Scalars['Int']['input']>;
  sourceChargeType?: InputMaybe<Scalars['Int']['input']>;
  sslCommonName?: InputMaybe<Scalars['Int']['input']>;
  sslIssuedBy?: InputMaybe<Scalars['Int']['input']>;
  sslLocation?: InputMaybe<Scalars['Int']['input']>;
  sslOrganization?: InputMaybe<Scalars['Int']['input']>;
  sslOrganizationUnit?: InputMaybe<Scalars['Int']['input']>;
  sslSerialNumber?: InputMaybe<Scalars['Int']['input']>;
  sslSignatureAlgorithm?: InputMaybe<Scalars['Int']['input']>;
  sslSource?: InputMaybe<Scalars['Int']['input']>;
  sslValidFromDateTime?: InputMaybe<Scalars['Int']['input']>;
  sslValidUntilDateTime?: InputMaybe<Scalars['Int']['input']>;
  vendorID?: InputMaybe<Scalars['Int']['input']>;
  warrantyExpirationDate?: InputMaybe<Scalars['Date']['input']>;
};


export type MutationCreateAutotaskContactsArgs = {
  additionalAddressInformation?: InputMaybe<Scalars['String']['input']>;
  addressLine?: InputMaybe<Scalars['String']['input']>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  alternatePhone?: InputMaybe<Scalars['String']['input']>;
  apiVendorID?: InputMaybe<Scalars['Int']['input']>;
  bulkEmailOptOutTime?: InputMaybe<Scalars['Date']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyID?: InputMaybe<Scalars['Int']['input']>;
  companyLocationID?: InputMaybe<Scalars['Int']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Int']['input']>;
  coreContactId?: InputMaybe<Scalars['Int']['input']>;
  countryID?: InputMaybe<Scalars['Int']['input']>;
  createDate?: InputMaybe<Scalars['Date']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailAddress2?: InputMaybe<Scalars['String']['input']>;
  emailAddress3?: InputMaybe<Scalars['String']['input']>;
  extension?: InputMaybe<Scalars['String']['input']>;
  externalID?: InputMaybe<Scalars['String']['input']>;
  facebookUrl?: InputMaybe<Scalars['String']['input']>;
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  impersonatorCreatorResourceID?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Int']['input']>;
  isOptedOutFromBulkEmail?: InputMaybe<Scalars['Boolean']['input']>;
  lastActivityDate?: InputMaybe<Scalars['Date']['input']>;
  lastModifiedDate?: InputMaybe<Scalars['Date']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  linkedInUrl?: InputMaybe<Scalars['String']['input']>;
  middleInitial?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  namePrefix?: InputMaybe<Scalars['Int']['input']>;
  nameSuffix?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  primaryContact?: InputMaybe<Scalars['Boolean']['input']>;
  receivesEmailNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  roomNumber?: InputMaybe<Scalars['String']['input']>;
  solicitationOptOut?: InputMaybe<Scalars['Boolean']['input']>;
  solicitationOptOutTime?: InputMaybe<Scalars['Date']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  surveyOptOut?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateAutotaskTicketStatusesArgs = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isSystem?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentValue?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCreateAutotaskTicketsArgs = {
  apiVendorID?: InputMaybe<Scalars['Int']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  assignedResourceID?: InputMaybe<Scalars['Int']['input']>;
  assignedResourceRoleID?: InputMaybe<Scalars['Int']['input']>;
  billingCodeID?: InputMaybe<Scalars['Int']['input']>;
  changeApprovalBoard?: InputMaybe<Scalars['Int']['input']>;
  changeApprovalStatus?: InputMaybe<Scalars['Int']['input']>;
  changeApprovalType?: InputMaybe<Scalars['Int']['input']>;
  changeInfoField1?: InputMaybe<Scalars['String']['input']>;
  changeInfoField2?: InputMaybe<Scalars['String']['input']>;
  changeInfoField3?: InputMaybe<Scalars['String']['input']>;
  changeInfoField4?: InputMaybe<Scalars['String']['input']>;
  changeInfoField5?: InputMaybe<Scalars['String']['input']>;
  companyID?: InputMaybe<Scalars['Int']['input']>;
  companyLocationID?: InputMaybe<Scalars['Int']['input']>;
  completedBy?: InputMaybe<Scalars['String']['input']>;
  completedByResourceID?: InputMaybe<Scalars['Int']['input']>;
  completedDate?: InputMaybe<Scalars['Date']['input']>;
  configurationItemID?: InputMaybe<Scalars['Int']['input']>;
  contactID?: InputMaybe<Scalars['Int']['input']>;
  contactlessStart?: InputMaybe<Scalars['Boolean']['input']>;
  contractID?: InputMaybe<Scalars['Int']['input']>;
  contractServiceBundleID?: InputMaybe<Scalars['Int']['input']>;
  contractServiceID?: InputMaybe<Scalars['Int']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Int']['input']>;
  createDate?: InputMaybe<Scalars['Date']['input']>;
  createdByContactID?: InputMaybe<Scalars['Int']['input']>;
  creatorResourceID?: InputMaybe<Scalars['Int']['input']>;
  creatorType?: InputMaybe<Scalars['Int']['input']>;
  currentServiceThermometerRating?: InputMaybe<Scalars['Int']['input']>;
  dueDateTime?: InputMaybe<Scalars['Date']['input']>;
  enlightendTicketClass?: InputMaybe<Scalars['String']['input']>;
  estimatedHours?: InputMaybe<Scalars['Int']['input']>;
  externalID?: InputMaybe<Scalars['String']['input']>;
  firstResponseAssignedResourceID?: InputMaybe<Scalars['Int']['input']>;
  firstResponseDateTime?: InputMaybe<Scalars['Date']['input']>;
  firstResponseDueDateTime?: InputMaybe<Scalars['Date']['input']>;
  firstResponseInitiatingResourceID?: InputMaybe<Scalars['Int']['input']>;
  fromAutotask?: InputMaybe<Scalars['Boolean']['input']>;
  hoursToBeScheduled?: InputMaybe<Scalars['Int']['input']>;
  impersonatorCreatorResourceID?: InputMaybe<Scalars['Int']['input']>;
  issueType?: InputMaybe<Scalars['Int']['input']>;
  lastActivityDate?: InputMaybe<Scalars['Date']['input']>;
  lastActivityPersonType?: InputMaybe<Scalars['Int']['input']>;
  lastActivityResourceID?: InputMaybe<Scalars['Int']['input']>;
  lastCustomerNotificationDateTime?: InputMaybe<Scalars['Date']['input']>;
  lastCustomerVisibleActivityDateTime?: InputMaybe<Scalars['Date']['input']>;
  lastImportanceSync?: InputMaybe<Scalars['Date']['input']>;
  lastTrackedModificationDateTime?: InputMaybe<Scalars['Date']['input']>;
  maxImportanceRank?: InputMaybe<Scalars['Int']['input']>;
  mergeToTicketId?: InputMaybe<Scalars['Int']['input']>;
  monitorID?: InputMaybe<Scalars['Int']['input']>;
  monitorTypeID?: InputMaybe<Scalars['Int']['input']>;
  nextActionDate?: InputMaybe<Scalars['Date']['input']>;
  nextActionTime?: InputMaybe<Scalars['Int']['input']>;
  nextActionType?: InputMaybe<Scalars['String']['input']>;
  opportunityID?: InputMaybe<Scalars['Int']['input']>;
  organizationalLevelAssociationID?: InputMaybe<Scalars['Int']['input']>;
  parentTicketId?: InputMaybe<Scalars['Int']['input']>;
  previousServiceThermometerRating?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  problemTicketId?: InputMaybe<Scalars['Int']['input']>;
  projectID?: InputMaybe<Scalars['Int']['input']>;
  purchaseOrderNumber?: InputMaybe<Scalars['String']['input']>;
  queueID?: InputMaybe<Scalars['Int']['input']>;
  recipeInstanceId?: InputMaybe<Scalars['Int']['input']>;
  resolution?: InputMaybe<Scalars['String']['input']>;
  resolutionPlanDateTime?: InputMaybe<Scalars['Date']['input']>;
  resolutionPlanDueDateTime?: InputMaybe<Scalars['Int']['input']>;
  resolvedDateTime?: InputMaybe<Scalars['Date']['input']>;
  resolvedDueDateTime?: InputMaybe<Scalars['Date']['input']>;
  resyncImportanceRank?: InputMaybe<Scalars['Boolean']['input']>;
  rmaStatus?: InputMaybe<Scalars['Int']['input']>;
  rmaType?: InputMaybe<Scalars['Int']['input']>;
  rmmAlertID?: InputMaybe<Scalars['String']['input']>;
  serviceLevelAgreementHasBeenMet?: InputMaybe<Scalars['Int']['input']>;
  serviceLevelAgreementID?: InputMaybe<Scalars['Int']['input']>;
  serviceLevelAgreementPausedNextEventHours?: InputMaybe<Scalars['Int']['input']>;
  serviceThermometerTemperature?: InputMaybe<Scalars['Int']['input']>;
  source?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  subIssueType?: InputMaybe<Scalars['Int']['input']>;
  ticketCategory?: InputMaybe<Scalars['Int']['input']>;
  ticketNumber?: InputMaybe<Scalars['String']['input']>;
  ticketType?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateAzureLicenseArgs = {
  azureUserId?: InputMaybe<Scalars['String']['input']>;
  skuId?: InputMaybe<Scalars['String']['input']>;
  skuPartNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateAzureTenantsArgs = {
  coreCompanyId: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateAzureUsersArgs = {
  accountEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  coreContactId?: InputMaybe<Scalars['Int']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  isMfaRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastMfaCheckDate?: InputMaybe<Scalars['Date']['input']>;
  lastSynced?: InputMaybe<Scalars['Date']['input']>;
  mail?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  officeLocation?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  surname?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  userPrincipalName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateContractsAssignmentsArgs = {
  coreContactId?: InputMaybe<Scalars['Float']['input']>;
  coreLocationId?: InputMaybe<Scalars['Float']['input']>;
  deviceId?: InputMaybe<Scalars['Float']['input']>;
  instanceId?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationCreateContractsContractsArgs = {
  additionalPrice?: InputMaybe<Scalars['Float']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  iconColor?: InputMaybe<Scalars['String']['input']>;
  internalDescription?: InputMaybe<Scalars['String']['input']>;
  internalUnitCost?: InputMaybe<Scalars['Float']['input']>;
  laborIncluded?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  publicDescription?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitsMeasured?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateContractsInstancesArgs = {
  additionalPrice?: InputMaybe<Scalars['Float']['input']>;
  approvedUnits?: InputMaybe<Scalars['Float']['input']>;
  autoAproveUnits?: InputMaybe<Scalars['Boolean']['input']>;
  contractId?: InputMaybe<Scalars['Float']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Float']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  usedUnits?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationCreateCoreCompaniesArgs = {
  active?: Scalars['Boolean']['input'];
  billingConfigured?: Scalars['Boolean']['input'];
  defaultHourlyRate?: Scalars['Float']['input'];
  defaultTicketLevel?: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateCoreContactArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  alternatePhone?: InputMaybe<Scalars['String']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Int']['input']>;
  coreLocationId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneExtension?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateCoreGroupArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};


export type MutationCreateCoreGroupsMembersArgs = {
  coreUserId: Scalars['String']['input'];
  groupId: Scalars['Float']['input'];
};


export type MutationCreateCoreTagArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateCoreTagLinkArgs = {
  link: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateCoreUsersArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  autotaskId?: InputMaybe<Scalars['Int']['input']>;
  autotaskLocationId?: InputMaybe<Scalars['Int']['input']>;
  bvoipExtention?: InputMaybe<Scalars['Int']['input']>;
  canContactCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isDispatchable?: InputMaybe<Scalars['Boolean']['input']>;
  isOnCall?: InputMaybe<Scalars['Boolean']['input']>;
  isRoleTier1?: InputMaybe<Scalars['Boolean']['input']>;
  isRoleTier2?: InputMaybe<Scalars['Boolean']['input']>;
  isRoleTier2FirstUp?: InputMaybe<Scalars['Boolean']['input']>;
  isRoleTriage?: InputMaybe<Scalars['Boolean']['input']>;
  isScheduler?: InputMaybe<Scalars['Boolean']['input']>;
  isSherpaChampion?: InputMaybe<Scalars['Boolean']['input']>;
  isTech?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  techTier?: InputMaybe<Scalars['Int']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  timwoodsId?: InputMaybe<Scalars['String']['input']>;
  useNewRank?: InputMaybe<Scalars['Boolean']['input']>;
  workDayEnd?: InputMaybe<Scalars['String']['input']>;
  workDayStart?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateDattormmDevicesArgs = {
  a64Bit?: InputMaybe<Scalars['Boolean']['input']>;
  antivirusProduct?: InputMaybe<Scalars['String']['input']>;
  antivirusStatus?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  cagVersion?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  creationDate?: InputMaybe<Scalars['Date']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  deviceClass?: InputMaybe<Scalars['String']['input']>;
  displayVersion?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  extIpAddress?: InputMaybe<Scalars['String']['input']>;
  hostname?: InputMaybe<Scalars['String']['input']>;
  intIpAddress?: InputMaybe<Scalars['String']['input']>;
  lastAuditDate?: InputMaybe<Scalars['Date']['input']>;
  lastLoggedInUser?: InputMaybe<Scalars['String']['input']>;
  lastReboot?: InputMaybe<Scalars['Date']['input']>;
  lastSeen?: InputMaybe<Scalars['Date']['input']>;
  online?: InputMaybe<Scalars['Boolean']['input']>;
  operatingSystem?: InputMaybe<Scalars['String']['input']>;
  patchStatus?: InputMaybe<Scalars['String']['input']>;
  patchesApprovedPending?: InputMaybe<Scalars['Float']['input']>;
  patchesInstalled?: InputMaybe<Scalars['Float']['input']>;
  patchesNotApproved?: InputMaybe<Scalars['Float']['input']>;
  portalUrl?: InputMaybe<Scalars['String']['input']>;
  rebootRequired?: InputMaybe<Scalars['Boolean']['input']>;
  siteId?: InputMaybe<Scalars['Float']['input']>;
  siteName?: InputMaybe<Scalars['String']['input']>;
  siteUid?: InputMaybe<Scalars['String']['input']>;
  snmpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  softwareStatus?: InputMaybe<Scalars['String']['input']>;
  suspended?: InputMaybe<Scalars['Boolean']['input']>;
  syncId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  udf1?: InputMaybe<Scalars['String']['input']>;
  udf2?: InputMaybe<Scalars['String']['input']>;
  udf3?: InputMaybe<Scalars['String']['input']>;
  udf4?: InputMaybe<Scalars['String']['input']>;
  udf5?: InputMaybe<Scalars['String']['input']>;
  udf6?: InputMaybe<Scalars['String']['input']>;
  udf7?: InputMaybe<Scalars['String']['input']>;
  udf8?: InputMaybe<Scalars['String']['input']>;
  udf9?: InputMaybe<Scalars['String']['input']>;
  udf10?: InputMaybe<Scalars['String']['input']>;
  udf11?: InputMaybe<Scalars['String']['input']>;
  udf12?: InputMaybe<Scalars['String']['input']>;
  udf13?: InputMaybe<Scalars['String']['input']>;
  udf14?: InputMaybe<Scalars['String']['input']>;
  udf15?: InputMaybe<Scalars['String']['input']>;
  udf16?: InputMaybe<Scalars['String']['input']>;
  udf17?: InputMaybe<Scalars['String']['input']>;
  udf18?: InputMaybe<Scalars['String']['input']>;
  udf19?: InputMaybe<Scalars['String']['input']>;
  udf20?: InputMaybe<Scalars['String']['input']>;
  udf21?: InputMaybe<Scalars['String']['input']>;
  udf22?: InputMaybe<Scalars['String']['input']>;
  udf23?: InputMaybe<Scalars['String']['input']>;
  udf24?: InputMaybe<Scalars['String']['input']>;
  udf25?: InputMaybe<Scalars['String']['input']>;
  udf26?: InputMaybe<Scalars['String']['input']>;
  udf27?: InputMaybe<Scalars['String']['input']>;
  udf28?: InputMaybe<Scalars['String']['input']>;
  udf29?: InputMaybe<Scalars['String']['input']>;
  udf30?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  warrantyDate?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateDattormmPatchingPoliciesArgs = {
  deviceId?: InputMaybe<Scalars['Float']['input']>;
  lastRun?: InputMaybe<Scalars['Date']['input']>;
  lastSync?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  schedule?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateDattormmSitesArgs = {
  accountUid?: InputMaybe<Scalars['String']['input']>;
  autotaskCompanyId?: InputMaybe<Scalars['String']['input']>;
  autotaskCompanyName?: InputMaybe<Scalars['String']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  numberOfDevices?: InputMaybe<Scalars['Float']['input']>;
  numberOfOfflineDevices?: InputMaybe<Scalars['Float']['input']>;
  numberOfOnlineDevices?: InputMaybe<Scalars['Float']['input']>;
  onDemand?: InputMaybe<Scalars['Boolean']['input']>;
  portalUrl?: InputMaybe<Scalars['String']['input']>;
  proxySettings?: InputMaybe<Scalars['Float']['input']>;
  splashtopAutoInstall?: InputMaybe<Scalars['Boolean']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateFeedbackInstanceArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateFeedbackScoreArgs = {
  customerResp: Scalars['String']['input'];
  customerRespTitle: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateFormsFormArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateFormsInstanceArgs = {
  autotaskTicketId?: InputMaybe<Scalars['Int']['input']>;
  complete?: InputMaybe<Scalars['Boolean']['input']>;
  formId?: InputMaybe<Scalars['Int']['input']>;
  recipeInstanceId?: InputMaybe<Scalars['Int']['input']>;
  todoId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCreatePhonesCallLegsArgs = {
  callProvider?: InputMaybe<Scalars['String']['input']>;
  callProviderId?: InputMaybe<Scalars['Int']['input']>;
  coreContactId?: InputMaybe<Scalars['Int']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  extention?: InputMaybe<Scalars['String']['input']>;
  phonesCallId?: InputMaybe<Scalars['Int']['input']>;
  recordingLocalPath?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['Date']['input']>;
  transcription?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreatePhonesCallsArgs = {
  atTicketId?: InputMaybe<Scalars['Int']['input']>;
  callProvider?: InputMaybe<Scalars['String']['input']>;
  callProviderId?: InputMaybe<Scalars['Int']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Int']['input']>;
  coreContactId?: InputMaybe<Scalars['Int']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  extention?: InputMaybe<Scalars['String']['input']>;
  fromNumber?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['Date']['input']>;
  toNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateRecipeNodeArgs = {
  data: Scalars['String']['input'];
  id: Scalars['String']['input'];
  positionX: Scalars['Float']['input'];
  positionY: Scalars['Float']['input'];
  recipeId: Scalars['Float']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};


export type MutationCreateRocketcyberAccountsArgs = {
  coreCompanyId?: InputMaybe<Scalars['Float']['input']>;
  emails: Scalars['String']['input'];
  hierarchy: Scalars['String']['input'];
  id: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  path: Scalars['String']['input'];
  status: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type MutationCreateRocketcyberAgentsArgs = {
  accountPath: Scalars['String']['input'];
  agentVersion: Scalars['String']['input'];
  architecture: Scalars['String']['input'];
  build: Scalars['String']['input'];
  connectivity: Scalars['String']['input'];
  customerId: Scalars['Float']['input'];
  edition: Scalars['String']['input'];
  family: Scalars['String']['input'];
  hostname: Scalars['String']['input'];
  ipv4Address: Scalars['String']['input'];
  macAddress: Scalars['String']['input'];
  platform: Scalars['String']['input'];
  release: Scalars['String']['input'];
  version: Scalars['String']['input'];
};


export type MutationCreateRocketcyberIncidentsArgs = {
  accountId: Scalars['Float']['input'];
  autotaskTicketId?: InputMaybe<Scalars['Float']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  description: Scalars['String']['input'];
  eventCount: Scalars['Float']['input'];
  id: Scalars['Float']['input'];
  remediation: Scalars['String']['input'];
  resolvedAt?: InputMaybe<Scalars['Date']['input']>;
  status: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationCreateScheduleInstanceArgs = {
  atTicketId?: InputMaybe<Scalars['Int']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  azureCalendarId?: InputMaybe<Scalars['String']['input']>;
  conflictDetected?: InputMaybe<Scalars['Boolean']['input']>;
  coreGroupId?: InputMaybe<Scalars['Int']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDateTime?: InputMaybe<Scalars['Date']['input']>;
  lockedDateTime?: InputMaybe<Scalars['Boolean']['input']>;
  lockedUser?: InputMaybe<Scalars['Boolean']['input']>;
  overidePlan?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleInstanceDay?: InputMaybe<Scalars['String']['input']>;
  schedulePlanId?: InputMaybe<Scalars['Int']['input']>;
  schedulerLog?: InputMaybe<Scalars['String']['input']>;
  startDateTime?: InputMaybe<Scalars['Date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateSchedulePlanArgs = {
  atTicketId?: InputMaybe<Scalars['Int']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  blockSizeInMinutes?: InputMaybe<Scalars['Int']['input']>;
  coreGroupId?: InputMaybe<Scalars['Int']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredUserCount?: InputMaybe<Scalars['Int']['input']>;
  end_hour?: InputMaybe<Scalars['Int']['input']>;
  end_minute?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  optimalBlockSizeInMinutes?: InputMaybe<Scalars['Int']['input']>;
  splitableIntoBlocks?: InputMaybe<Scalars['Boolean']['input']>;
  startTimeDay_daysOfMonth?: InputMaybe<Array<Scalars['Int']['input']>>;
  startTimeDay_daysOfWeek?: InputMaybe<Array<Scalars['String']['input']>>;
  startTimeDay_monthsOfTheYear?: InputMaybe<Array<Scalars['String']['input']>>;
  startTimeDay_year?: InputMaybe<Scalars['Int']['input']>;
  start_hour?: InputMaybe<Scalars['Int']['input']>;
  start_minute?: InputMaybe<Scalars['Int']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateTicketTimelineArgs = {
  atTicketId?: InputMaybe<Scalars['Int']['input']>;
  azureEmailId?: InputMaybe<Scalars['String']['input']>;
  coreTimeclockId?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  formInstanceId?: InputMaybe<Scalars['Int']['input']>;
  phonesCallId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateTodosTodoArgs = {
  autotaskTicketId?: InputMaybe<Scalars['Int']['input']>;
  blockedByTodoId?: InputMaybe<Scalars['Int']['input']>;
  complete?: InputMaybe<Scalars['Boolean']['input']>;
  completeAt?: InputMaybe<Scalars['Date']['input']>;
  completeByUser?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  createdByModuleId?: InputMaybe<Scalars['String']['input']>;
  createdByModuleName?: InputMaybe<Scalars['String']['input']>;
  createdByUser?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  estimatedMinutes?: InputMaybe<Scalars['Int']['input']>;
  formInstanceId?: InputMaybe<Scalars['Int']['input']>;
  isOnsite?: InputMaybe<Scalars['Boolean']['input']>;
  isOutboundCall?: InputMaybe<Scalars['Boolean']['input']>;
  mergeFromTicketId?: InputMaybe<Scalars['Int']['input']>;
  nextActionTime?: InputMaybe<Scalars['Int']['input']>;
  nextActionType?: InputMaybe<Scalars['String']['input']>;
  noteType?: InputMaybe<Scalars['String']['input']>;
  outboundCallNumber?: InputMaybe<Scalars['Int']['input']>;
  recipeInstanceId?: InputMaybe<Scalars['Int']['input']>;
  response?: InputMaybe<Scalars['String']['input']>;
  ticketBlocking?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  tripStopId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
};


export type MutationDeleteAiChatArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteAiChatMessagesArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteActionLogActionArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteAutotaskConfigurationItemsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteAutotaskContactsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteAutotaskTicketStatusesArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteAutotaskTicketsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteAzureLicenseArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteAzureTenantsArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteAzureUsersArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteContractsAssignmentsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteContractsContractsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteContractsInstancesArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCoreCompaniesArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCoreContactArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCoreGroupArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCoreGroupsMembersArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCoreTagArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCoreTagLinkArgs = {
  name: Scalars['String']['input'];
};


export type MutationDeleteCoreUsersArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteDattormmDevicesArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteDattormmPatchingPoliciesArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteDattormmSitesArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteFeedbackInstanceArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteFeedbackScoreArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteFormsFormArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteFormsInstanceArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePhonesCallLegsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePhonesCallsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteRecipeNodeArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteRocketcyberAccountsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteRocketcyberAgentsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteRocketcyberIncidentsArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteScheduleInstanceArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteSchedulePlanArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteTicketTimelineArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteTodosTodoArgs = {
  id: Scalars['Int']['input'];
};


export type MutationMergeCoreCompaniesArgs = {
  fromCompanyId: Scalars['Float']['input'];
  toCompanyId: Scalars['Float']['input'];
};


export type MutationOptimizeScheduleArgs = {
  date: Scalars['String']['input'];
};


export type MutationRescheduleScheduleDateArgs = {
  date: Scalars['String']['input'];
};


export type MutationSyncBvoipPhoneCallArgs = {
  bvoipCallId?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSyncDattormmDeviceInfoArgs = {
  deviceId: Scalars['Float']['input'];
};


export type MutationSyncUsersCalendarsToScheduleArgs = {
  date: Scalars['String']['input'];
};


export type MutationTimeclockClockinArgs = {
  isPayroll?: InputMaybe<Scalars['Boolean']['input']>;
  ticketId?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationTimeclockClockoutArgs = {
  id: Scalars['Float']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  ticketUpdateData?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationUpdateAiChatArgs = {
  id: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  ticketId?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationUpdateAiChatMessagesArgs = {
  coreUserId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Float']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateActionLogActionArgs = {
  actionTime?: InputMaybe<Scalars['Date']['input']>;
  azureEmailId?: InputMaybe<Scalars['String']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  databaseAction?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  loggedFunction?: InputMaybe<Scalars['String']['input']>;
  module?: InputMaybe<Scalars['String']['input']>;
  moduleIdNumber?: InputMaybe<Scalars['Int']['input']>;
  moduleIdString?: InputMaybe<Scalars['String']['input']>;
  ticketId?: InputMaybe<Scalars['Int']['input']>;
  userActionId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateAutotaskConfigurationItemsArgs = {
  apiVendorID?: InputMaybe<Scalars['Int']['input']>;
  companyID?: InputMaybe<Scalars['Int']['input']>;
  companyLocationID?: InputMaybe<Scalars['Int']['input']>;
  configurationItemCategoryID?: InputMaybe<Scalars['Int']['input']>;
  configurationItemType?: InputMaybe<Scalars['Int']['input']>;
  contactID?: InputMaybe<Scalars['Int']['input']>;
  contractID?: InputMaybe<Scalars['Int']['input']>;
  contractServiceBundleID?: InputMaybe<Scalars['Int']['input']>;
  contractServiceID?: InputMaybe<Scalars['Int']['input']>;
  createDate?: InputMaybe<Scalars['Date']['input']>;
  createdByPersonID?: InputMaybe<Scalars['Int']['input']>;
  dailyCost?: InputMaybe<Scalars['Int']['input']>;
  dattoAvailableKilobytes?: InputMaybe<Scalars['Int']['input']>;
  dattoDeviceMemoryMegabytes?: InputMaybe<Scalars['Int']['input']>;
  dattoDrivesErrors?: InputMaybe<Scalars['Boolean']['input']>;
  dattoHostname?: InputMaybe<Scalars['String']['input']>;
  dattoInternalIP?: InputMaybe<Scalars['String']['input']>;
  dattoKernelVersionID?: InputMaybe<Scalars['Int']['input']>;
  dattoLastCheckInDateTime?: InputMaybe<Scalars['Date']['input']>;
  dattoNICSpeedKilobitsPerSecond?: InputMaybe<Scalars['Int']['input']>;
  dattoNumberOfAgents?: InputMaybe<Scalars['Int']['input']>;
  dattoNumberOfDrives?: InputMaybe<Scalars['Int']['input']>;
  dattoNumberOfVolumes?: InputMaybe<Scalars['Int']['input']>;
  dattoOSVersionID?: InputMaybe<Scalars['Int']['input']>;
  dattoOffsiteUsedBytes?: InputMaybe<Scalars['Int']['input']>;
  dattoPercentageUsed?: InputMaybe<Scalars['Int']['input']>;
  dattoProtectedKilobytes?: InputMaybe<Scalars['Int']['input']>;
  dattoRemoteIP?: InputMaybe<Scalars['String']['input']>;
  dattoSerialNumber?: InputMaybe<Scalars['String']['input']>;
  dattoUptimeSeconds?: InputMaybe<Scalars['Int']['input']>;
  dattoUsedKilobytes?: InputMaybe<Scalars['Int']['input']>;
  dattoZFSVersionID?: InputMaybe<Scalars['Int']['input']>;
  deviceNetworkingID?: InputMaybe<Scalars['Int']['input']>;
  domain?: InputMaybe<Scalars['Int']['input']>;
  domainExpirationDateTime?: InputMaybe<Scalars['Int']['input']>;
  domainLastUpdatedDateTime?: InputMaybe<Scalars['Int']['input']>;
  domainRegistrarID?: InputMaybe<Scalars['Int']['input']>;
  domainRegistrationDateTime?: InputMaybe<Scalars['Int']['input']>;
  hourlyCost?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  impersonatorCreatorResourceID?: InputMaybe<Scalars['Int']['input']>;
  installDate?: InputMaybe<Scalars['Date']['input']>;
  installedByContactID?: InputMaybe<Scalars['Int']['input']>;
  installedByID?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastActivityPersonID?: InputMaybe<Scalars['Int']['input']>;
  lastActivityPersonType?: InputMaybe<Scalars['Int']['input']>;
  lastModifiedTime?: InputMaybe<Scalars['Date']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  monthlyCost?: InputMaybe<Scalars['Int']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  numberOfUsers?: InputMaybe<Scalars['Int']['input']>;
  parentConfigurationItemID?: InputMaybe<Scalars['Int']['input']>;
  perUseCost?: InputMaybe<Scalars['Int']['input']>;
  productID?: InputMaybe<Scalars['Int']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  referenceTitle?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditAntivirusStatusID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditArchitectureID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditBackupStatusID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditDescription?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditDeviceTypeID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditDisplayAdaptorID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditDomainID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditExternalIPAddress?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditHostname?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditIPAddress?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditLastUser?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditMacAddress?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditManufacturerID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditMemoryBytes?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditMissingPatchCount?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditMobileNetworkOperatorID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditMobileNumber?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditModelID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditMotherboardID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditOperatingSystem?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditPatchStatusID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditProcessorID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditSNMPContact?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditSNMPLocation?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditSNMPName?: InputMaybe<Scalars['String']['input']>;
  rmmDeviceAuditServicePackID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditSoftwareStatusID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceAuditStorageBytes?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceID?: InputMaybe<Scalars['Int']['input']>;
  rmmDeviceUID?: InputMaybe<Scalars['String']['input']>;
  rmmOpenAlertCount?: InputMaybe<Scalars['Int']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  serviceBundleID?: InputMaybe<Scalars['Int']['input']>;
  serviceID?: InputMaybe<Scalars['Int']['input']>;
  serviceLevelAgreementID?: InputMaybe<Scalars['Int']['input']>;
  setupFee?: InputMaybe<Scalars['Int']['input']>;
  sourceChargeID?: InputMaybe<Scalars['Int']['input']>;
  sourceChargeType?: InputMaybe<Scalars['Int']['input']>;
  sslCommonName?: InputMaybe<Scalars['Int']['input']>;
  sslIssuedBy?: InputMaybe<Scalars['Int']['input']>;
  sslLocation?: InputMaybe<Scalars['Int']['input']>;
  sslOrganization?: InputMaybe<Scalars['Int']['input']>;
  sslOrganizationUnit?: InputMaybe<Scalars['Int']['input']>;
  sslSerialNumber?: InputMaybe<Scalars['Int']['input']>;
  sslSignatureAlgorithm?: InputMaybe<Scalars['Int']['input']>;
  sslSource?: InputMaybe<Scalars['Int']['input']>;
  sslValidFromDateTime?: InputMaybe<Scalars['Int']['input']>;
  sslValidUntilDateTime?: InputMaybe<Scalars['Int']['input']>;
  vendorID?: InputMaybe<Scalars['Int']['input']>;
  warrantyExpirationDate?: InputMaybe<Scalars['Date']['input']>;
};


export type MutationUpdateAutotaskContactsArgs = {
  additionalAddressInformation?: InputMaybe<Scalars['String']['input']>;
  addressLine?: InputMaybe<Scalars['String']['input']>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  alternatePhone?: InputMaybe<Scalars['String']['input']>;
  apiVendorID?: InputMaybe<Scalars['Int']['input']>;
  bulkEmailOptOutTime?: InputMaybe<Scalars['Date']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyID?: InputMaybe<Scalars['Int']['input']>;
  companyLocationID?: InputMaybe<Scalars['Int']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Int']['input']>;
  coreContactId?: InputMaybe<Scalars['Int']['input']>;
  countryID?: InputMaybe<Scalars['Int']['input']>;
  createDate?: InputMaybe<Scalars['Date']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  emailAddress2?: InputMaybe<Scalars['String']['input']>;
  emailAddress3?: InputMaybe<Scalars['String']['input']>;
  extension?: InputMaybe<Scalars['String']['input']>;
  externalID?: InputMaybe<Scalars['String']['input']>;
  facebookUrl?: InputMaybe<Scalars['String']['input']>;
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  impersonatorCreatorResourceID?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Int']['input']>;
  isOptedOutFromBulkEmail?: InputMaybe<Scalars['Boolean']['input']>;
  lastActivityDate?: InputMaybe<Scalars['Date']['input']>;
  lastModifiedDate?: InputMaybe<Scalars['Date']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  linkedInUrl?: InputMaybe<Scalars['String']['input']>;
  middleInitial?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  namePrefix?: InputMaybe<Scalars['Int']['input']>;
  nameSuffix?: InputMaybe<Scalars['Int']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  primaryContact?: InputMaybe<Scalars['Boolean']['input']>;
  receivesEmailNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  roomNumber?: InputMaybe<Scalars['String']['input']>;
  solicitationOptOut?: InputMaybe<Scalars['Boolean']['input']>;
  solicitationOptOutTime?: InputMaybe<Scalars['Date']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  surveyOptOut?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateAutotaskTicketStatusesArgs = {
  id: Scalars['Int']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isSystem?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentValue?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateAutotaskTicketsArgs = {
  apiVendorID?: InputMaybe<Scalars['Int']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  assignedResourceID?: InputMaybe<Scalars['Int']['input']>;
  assignedResourceRoleID?: InputMaybe<Scalars['Int']['input']>;
  billingCodeID?: InputMaybe<Scalars['Int']['input']>;
  changeApprovalBoard?: InputMaybe<Scalars['Int']['input']>;
  changeApprovalStatus?: InputMaybe<Scalars['Int']['input']>;
  changeApprovalType?: InputMaybe<Scalars['Int']['input']>;
  changeInfoField1?: InputMaybe<Scalars['String']['input']>;
  changeInfoField2?: InputMaybe<Scalars['String']['input']>;
  changeInfoField3?: InputMaybe<Scalars['String']['input']>;
  changeInfoField4?: InputMaybe<Scalars['String']['input']>;
  changeInfoField5?: InputMaybe<Scalars['String']['input']>;
  companyID?: InputMaybe<Scalars['Int']['input']>;
  companyLocationID?: InputMaybe<Scalars['Int']['input']>;
  completedBy?: InputMaybe<Scalars['String']['input']>;
  completedByResourceID?: InputMaybe<Scalars['Int']['input']>;
  completedDate?: InputMaybe<Scalars['Date']['input']>;
  configurationItemID?: InputMaybe<Scalars['Int']['input']>;
  contactID?: InputMaybe<Scalars['Int']['input']>;
  contactlessStart?: InputMaybe<Scalars['Boolean']['input']>;
  contractID?: InputMaybe<Scalars['Int']['input']>;
  contractServiceBundleID?: InputMaybe<Scalars['Int']['input']>;
  contractServiceID?: InputMaybe<Scalars['Int']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Int']['input']>;
  createDate?: InputMaybe<Scalars['Date']['input']>;
  createdByContactID?: InputMaybe<Scalars['Int']['input']>;
  creatorResourceID?: InputMaybe<Scalars['Int']['input']>;
  creatorType?: InputMaybe<Scalars['Int']['input']>;
  currentServiceThermometerRating?: InputMaybe<Scalars['Int']['input']>;
  dueDateTime?: InputMaybe<Scalars['Date']['input']>;
  enlightendTicketClass?: InputMaybe<Scalars['String']['input']>;
  estimatedHours?: InputMaybe<Scalars['Int']['input']>;
  externalID?: InputMaybe<Scalars['String']['input']>;
  firstResponseAssignedResourceID?: InputMaybe<Scalars['Int']['input']>;
  firstResponseDateTime?: InputMaybe<Scalars['Date']['input']>;
  firstResponseDueDateTime?: InputMaybe<Scalars['Date']['input']>;
  firstResponseInitiatingResourceID?: InputMaybe<Scalars['Int']['input']>;
  fromAutotask?: InputMaybe<Scalars['Boolean']['input']>;
  hoursToBeScheduled?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  impersonatorCreatorResourceID?: InputMaybe<Scalars['Int']['input']>;
  issueType?: InputMaybe<Scalars['Int']['input']>;
  lastActivityDate?: InputMaybe<Scalars['Date']['input']>;
  lastActivityPersonType?: InputMaybe<Scalars['Int']['input']>;
  lastActivityResourceID?: InputMaybe<Scalars['Int']['input']>;
  lastCustomerNotificationDateTime?: InputMaybe<Scalars['Date']['input']>;
  lastCustomerVisibleActivityDateTime?: InputMaybe<Scalars['Date']['input']>;
  lastImportanceSync?: InputMaybe<Scalars['Date']['input']>;
  lastTrackedModificationDateTime?: InputMaybe<Scalars['Date']['input']>;
  maxImportanceRank?: InputMaybe<Scalars['Int']['input']>;
  mergeToTicketId?: InputMaybe<Scalars['Int']['input']>;
  monitorID?: InputMaybe<Scalars['Int']['input']>;
  monitorTypeID?: InputMaybe<Scalars['Int']['input']>;
  nextActionDate?: InputMaybe<Scalars['Date']['input']>;
  nextActionNote?: InputMaybe<Scalars['String']['input']>;
  nextActionTime?: InputMaybe<Scalars['Int']['input']>;
  nextActionType?: InputMaybe<Scalars['String']['input']>;
  opportunityID?: InputMaybe<Scalars['Int']['input']>;
  organizationalLevelAssociationID?: InputMaybe<Scalars['Int']['input']>;
  parentTicketId?: InputMaybe<Scalars['Int']['input']>;
  previousServiceThermometerRating?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  problemTicketId?: InputMaybe<Scalars['Int']['input']>;
  projectID?: InputMaybe<Scalars['Int']['input']>;
  purchaseOrderNumber?: InputMaybe<Scalars['String']['input']>;
  queueID?: InputMaybe<Scalars['Int']['input']>;
  recipeInstanceId?: InputMaybe<Scalars['Int']['input']>;
  resolution?: InputMaybe<Scalars['String']['input']>;
  resolutionPlanDateTime?: InputMaybe<Scalars['Date']['input']>;
  resolutionPlanDueDateTime?: InputMaybe<Scalars['Int']['input']>;
  resolvedDateTime?: InputMaybe<Scalars['Date']['input']>;
  resolvedDueDateTime?: InputMaybe<Scalars['Date']['input']>;
  resyncImportanceRank?: InputMaybe<Scalars['Boolean']['input']>;
  rmaStatus?: InputMaybe<Scalars['Int']['input']>;
  rmaType?: InputMaybe<Scalars['Int']['input']>;
  rmmAlertID?: InputMaybe<Scalars['String']['input']>;
  serviceLevelAgreementHasBeenMet?: InputMaybe<Scalars['Int']['input']>;
  serviceLevelAgreementID?: InputMaybe<Scalars['Int']['input']>;
  serviceLevelAgreementPausedNextEventHours?: InputMaybe<Scalars['Int']['input']>;
  serviceThermometerTemperature?: InputMaybe<Scalars['Int']['input']>;
  source?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  subIssueType?: InputMaybe<Scalars['Int']['input']>;
  ticketCategory?: InputMaybe<Scalars['Int']['input']>;
  ticketNumber?: InputMaybe<Scalars['String']['input']>;
  ticketType?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateAzureLicenseArgs = {
  azureUserId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  skuId?: InputMaybe<Scalars['String']['input']>;
  skuPartNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateAzureTenantsArgs = {
  coreCompanyId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateAzureUsersArgs = {
  accountEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  coreContactId?: InputMaybe<Scalars['Int']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isMfaRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastMfaCheckDate?: InputMaybe<Scalars['Date']['input']>;
  lastSynced?: InputMaybe<Scalars['Date']['input']>;
  mail?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  officeLocation?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  surname?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
  userPrincipalName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateContractsAssignmentsArgs = {
  coreContactId?: InputMaybe<Scalars['Float']['input']>;
  coreLocationId?: InputMaybe<Scalars['Float']['input']>;
  deviceId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Float']['input'];
  instanceId?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationUpdateContractsContractsArgs = {
  additionalPrice?: InputMaybe<Scalars['Float']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  iconColor?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  internalDescription?: InputMaybe<Scalars['String']['input']>;
  internalUnitCost?: InputMaybe<Scalars['Float']['input']>;
  laborIncluded?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  publicDescription?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  unitsMeasured?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateContractsInstancesArgs = {
  additionalPrice?: InputMaybe<Scalars['Float']['input']>;
  approvedUnits?: InputMaybe<Scalars['Float']['input']>;
  autoAproveUnits?: InputMaybe<Scalars['Boolean']['input']>;
  contractId?: InputMaybe<Scalars['Float']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Float']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
  usedUnits?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationUpdateCoreCompaniesArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  billingConfigured?: InputMaybe<Scalars['Boolean']['input']>;
  defaultHourlyRate?: InputMaybe<Scalars['Float']['input']>;
  defaultTicketLevel?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCoreContactArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  alternatePhone?: InputMaybe<Scalars['String']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Int']['input']>;
  coreLocationId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobilePhone?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneExtension?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCoreGroupArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCoreGroupsMembersArgs = {
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Float']['input'];
};


export type MutationUpdateCoreTagArgs = {
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCoreTagLinkArgs = {
  link?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};


export type MutationUpdateCoreUsersArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  autotaskId?: InputMaybe<Scalars['Int']['input']>;
  autotaskLocationId?: InputMaybe<Scalars['Int']['input']>;
  bvoipExtention?: InputMaybe<Scalars['Int']['input']>;
  canContactCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isDispatchable?: InputMaybe<Scalars['Boolean']['input']>;
  isOnCall?: InputMaybe<Scalars['Boolean']['input']>;
  isRoleTier1?: InputMaybe<Scalars['Boolean']['input']>;
  isRoleTier2?: InputMaybe<Scalars['Boolean']['input']>;
  isRoleTier2FirstUp?: InputMaybe<Scalars['Boolean']['input']>;
  isRoleTriage?: InputMaybe<Scalars['Boolean']['input']>;
  isScheduler?: InputMaybe<Scalars['Boolean']['input']>;
  isSherpaChampion?: InputMaybe<Scalars['Boolean']['input']>;
  isTech?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  techTier?: InputMaybe<Scalars['Int']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  timwoodsId?: InputMaybe<Scalars['String']['input']>;
  useNewRank?: InputMaybe<Scalars['Boolean']['input']>;
  workDayEnd?: InputMaybe<Scalars['String']['input']>;
  workDayStart?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateDattormmDevicesArgs = {
  a64Bit?: InputMaybe<Scalars['Boolean']['input']>;
  antivirusProduct?: InputMaybe<Scalars['String']['input']>;
  antivirusStatus?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  cagVersion?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  creationDate?: InputMaybe<Scalars['Date']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  deviceClass?: InputMaybe<Scalars['String']['input']>;
  displayVersion?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  extIpAddress?: InputMaybe<Scalars['String']['input']>;
  hostname?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  intIpAddress?: InputMaybe<Scalars['String']['input']>;
  lastAuditDate?: InputMaybe<Scalars['Date']['input']>;
  lastLoggedInUser?: InputMaybe<Scalars['String']['input']>;
  lastReboot?: InputMaybe<Scalars['Date']['input']>;
  lastSeen?: InputMaybe<Scalars['Date']['input']>;
  online?: InputMaybe<Scalars['Boolean']['input']>;
  operatingSystem?: InputMaybe<Scalars['String']['input']>;
  patchStatus?: InputMaybe<Scalars['String']['input']>;
  patchesApprovedPending?: InputMaybe<Scalars['Float']['input']>;
  patchesInstalled?: InputMaybe<Scalars['Float']['input']>;
  patchesNotApproved?: InputMaybe<Scalars['Float']['input']>;
  portalUrl?: InputMaybe<Scalars['String']['input']>;
  rebootRequired?: InputMaybe<Scalars['Boolean']['input']>;
  siteId?: InputMaybe<Scalars['Float']['input']>;
  siteName?: InputMaybe<Scalars['String']['input']>;
  siteUid?: InputMaybe<Scalars['String']['input']>;
  snmpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  softwareStatus?: InputMaybe<Scalars['String']['input']>;
  suspended?: InputMaybe<Scalars['Boolean']['input']>;
  syncId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  udf1?: InputMaybe<Scalars['String']['input']>;
  udf2?: InputMaybe<Scalars['String']['input']>;
  udf3?: InputMaybe<Scalars['String']['input']>;
  udf4?: InputMaybe<Scalars['String']['input']>;
  udf5?: InputMaybe<Scalars['String']['input']>;
  udf6?: InputMaybe<Scalars['String']['input']>;
  udf7?: InputMaybe<Scalars['String']['input']>;
  udf8?: InputMaybe<Scalars['String']['input']>;
  udf9?: InputMaybe<Scalars['String']['input']>;
  udf10?: InputMaybe<Scalars['String']['input']>;
  udf11?: InputMaybe<Scalars['String']['input']>;
  udf12?: InputMaybe<Scalars['String']['input']>;
  udf13?: InputMaybe<Scalars['String']['input']>;
  udf14?: InputMaybe<Scalars['String']['input']>;
  udf15?: InputMaybe<Scalars['String']['input']>;
  udf16?: InputMaybe<Scalars['String']['input']>;
  udf17?: InputMaybe<Scalars['String']['input']>;
  udf18?: InputMaybe<Scalars['String']['input']>;
  udf19?: InputMaybe<Scalars['String']['input']>;
  udf20?: InputMaybe<Scalars['String']['input']>;
  udf21?: InputMaybe<Scalars['String']['input']>;
  udf22?: InputMaybe<Scalars['String']['input']>;
  udf23?: InputMaybe<Scalars['String']['input']>;
  udf24?: InputMaybe<Scalars['String']['input']>;
  udf25?: InputMaybe<Scalars['String']['input']>;
  udf26?: InputMaybe<Scalars['String']['input']>;
  udf27?: InputMaybe<Scalars['String']['input']>;
  udf28?: InputMaybe<Scalars['String']['input']>;
  udf29?: InputMaybe<Scalars['String']['input']>;
  udf30?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
  warrantyDate?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateDattormmPatchingPoliciesArgs = {
  deviceId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Float']['input'];
  lastRun?: InputMaybe<Scalars['Date']['input']>;
  lastSync?: InputMaybe<Scalars['Date']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  schedule?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateDattormmSitesArgs = {
  autotaskCompanyId?: InputMaybe<Scalars['String']['input']>;
  autotaskCompanyName?: InputMaybe<Scalars['String']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  numberOfDevices?: InputMaybe<Scalars['Float']['input']>;
  numberOfOfflineDevices?: InputMaybe<Scalars['Float']['input']>;
  numberOfOnlineDevices?: InputMaybe<Scalars['Float']['input']>;
  onDemand?: InputMaybe<Scalars['Boolean']['input']>;
  portalUrl?: InputMaybe<Scalars['String']['input']>;
  proxySettings?: InputMaybe<Scalars['Float']['input']>;
  splashtopAutoInstall?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateFeedbackInstanceArgs = {
  id: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateFeedbackScoreArgs = {
  customerResp?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateFormsFormArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateFormsInstanceArgs = {
  autotaskTicketId?: InputMaybe<Scalars['Int']['input']>;
  complete?: InputMaybe<Scalars['Boolean']['input']>;
  formId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  recipeInstanceId?: InputMaybe<Scalars['Int']['input']>;
  todoId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdatePhonesCallLegsArgs = {
  callProvider?: InputMaybe<Scalars['String']['input']>;
  callProviderId?: InputMaybe<Scalars['Int']['input']>;
  coreContactId?: InputMaybe<Scalars['Int']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  extention?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  phonesCallId?: InputMaybe<Scalars['Int']['input']>;
  recordingLocalPath?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['Date']['input']>;
  transcription?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdatePhonesCallsArgs = {
  atTicketId?: InputMaybe<Scalars['Int']['input']>;
  callProvider?: InputMaybe<Scalars['String']['input']>;
  callProviderId?: InputMaybe<Scalars['Int']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Int']['input']>;
  coreContactId?: InputMaybe<Scalars['Int']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  extention?: InputMaybe<Scalars['String']['input']>;
  fromNumber?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  startTime?: InputMaybe<Scalars['Date']['input']>;
  toNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateRecipeNodeArgs = {
  data?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  positionX?: InputMaybe<Scalars['Float']['input']>;
  positionY?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateRocketcyberAccountsArgs = {
  coreCompanyId?: InputMaybe<Scalars['Float']['input']>;
  emails?: InputMaybe<Scalars['String']['input']>;
  hierarchy?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateRocketcyberAgentsArgs = {
  accountPath?: InputMaybe<Scalars['String']['input']>;
  agentVersion?: InputMaybe<Scalars['String']['input']>;
  architecture?: InputMaybe<Scalars['String']['input']>;
  build?: InputMaybe<Scalars['String']['input']>;
  connectivity?: InputMaybe<Scalars['String']['input']>;
  edition?: InputMaybe<Scalars['String']['input']>;
  family?: InputMaybe<Scalars['String']['input']>;
  hostname?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  ipv4Address?: InputMaybe<Scalars['String']['input']>;
  macAddress?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  release?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateRocketcyberIncidentsArgs = {
  autotaskTicketId?: InputMaybe<Scalars['Float']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  eventCount?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Float']['input'];
  remediation?: InputMaybe<Scalars['String']['input']>;
  resolvedAt?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateScheduleInstanceArgs = {
  atTicketId?: InputMaybe<Scalars['Int']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  azureCalendarId?: InputMaybe<Scalars['String']['input']>;
  conflictDetected?: InputMaybe<Scalars['Boolean']['input']>;
  coreGroupId?: InputMaybe<Scalars['Int']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDateTime?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Int']['input'];
  lockedDateTime?: InputMaybe<Scalars['Boolean']['input']>;
  lockedUser?: InputMaybe<Scalars['Boolean']['input']>;
  overidePlan?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleInstanceDay?: InputMaybe<Scalars['String']['input']>;
  schedulePlanId?: InputMaybe<Scalars['Int']['input']>;
  schedulerLog?: InputMaybe<Scalars['String']['input']>;
  startDateTime?: InputMaybe<Scalars['Date']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateSchedulePlanArgs = {
  atTicketId?: InputMaybe<Scalars['Int']['input']>;
  available?: InputMaybe<Scalars['Boolean']['input']>;
  blockSizeInMinutes?: InputMaybe<Scalars['Int']['input']>;
  coreGroupId?: InputMaybe<Scalars['Int']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  desiredUserCount?: InputMaybe<Scalars['Int']['input']>;
  end_hour?: InputMaybe<Scalars['Int']['input']>;
  end_minute?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  optimalBlockSizeInMinutes?: InputMaybe<Scalars['Int']['input']>;
  splitableIntoBlocks?: InputMaybe<Scalars['Boolean']['input']>;
  startTimeDay_daysOfMonth?: InputMaybe<Array<Scalars['Int']['input']>>;
  startTimeDay_daysOfWeek?: InputMaybe<Array<Scalars['String']['input']>>;
  startTimeDay_monthsOfTheYear?: InputMaybe<Array<Scalars['String']['input']>>;
  startTimeDay_year?: InputMaybe<Scalars['Int']['input']>;
  start_hour?: InputMaybe<Scalars['Int']['input']>;
  start_minute?: InputMaybe<Scalars['Int']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateTicketTimelineArgs = {
  atTicketId?: InputMaybe<Scalars['Int']['input']>;
  azureEmailId?: InputMaybe<Scalars['String']['input']>;
  coreTimeclockId?: InputMaybe<Scalars['Int']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  formInstanceId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  phonesCallId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateTimeclockArgs = {
  end?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['Float']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['Date']['input']>;
};


export type MutationUpdateTodosTodoArgs = {
  autotaskTicketId?: InputMaybe<Scalars['Int']['input']>;
  blockedByTodoId?: InputMaybe<Scalars['Int']['input']>;
  complete?: InputMaybe<Scalars['Boolean']['input']>;
  completeAt?: InputMaybe<Scalars['Date']['input']>;
  completeByUser?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  createdByModuleId?: InputMaybe<Scalars['String']['input']>;
  createdByModuleName?: InputMaybe<Scalars['String']['input']>;
  createdByUser?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  estimatedMinutes?: InputMaybe<Scalars['Int']['input']>;
  formInstanceId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  isOnsite?: InputMaybe<Scalars['Boolean']['input']>;
  isOutboundCall?: InputMaybe<Scalars['Boolean']['input']>;
  mergeFromTicketId?: InputMaybe<Scalars['Int']['input']>;
  nextActionTime?: InputMaybe<Scalars['Int']['input']>;
  nextActionType?: InputMaybe<Scalars['String']['input']>;
  noteType?: InputMaybe<Scalars['String']['input']>;
  outboundCallNumber?: InputMaybe<Scalars['Int']['input']>;
  recipeInstanceId?: InputMaybe<Scalars['Int']['input']>;
  response?: InputMaybe<Scalars['String']['input']>;
  ticketBlocking?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  tripStopId?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
};


export type MutationVerifyAzureTenantArgs = {
  id: Scalars['String']['input'];
};

export type NumberProp = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<Scalars['Float']['input']>;
  notnull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OpsComplianceUserStatus = {
  __typename?: 'OpsComplianceUserStatus';
  coreUserId: Scalars['String']['output'];
  end?: Maybe<Scalars['DateTime']['output']>;
  hasDispatch?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  isDispatchable?: Maybe<Scalars['Boolean']['output']>;
  isOnCall?: Maybe<Scalars['Boolean']['output']>;
  isTimwoodsClockedIn?: Maybe<Scalars['Boolean']['output']>;
  isWorking?: Maybe<Scalars['Boolean']['output']>;
  isWorkingClose?: Maybe<Scalars['Boolean']['output']>;
  isWorkingDispatched?: Maybe<Scalars['Boolean']['output']>;
  lastChecked: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
  user?: Maybe<CoreUsers>;
};

export type PaginatedAiChat = {
  __typename?: 'PaginatedAIChat';
  data?: Maybe<Array<AiChat>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedAiChatMessages = {
  __typename?: 'PaginatedAIChatMessages';
  data?: Maybe<Array<AiChatMessages>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedActionLogAction = {
  __typename?: 'PaginatedActionLogAction';
  data?: Maybe<Array<ActionLogAction>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedAutotaskConfigurationItems = {
  __typename?: 'PaginatedAutotaskConfigurationItems';
  data?: Maybe<Array<AutotaskConfigurationItems>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedAutotaskContacts = {
  __typename?: 'PaginatedAutotaskContacts';
  data?: Maybe<Array<AutotaskContacts>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedAutotaskTicketStatuses = {
  __typename?: 'PaginatedAutotaskTicketStatuses';
  data?: Maybe<Array<AutotaskTicketStatuses>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedAutotaskTickets = {
  __typename?: 'PaginatedAutotaskTickets';
  data?: Maybe<Array<AutotaskTickets>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedAzureLicense = {
  __typename?: 'PaginatedAzureLicense';
  data?: Maybe<Array<AzureLicense>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedAzureTenants = {
  __typename?: 'PaginatedAzureTenants';
  data?: Maybe<Array<AzureTenants>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedAzureUsers = {
  __typename?: 'PaginatedAzureUsers';
  data?: Maybe<Array<AzureUsers>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedContractsAssignments = {
  __typename?: 'PaginatedContractsAssignments';
  data?: Maybe<Array<ContractsAssignments>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedContractsContracts = {
  __typename?: 'PaginatedContractsContracts';
  data?: Maybe<Array<ContractsContracts>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedContractsInstances = {
  __typename?: 'PaginatedContractsInstances';
  data?: Maybe<Array<ContractsInstances>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedCoreCompanies = {
  __typename?: 'PaginatedCoreCompanies';
  data?: Maybe<Array<CoreCompanies>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedCoreContact = {
  __typename?: 'PaginatedCoreContact';
  data?: Maybe<Array<CoreContact>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedCoreGroup = {
  __typename?: 'PaginatedCoreGroup';
  data?: Maybe<Array<CoreGroup>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedCoreGroupsMembers = {
  __typename?: 'PaginatedCoreGroupsMembers';
  data?: Maybe<Array<CoreGroupsMembers>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedCoreTag = {
  __typename?: 'PaginatedCoreTag';
  data?: Maybe<Array<CoreTag>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedCoreTagLink = {
  __typename?: 'PaginatedCoreTagLink';
  data?: Maybe<Array<CoreTagLink>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedCoreUsers = {
  __typename?: 'PaginatedCoreUsers';
  data?: Maybe<Array<CoreUsers>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedDattormmDevices = {
  __typename?: 'PaginatedDattormmDevices';
  data?: Maybe<Array<DattormmDevices>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedDattormmPatchingPolicies = {
  __typename?: 'PaginatedDattormmPatchingPolicies';
  data?: Maybe<Array<DattormmPatchingPolicies>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedDattormmSites = {
  __typename?: 'PaginatedDattormmSites';
  data?: Maybe<Array<DattormmSites>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedFeedbackInstance = {
  __typename?: 'PaginatedFeedbackInstance';
  data?: Maybe<Array<FeedbackInstance>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedFeedbackScore = {
  __typename?: 'PaginatedFeedbackScore';
  data?: Maybe<Array<FeedbackScore>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedFormsForm = {
  __typename?: 'PaginatedFormsForm';
  data?: Maybe<Array<FormsForm>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedFormsInstance = {
  __typename?: 'PaginatedFormsInstance';
  data?: Maybe<Array<FormsInstance>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedPhonesCallLegs = {
  __typename?: 'PaginatedPhonesCallLegs';
  data?: Maybe<Array<PhonesCallLegs>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedPhonesCalls = {
  __typename?: 'PaginatedPhonesCalls';
  data?: Maybe<Array<PhonesCalls>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedRecipeNode = {
  __typename?: 'PaginatedRecipeNode';
  data?: Maybe<Array<RecipeNode>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedRocketcyberAccounts = {
  __typename?: 'PaginatedRocketcyberAccounts';
  data?: Maybe<Array<RocketcyberAccounts>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedRocketcyberAgents = {
  __typename?: 'PaginatedRocketcyberAgents';
  data?: Maybe<Array<RocketcyberAgents>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedRocketcyberIncidents = {
  __typename?: 'PaginatedRocketcyberIncidents';
  data?: Maybe<Array<RocketcyberIncidents>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedScheduleInstance = {
  __typename?: 'PaginatedScheduleInstance';
  data?: Maybe<Array<ScheduleInstance>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedSchedulePlan = {
  __typename?: 'PaginatedSchedulePlan';
  data?: Maybe<Array<SchedulePlan>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedTicketTimeline = {
  __typename?: 'PaginatedTicketTimeline';
  data?: Maybe<Array<TicketTimeline>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedTimeclocks = {
  __typename?: 'PaginatedTimeclocks';
  data?: Maybe<Array<CoreTimeclock>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedTodosTodo = {
  __typename?: 'PaginatedTodosTodo';
  data?: Maybe<Array<TodosTodo>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PaginatedUserStatus = {
  __typename?: 'PaginatedUserStatus';
  data?: Maybe<Array<OpsComplianceUserStatus>>;
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type PhonesCallLegs = {
  __typename?: 'PhonesCallLegs';
  Contact?: Maybe<CoreContact>;
  CoreUser?: Maybe<CoreUsers>;
  PhoneCall?: Maybe<PhonesCalls>;
  callProvider?: Maybe<Scalars['String']['output']>;
  callProviderId?: Maybe<Scalars['Float']['output']>;
  coreContactId?: Maybe<Scalars['Float']['output']>;
  coreUserId?: Maybe<Scalars['String']['output']>;
  createdByCoreUserId?: Maybe<Scalars['String']['output']>;
  createdDateTime: Scalars['DateTime']['output'];
  downloadUrl?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['DateTimeIso']['output']>;
  extention?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  phonesCallId?: Maybe<Scalars['Float']['output']>;
  recordingLocalPath?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['DateTimeIso']['output']>;
  transcription?: Maybe<Scalars['String']['output']>;
  updatedByCoreUserId?: Maybe<Scalars['String']['output']>;
  updatedDateTime: Scalars['DateTime']['output'];
};

export type PhonesCalls = {
  __typename?: 'PhonesCalls';
  AutotaskTickets?: Maybe<AutotaskTickets>;
  BvoipCalls?: Maybe<Array<BvoipCalls>>;
  Contact?: Maybe<CoreContact>;
  CoreCompany?: Maybe<CoreCompanies>;
  CoreUser?: Maybe<CoreUsers>;
  PhoneCallLegs?: Maybe<Array<PhonesCallLegs>>;
  TicketTimelines?: Maybe<Array<TicketTimeline>>;
  atTicketId?: Maybe<Scalars['Float']['output']>;
  callProvider?: Maybe<Scalars['String']['output']>;
  callProviderId?: Maybe<Scalars['Float']['output']>;
  coreCompanyId?: Maybe<Scalars['Float']['output']>;
  coreContactId?: Maybe<Scalars['Float']['output']>;
  coreUserId?: Maybe<Scalars['String']['output']>;
  createdByCoreUserId?: Maybe<Scalars['String']['output']>;
  createdDateTime: Scalars['DateTime']['output'];
  direction?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['DateTimeIso']['output']>;
  extention?: Maybe<Scalars['String']['output']>;
  fromNumber?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  startTime?: Maybe<Scalars['DateTimeIso']['output']>;
  toNumber?: Maybe<Scalars['String']['output']>;
  updatedByCoreUserId?: Maybe<Scalars['String']['output']>;
  updatedDateTime: Scalars['DateTime']['output'];
};

export type Query = {
  __typename?: 'Query';
  AITranscribeAudio: Scalars['String']['output'];
  findAIChatMessagesPaginated: PaginatedAiChatMessages;
  findAIChatPaginated: PaginatedAiChat;
  findActionLogActionPaginated: PaginatedActionLogAction;
  findAutotaskConfigurationItemsPaginated: PaginatedAutotaskConfigurationItems;
  findAutotaskContactsPaginated: PaginatedAutotaskContacts;
  findAutotaskTicketStatusesPaginated: PaginatedAutotaskTicketStatuses;
  findAutotaskTicketsPaginated: PaginatedAutotaskTickets;
  findAzureLicensePaginated: PaginatedAzureLicense;
  findAzureTenantsPaginated: PaginatedAzureTenants;
  findAzureUsersPaginated: PaginatedAzureUsers;
  findContractsAssignmentsPaginated: PaginatedContractsAssignments;
  findContractsContractsPaginated: PaginatedContractsContracts;
  findContractsInstancesPaginated: PaginatedContractsInstances;
  findCoreCompaniesPaginated: PaginatedCoreCompanies;
  findCoreContactPaginated: PaginatedCoreContact;
  findCoreGroupPaginated: PaginatedCoreGroup;
  findCoreGroupsMembersPaginated: PaginatedCoreGroupsMembers;
  findCoreTagLinkPaginated: PaginatedCoreTagLink;
  findCoreTagPaginated: PaginatedCoreTag;
  findCoreUsersPaginated: PaginatedCoreUsers;
  findDattormmDevicesPaginated: PaginatedDattormmDevices;
  findDattormmPatchingPoliciesPaginated: PaginatedDattormmPatchingPolicies;
  findDattormmSitesPaginated: PaginatedDattormmSites;
  findFeedbackInstancePaginated: PaginatedFeedbackInstance;
  findFeedbackScorePaginated: PaginatedFeedbackScore;
  findFormsFormPaginated: PaginatedFormsForm;
  findFormsInstancePaginated: PaginatedFormsInstance;
  findPhonesCallLegsPaginated: PaginatedPhonesCallLegs;
  findPhonesCallsPaginated: PaginatedPhonesCalls;
  findRecipeNodePaginated: PaginatedRecipeNode;
  findRocketcyberAccountsPaginated: PaginatedRocketcyberAccounts;
  findRocketcyberAgentsPaginated: PaginatedRocketcyberAgents;
  findRocketcyberIncidentsPaginated: PaginatedRocketcyberIncidents;
  findScheduleInstancePaginated: PaginatedScheduleInstance;
  findSchedulePlanPaginated: PaginatedSchedulePlan;
  findTicketTimelinePaginated: PaginatedTicketTimeline;
  findTodosTodoPaginated: PaginatedTodosTodo;
  getAIChatById: AiChat;
  getAIChatMessagesById: AiChatMessages;
  getActionLogActionById: ActionLogAction;
  getAutotaskConfigurationItemsById: AutotaskConfigurationItems;
  getAutotaskContactsById: AutotaskContacts;
  getAutotaskTicketStatusesById: AutotaskTicketStatuses;
  getAutotaskTicketsById: AutotaskTickets;
  getAzureLicenseById: AzureLicense;
  getAzureTenantsById: AzureTenants;
  getAzureUsersById: AzureUsers;
  getCompanyByDomain: Scalars['Int']['output'];
  getContractsAssignmentsById: ContractsAssignments;
  getContractsContractsById: ContractsContracts;
  getContractsInstancesById: ContractsInstances;
  getCoreCompaniesById: CoreCompanies;
  getCoreContactById: CoreContact;
  getCoreGroupById: CoreGroup;
  getCoreGroupsMembersById: CoreGroupsMembers;
  getCoreTagById: CoreTag;
  getCoreTagLinkById: CoreTagLink;
  getCoreUsersById: CoreUsers;
  getDattormmDevicesById: DattormmDevices;
  getDattormmPatchingPoliciesById: DattormmPatchingPolicies;
  getDattormmSitesById: DattormmSites;
  getFeedbackInstanceById: FeedbackInstance;
  getFeedbackScoreById: FeedbackScore;
  getFormsFormById: FormsForm;
  getFormsInstanceById: FormsInstance;
  getPhonesCallLegsById: PhonesCallLegs;
  getPhonesCallsById: PhonesCalls;
  getRecipeNodeById: RecipeNode;
  getRocketcyberAccountsById: RocketcyberAccounts;
  getRocketcyberAgentsById: RocketcyberAgents;
  getRocketcyberIncidentsById: RocketcyberIncidents;
  getScheduleInstanceById: ScheduleInstance;
  getSchedulePlanById: SchedulePlan;
  getTicketTimelineById: TicketTimeline;
  getTodosTodoById: TodosTodo;
  signSecureGetUrl: Scalars['String']['output'];
  signSecurePutUrl: Scalars['String']['output'];
  timeclock: CoreTimeclock;
  timeclocks: PaginatedTimeclocks;
  userstatuses: PaginatedUserStatus;
};


export type QueryAiTranscribeAudioArgs = {
  input: TranscribeArgs;
  provider?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFindAiChatMessagesPaginatedArgs = {
  chatId?: InputMaybe<NumberProp>;
  coreUserId?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
  rawMessage?: InputMaybe<StringProp>;
  role?: InputMaybe<StringProp>;
  runWithContexts?: InputMaybe<StringProp>;
};


export type QueryFindAiChatPaginatedArgs = {
  coreUserId?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
  ticket?: InputMaybe<GetTicketsArgs>;
  ticketId?: InputMaybe<NumberProp>;
  user?: InputMaybe<GetUsersArgs>;
};


export type QueryFindActionLogActionPaginatedArgs = {
  actionTime?: InputMaybe<TimeQueryProp>;
  azureEmailId?: InputMaybe<StringProp>;
  coreUserId?: InputMaybe<StringProp>;
  databaseAction?: InputMaybe<StringProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  loggedFunction?: InputMaybe<StringProp>;
  module?: InputMaybe<StringProp>;
  moduleIdNumber?: InputMaybe<NumberProp>;
  moduleIdString?: InputMaybe<StringProp>;
  order?: InputMaybe<SortActionLogActionsArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  ticketId?: InputMaybe<NumberProp>;
  userActionId?: InputMaybe<StringProp>;
};


export type QueryFindAutotaskConfigurationItemsPaginatedArgs = {
  apiVendorID?: InputMaybe<NumberProp>;
  companyID?: InputMaybe<NumberProp>;
  companyLocationID?: InputMaybe<NumberProp>;
  configurationItemCategoryID?: InputMaybe<NumberProp>;
  configurationItemType?: InputMaybe<NumberProp>;
  contactID?: InputMaybe<NumberProp>;
  contractID?: InputMaybe<NumberProp>;
  contractServiceBundleID?: InputMaybe<NumberProp>;
  contractServiceID?: InputMaybe<NumberProp>;
  createDate?: InputMaybe<TimeQueryProp>;
  createdByPersonID?: InputMaybe<NumberProp>;
  dailyCost?: InputMaybe<NumberProp>;
  dattoAvailableKilobytes?: InputMaybe<NumberProp>;
  dattoDeviceMemoryMegabytes?: InputMaybe<NumberProp>;
  dattoDrivesErrors?: InputMaybe<BooleanProp>;
  dattoHostname?: InputMaybe<StringProp>;
  dattoInternalIP?: InputMaybe<StringProp>;
  dattoKernelVersionID?: InputMaybe<NumberProp>;
  dattoLastCheckInDateTime?: InputMaybe<TimeQueryProp>;
  dattoNICSpeedKilobitsPerSecond?: InputMaybe<NumberProp>;
  dattoNumberOfAgents?: InputMaybe<NumberProp>;
  dattoNumberOfDrives?: InputMaybe<NumberProp>;
  dattoNumberOfVolumes?: InputMaybe<NumberProp>;
  dattoOSVersionID?: InputMaybe<NumberProp>;
  dattoOffsiteUsedBytes?: InputMaybe<NumberProp>;
  dattoPercentageUsed?: InputMaybe<NumberProp>;
  dattoProtectedKilobytes?: InputMaybe<NumberProp>;
  dattoRemoteIP?: InputMaybe<StringProp>;
  dattoSerialNumber?: InputMaybe<StringProp>;
  dattoUptimeSeconds?: InputMaybe<NumberProp>;
  dattoUsedKilobytes?: InputMaybe<NumberProp>;
  dattoZFSVersionID?: InputMaybe<NumberProp>;
  deviceNetworkingID?: InputMaybe<NumberProp>;
  domain?: InputMaybe<NumberProp>;
  domainExpirationDateTime?: InputMaybe<NumberProp>;
  domainLastUpdatedDateTime?: InputMaybe<NumberProp>;
  domainRegistrarID?: InputMaybe<NumberProp>;
  domainRegistrationDateTime?: InputMaybe<NumberProp>;
  hourlyCost?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  impersonatorCreatorResourceID?: InputMaybe<NumberProp>;
  installDate?: InputMaybe<TimeQueryProp>;
  installedByContactID?: InputMaybe<NumberProp>;
  installedByID?: InputMaybe<NumberProp>;
  isActive?: InputMaybe<BooleanProp>;
  lastActivityPersonID?: InputMaybe<NumberProp>;
  lastActivityPersonType?: InputMaybe<NumberProp>;
  lastModifiedTime?: InputMaybe<TimeQueryProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<StringProp>;
  monthlyCost?: InputMaybe<NumberProp>;
  notes?: InputMaybe<StringProp>;
  numberOfUsers?: InputMaybe<NumberProp>;
  order?: InputMaybe<SortAutotaskConfigurationItemssArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  parentConfigurationItemID?: InputMaybe<NumberProp>;
  perUseCost?: InputMaybe<NumberProp>;
  productID?: InputMaybe<NumberProp>;
  referenceNumber?: InputMaybe<StringProp>;
  referenceTitle?: InputMaybe<StringProp>;
  rmmDeviceAuditAntivirusStatusID?: InputMaybe<NumberProp>;
  rmmDeviceAuditArchitectureID?: InputMaybe<NumberProp>;
  rmmDeviceAuditBackupStatusID?: InputMaybe<NumberProp>;
  rmmDeviceAuditDescription?: InputMaybe<StringProp>;
  rmmDeviceAuditDeviceTypeID?: InputMaybe<NumberProp>;
  rmmDeviceAuditDisplayAdaptorID?: InputMaybe<NumberProp>;
  rmmDeviceAuditDomainID?: InputMaybe<NumberProp>;
  rmmDeviceAuditExternalIPAddress?: InputMaybe<StringProp>;
  rmmDeviceAuditHostname?: InputMaybe<StringProp>;
  rmmDeviceAuditIPAddress?: InputMaybe<StringProp>;
  rmmDeviceAuditLastUser?: InputMaybe<StringProp>;
  rmmDeviceAuditMacAddress?: InputMaybe<StringProp>;
  rmmDeviceAuditManufacturerID?: InputMaybe<NumberProp>;
  rmmDeviceAuditMemoryBytes?: InputMaybe<NumberProp>;
  rmmDeviceAuditMissingPatchCount?: InputMaybe<NumberProp>;
  rmmDeviceAuditMobileNetworkOperatorID?: InputMaybe<NumberProp>;
  rmmDeviceAuditMobileNumber?: InputMaybe<StringProp>;
  rmmDeviceAuditModelID?: InputMaybe<NumberProp>;
  rmmDeviceAuditMotherboardID?: InputMaybe<NumberProp>;
  rmmDeviceAuditOperatingSystem?: InputMaybe<StringProp>;
  rmmDeviceAuditPatchStatusID?: InputMaybe<NumberProp>;
  rmmDeviceAuditProcessorID?: InputMaybe<NumberProp>;
  rmmDeviceAuditSNMPContact?: InputMaybe<StringProp>;
  rmmDeviceAuditSNMPLocation?: InputMaybe<StringProp>;
  rmmDeviceAuditSNMPName?: InputMaybe<StringProp>;
  rmmDeviceAuditServicePackID?: InputMaybe<NumberProp>;
  rmmDeviceAuditSoftwareStatusID?: InputMaybe<NumberProp>;
  rmmDeviceAuditStorageBytes?: InputMaybe<NumberProp>;
  rmmDeviceID?: InputMaybe<NumberProp>;
  rmmDeviceUID?: InputMaybe<StringProp>;
  rmmOpenAlertCount?: InputMaybe<NumberProp>;
  search?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<StringProp>;
  serviceBundleID?: InputMaybe<NumberProp>;
  serviceID?: InputMaybe<NumberProp>;
  serviceLevelAgreementID?: InputMaybe<NumberProp>;
  setupFee?: InputMaybe<NumberProp>;
  sourceChargeID?: InputMaybe<NumberProp>;
  sourceChargeType?: InputMaybe<NumberProp>;
  sslCommonName?: InputMaybe<NumberProp>;
  sslIssuedBy?: InputMaybe<NumberProp>;
  sslLocation?: InputMaybe<NumberProp>;
  sslOrganization?: InputMaybe<NumberProp>;
  sslOrganizationUnit?: InputMaybe<NumberProp>;
  sslSerialNumber?: InputMaybe<NumberProp>;
  sslSignatureAlgorithm?: InputMaybe<NumberProp>;
  sslSource?: InputMaybe<NumberProp>;
  sslValidFromDateTime?: InputMaybe<NumberProp>;
  sslValidUntilDateTime?: InputMaybe<NumberProp>;
  vendorID?: InputMaybe<NumberProp>;
  warrantyExpirationDate?: InputMaybe<TimeQueryProp>;
};


export type QueryFindAutotaskContactsPaginatedArgs = {
  additionalAddressInformation?: InputMaybe<StringProp>;
  addressLine?: InputMaybe<StringProp>;
  addressLine1?: InputMaybe<StringProp>;
  alternatePhone?: InputMaybe<StringProp>;
  apiVendorID?: InputMaybe<NumberProp>;
  bulkEmailOptOutTime?: InputMaybe<TimeQueryProp>;
  city?: InputMaybe<StringProp>;
  companyID?: InputMaybe<NumberProp>;
  companyLocationID?: InputMaybe<NumberProp>;
  coreCompanyId?: InputMaybe<NumberProp>;
  coreContactId?: InputMaybe<NumberProp>;
  countryID?: InputMaybe<NumberProp>;
  createDate?: InputMaybe<TimeQueryProp>;
  emailAddress?: InputMaybe<StringProp>;
  emailAddress2?: InputMaybe<StringProp>;
  emailAddress3?: InputMaybe<StringProp>;
  extension?: InputMaybe<StringProp>;
  externalID?: InputMaybe<StringProp>;
  facebookUrl?: InputMaybe<StringProp>;
  faxNumber?: InputMaybe<StringProp>;
  firstName?: InputMaybe<StringProp>;
  id?: InputMaybe<NumberProp>;
  impersonatorCreatorResourceID?: InputMaybe<NumberProp>;
  isActive?: InputMaybe<NumberProp>;
  isOptedOutFromBulkEmail?: InputMaybe<BooleanProp>;
  lastActivityDate?: InputMaybe<TimeQueryProp>;
  lastModifiedDate?: InputMaybe<TimeQueryProp>;
  lastName?: InputMaybe<StringProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  linkedInUrl?: InputMaybe<StringProp>;
  middleInitial?: InputMaybe<StringProp>;
  mobilePhone?: InputMaybe<StringProp>;
  namePrefix?: InputMaybe<NumberProp>;
  nameSuffix?: InputMaybe<NumberProp>;
  note?: InputMaybe<StringProp>;
  order?: InputMaybe<SortAutotaskContactssArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<StringProp>;
  primaryContact?: InputMaybe<BooleanProp>;
  receivesEmailNotifications?: InputMaybe<BooleanProp>;
  roomNumber?: InputMaybe<StringProp>;
  search?: InputMaybe<Scalars['String']['input']>;
  solicitationOptOut?: InputMaybe<BooleanProp>;
  solicitationOptOutTime?: InputMaybe<TimeQueryProp>;
  state?: InputMaybe<StringProp>;
  surveyOptOut?: InputMaybe<BooleanProp>;
  title?: InputMaybe<StringProp>;
  twitterUrl?: InputMaybe<StringProp>;
  zipCode?: InputMaybe<StringProp>;
};


export type QueryFindAutotaskTicketStatusesPaginatedArgs = {
  id?: InputMaybe<NumberProp>;
  isActive?: InputMaybe<BooleanProp>;
  isSystem?: InputMaybe<BooleanProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  order?: InputMaybe<SortAutotaskTicketStatusessArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  parentValue?: InputMaybe<StringProp>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<NumberProp>;
};


export type QueryFindAutotaskTicketsPaginatedArgs = {
  apiVendorID?: InputMaybe<NumberProp>;
  archived?: InputMaybe<BooleanProp>;
  assignedResourceID?: InputMaybe<NumberProp>;
  assignedResourceRoleID?: InputMaybe<NumberProp>;
  autotaskCompany?: InputMaybe<GetAutotaskCompaniesArgs>;
  billingCodeID?: InputMaybe<NumberProp>;
  changeApprovalBoard?: InputMaybe<NumberProp>;
  changeApprovalStatus?: InputMaybe<NumberProp>;
  changeApprovalType?: InputMaybe<NumberProp>;
  changeInfoField1?: InputMaybe<StringProp>;
  changeInfoField2?: InputMaybe<StringProp>;
  changeInfoField3?: InputMaybe<StringProp>;
  changeInfoField4?: InputMaybe<StringProp>;
  changeInfoField5?: InputMaybe<StringProp>;
  company?: InputMaybe<GetAutotaskCompaniesArgs>;
  companyID?: InputMaybe<NumberProp>;
  companyLocationID?: InputMaybe<NumberProp>;
  completedBy?: InputMaybe<StringProp>;
  completedByResourceID?: InputMaybe<NumberProp>;
  completedDate?: InputMaybe<TimeQueryProp>;
  configurationItemID?: InputMaybe<NumberProp>;
  contactID?: InputMaybe<NumberProp>;
  contactlessStart?: InputMaybe<BooleanProp>;
  contractID?: InputMaybe<NumberProp>;
  contractServiceBundleID?: InputMaybe<NumberProp>;
  contractServiceID?: InputMaybe<NumberProp>;
  coreCompanyId?: InputMaybe<NumberProp>;
  createDate?: InputMaybe<TimeQueryProp>;
  createdByContactID?: InputMaybe<NumberProp>;
  creatorResourceID?: InputMaybe<NumberProp>;
  creatorType?: InputMaybe<NumberProp>;
  currentServiceThermometerRating?: InputMaybe<NumberProp>;
  dueDateTime?: InputMaybe<TimeQueryProp>;
  enlightendTicketClass?: InputMaybe<StringProp>;
  estimatedHours?: InputMaybe<NumberProp>;
  externalID?: InputMaybe<StringProp>;
  firstResponseAssignedResourceID?: InputMaybe<NumberProp>;
  firstResponseDateTime?: InputMaybe<TimeQueryProp>;
  firstResponseDueDateTime?: InputMaybe<TimeQueryProp>;
  firstResponseInitiatingResourceID?: InputMaybe<NumberProp>;
  fromAutotask?: InputMaybe<BooleanProp>;
  hoursToBeScheduled?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  impersonatorCreatorResourceID?: InputMaybe<NumberProp>;
  issueType?: InputMaybe<NumberProp>;
  lastActivityDate?: InputMaybe<TimeQueryProp>;
  lastActivityPersonType?: InputMaybe<NumberProp>;
  lastActivityResourceID?: InputMaybe<NumberProp>;
  lastCustomerNotificationDateTime?: InputMaybe<TimeQueryProp>;
  lastCustomerVisibleActivityDateTime?: InputMaybe<TimeQueryProp>;
  lastImportanceSync?: InputMaybe<TimeQueryProp>;
  lastTrackedModificationDateTime?: InputMaybe<TimeQueryProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxImportanceRank?: InputMaybe<NumberProp>;
  mergeToTicketId?: InputMaybe<NumberProp>;
  monitorID?: InputMaybe<NumberProp>;
  monitorTypeID?: InputMaybe<NumberProp>;
  nextActionDate?: InputMaybe<TimeQueryProp>;
  nextActionTime?: InputMaybe<NumberProp>;
  nextActionType?: InputMaybe<StringProp>;
  opportunityID?: InputMaybe<NumberProp>;
  order?: InputMaybe<SortTicketsArgs>;
  organizationalLevelAssociationID?: InputMaybe<NumberProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
  parentTicketId?: InputMaybe<NumberProp>;
  previousServiceThermometerRating?: InputMaybe<NumberProp>;
  priority?: InputMaybe<NumberProp>;
  problemTicketId?: InputMaybe<NumberProp>;
  projectID?: InputMaybe<NumberProp>;
  purchaseOrderNumber?: InputMaybe<StringProp>;
  queueID?: InputMaybe<NumberProp>;
  recipeInstanceId?: InputMaybe<NumberProp>;
  resolution?: InputMaybe<StringProp>;
  resolutionPlanDateTime?: InputMaybe<TimeQueryProp>;
  resolutionPlanDueDateTime?: InputMaybe<NumberProp>;
  resolvedDateTime?: InputMaybe<TimeQueryProp>;
  resolvedDueDateTime?: InputMaybe<TimeQueryProp>;
  resyncImportanceRank?: InputMaybe<BooleanProp>;
  rmaStatus?: InputMaybe<NumberProp>;
  rmaType?: InputMaybe<NumberProp>;
  rmmAlertID?: InputMaybe<StringProp>;
  search?: InputMaybe<Scalars['String']['input']>;
  serviceLevelAgreementHasBeenMet?: InputMaybe<NumberProp>;
  serviceLevelAgreementID?: InputMaybe<NumberProp>;
  serviceLevelAgreementPausedNextEventHours?: InputMaybe<NumberProp>;
  serviceThermometerTemperature?: InputMaybe<NumberProp>;
  source?: InputMaybe<NumberProp>;
  status?: InputMaybe<NumberProp>;
  subIssueType?: InputMaybe<NumberProp>;
  ticketCategory?: InputMaybe<NumberProp>;
  ticketNumber?: InputMaybe<StringProp>;
  ticketType?: InputMaybe<NumberProp>;
  title?: InputMaybe<StringProp>;
};


export type QueryFindAzureLicensePaginatedArgs = {
  azureUserId?: InputMaybe<StringProp>;
  id?: InputMaybe<StringProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortAzureLicensesArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  skuId?: InputMaybe<StringProp>;
  skuPartNumber?: InputMaybe<StringProp>;
};


export type QueryFindAzureTenantsPaginatedArgs = {
  coreCompanyId?: InputMaybe<NumberProp>;
  id?: InputMaybe<StringProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindAzureUsersPaginatedArgs = {
  accountEnabled?: InputMaybe<BooleanProp>;
  coreContactId?: InputMaybe<NumberProp>;
  displayName?: InputMaybe<StringProp>;
  givenName?: InputMaybe<StringProp>;
  id?: InputMaybe<StringProp>;
  isMfaRegistered?: InputMaybe<BooleanProp>;
  jobTitle?: InputMaybe<StringProp>;
  lastMfaCheckDate?: InputMaybe<TimeQueryProp>;
  lastSynced?: InputMaybe<TimeQueryProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mail?: InputMaybe<StringProp>;
  mobilePhone?: InputMaybe<StringProp>;
  officeLocation?: InputMaybe<StringProp>;
  order?: InputMaybe<SortAzureUsersArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  preferredLanguage?: InputMaybe<StringProp>;
  surname?: InputMaybe<StringProp>;
  tenantId?: InputMaybe<StringProp>;
  userPrincipalName?: InputMaybe<StringProp>;
};


export type QueryFindContractsAssignmentsPaginatedArgs = {
  coreContactId?: InputMaybe<NumberProp>;
  coreLocationId?: InputMaybe<NumberProp>;
  deviceId?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  instanceId?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindContractsContractsPaginatedArgs = {
  id?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindContractsInstancesPaginatedArgs = {
  contractId?: InputMaybe<NumberProp>;
  coreCompanyId?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindCoreCompaniesPaginatedArgs = {
  active?: InputMaybe<BooleanProp>;
  billingConfigured?: InputMaybe<BooleanProp>;
  defaultHourlyRate?: InputMaybe<NumberProp>;
  defaultTicketLevel?: InputMaybe<StringProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindCoreContactPaginatedArgs = {
  active?: InputMaybe<BooleanProp>;
  alternatePhone?: InputMaybe<StringProp>;
  coreCompanyId?: InputMaybe<NumberProp>;
  coreLocationId?: InputMaybe<NumberProp>;
  email?: InputMaybe<StringProp>;
  firstName?: InputMaybe<StringProp>;
  id?: InputMaybe<NumberProp>;
  lastName?: InputMaybe<StringProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mobilePhone?: InputMaybe<StringProp>;
  order?: InputMaybe<SortContactsArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<StringProp>;
  phoneExtension?: InputMaybe<StringProp>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFindCoreGroupPaginatedArgs = {
  description?: InputMaybe<StringProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindCoreGroupsMembersPaginatedArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindCoreTagLinkPaginatedArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  link?: InputMaybe<StringProp>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindCoreTagPaginatedArgs = {
  autotaskTicketId?: InputMaybe<NumberProp>;
  createdByService?: InputMaybe<StringProp>;
  id?: InputMaybe<StringProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindCoreUsersPaginatedArgs = {
  active?: InputMaybe<BooleanProp>;
  autotaskId?: InputMaybe<NumberProp>;
  autotaskLocationId?: InputMaybe<NumberProp>;
  bvoipExtention?: InputMaybe<NumberProp>;
  canContactCustomers?: InputMaybe<BooleanProp>;
  coreCompanyId?: InputMaybe<NumberProp>;
  email?: InputMaybe<StringProp>;
  firstName?: InputMaybe<StringProp>;
  id?: InputMaybe<StringProp>;
  isDispatchable?: InputMaybe<BooleanProp>;
  isOnCall?: InputMaybe<BooleanProp>;
  isRoleTier1?: InputMaybe<BooleanProp>;
  isRoleTier2?: InputMaybe<BooleanProp>;
  isRoleTier2FirstUp?: InputMaybe<BooleanProp>;
  isRoleTriage?: InputMaybe<BooleanProp>;
  isScheduler?: InputMaybe<BooleanProp>;
  isSherpaChampion?: InputMaybe<BooleanProp>;
  isTech?: InputMaybe<BooleanProp>;
  lastName?: InputMaybe<StringProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortCoreUsersArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  techTier?: InputMaybe<NumberProp>;
  theme?: InputMaybe<StringProp>;
  timwoodsId?: InputMaybe<StringProp>;
  useNewRank?: InputMaybe<BooleanProp>;
  workDayEnd?: InputMaybe<StringProp>;
  workDayStart?: InputMaybe<StringProp>;
};


export type QueryFindDattormmDevicesPaginatedArgs = {
  a64Bit?: InputMaybe<BooleanProp>;
  antivirusProduct?: InputMaybe<StringProp>;
  antivirusStatus?: InputMaybe<StringProp>;
  archived?: InputMaybe<BooleanProp>;
  cagVersion?: InputMaybe<StringProp>;
  category?: InputMaybe<StringProp>;
  creationDate?: InputMaybe<TimeQueryProp>;
  deleted?: InputMaybe<BooleanProp>;
  description?: InputMaybe<StringProp>;
  deviceClass?: InputMaybe<StringProp>;
  displayVersion?: InputMaybe<StringProp>;
  domain?: InputMaybe<StringProp>;
  extIpAddress?: InputMaybe<StringProp>;
  hostname?: InputMaybe<StringProp>;
  id?: InputMaybe<NumberProp>;
  intIpAddress?: InputMaybe<StringProp>;
  lastAuditDate?: InputMaybe<TimeQueryProp>;
  lastLoggedInUser?: InputMaybe<StringProp>;
  lastReboot?: InputMaybe<TimeQueryProp>;
  lastSeen?: InputMaybe<TimeQueryProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  online?: InputMaybe<BooleanProp>;
  operatingSystem?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
  patchStatus?: InputMaybe<StringProp>;
  patchesApprovedPending?: InputMaybe<NumberProp>;
  patchesInstalled?: InputMaybe<NumberProp>;
  patchesNotApproved?: InputMaybe<NumberProp>;
  portalUrl?: InputMaybe<StringProp>;
  rebootRequired?: InputMaybe<BooleanProp>;
  siteId?: InputMaybe<NumberProp>;
  siteName?: InputMaybe<StringProp>;
  siteUid?: InputMaybe<StringProp>;
  snmpEnabled?: InputMaybe<BooleanProp>;
  softwareStatus?: InputMaybe<StringProp>;
  suspended?: InputMaybe<BooleanProp>;
  syncId?: InputMaybe<StringProp>;
  type?: InputMaybe<StringProp>;
  udf1?: InputMaybe<StringProp>;
  udf2?: InputMaybe<StringProp>;
  udf3?: InputMaybe<StringProp>;
  udf4?: InputMaybe<StringProp>;
  udf5?: InputMaybe<StringProp>;
  udf6?: InputMaybe<StringProp>;
  udf7?: InputMaybe<StringProp>;
  udf8?: InputMaybe<StringProp>;
  udf9?: InputMaybe<StringProp>;
  udf10?: InputMaybe<StringProp>;
  udf11?: InputMaybe<StringProp>;
  udf12?: InputMaybe<StringProp>;
  udf13?: InputMaybe<StringProp>;
  udf14?: InputMaybe<StringProp>;
  udf15?: InputMaybe<StringProp>;
  udf16?: InputMaybe<StringProp>;
  udf17?: InputMaybe<StringProp>;
  udf18?: InputMaybe<StringProp>;
  udf19?: InputMaybe<StringProp>;
  udf20?: InputMaybe<StringProp>;
  udf21?: InputMaybe<StringProp>;
  udf22?: InputMaybe<StringProp>;
  udf23?: InputMaybe<StringProp>;
  udf24?: InputMaybe<StringProp>;
  udf25?: InputMaybe<StringProp>;
  udf26?: InputMaybe<StringProp>;
  udf27?: InputMaybe<StringProp>;
  udf28?: InputMaybe<StringProp>;
  udf29?: InputMaybe<StringProp>;
  udf30?: InputMaybe<StringProp>;
  uid?: InputMaybe<StringProp>;
  warrantyDate?: InputMaybe<StringProp>;
};


export type QueryFindDattormmPatchingPoliciesPaginatedArgs = {
  deviceId?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  lastRun?: InputMaybe<Scalars['Date']['input']>;
  lastSync?: InputMaybe<Scalars['Date']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
  schedule?: InputMaybe<StringProp>;
};


export type QueryFindDattormmSitesPaginatedArgs = {
  coreCompanyId?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindFeedbackInstancePaginatedArgs = {
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindFeedbackScorePaginatedArgs = {
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindFormsFormPaginatedArgs = {
  description?: InputMaybe<StringProp>;
  id?: InputMaybe<NumberProp>;
  identifier?: InputMaybe<StringProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  order?: InputMaybe<SortFormsArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindFormsInstancePaginatedArgs = {
  autotaskTicketId?: InputMaybe<NumberProp>;
  complete?: InputMaybe<BooleanProp>;
  formId?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortFormInstancesArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  recipeInstanceId?: InputMaybe<NumberProp>;
  todoId?: InputMaybe<NumberProp>;
};


export type QueryFindPhonesCallLegsPaginatedArgs = {
  callProvider?: InputMaybe<StringProp>;
  callProviderId?: InputMaybe<NumberProp>;
  coreContactId?: InputMaybe<NumberProp>;
  coreUserId?: InputMaybe<StringProp>;
  endTime?: InputMaybe<TimeQueryProp>;
  extention?: InputMaybe<StringProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortPhoneCallLegsArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  phonesCallId?: InputMaybe<NumberProp>;
  recordingLocalPath?: InputMaybe<StringProp>;
  startTime?: InputMaybe<TimeQueryProp>;
  transcription?: InputMaybe<StringProp>;
};


export type QueryFindPhonesCallsPaginatedArgs = {
  CoreCompany?: InputMaybe<GetCoreCompaniesSubfieldArgs>;
  atTicketId?: InputMaybe<NumberProp>;
  callProvider?: InputMaybe<StringProp>;
  callProviderId?: InputMaybe<NumberProp>;
  coreCompanyId?: InputMaybe<NumberProp>;
  coreContactId?: InputMaybe<NumberProp>;
  coreUserId?: InputMaybe<StringProp>;
  direction?: InputMaybe<StringProp>;
  endTime?: InputMaybe<TimeQueryProp>;
  extention?: InputMaybe<StringProp>;
  fromNumber?: InputMaybe<StringProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortPhoneCallsArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<TimeQueryProp>;
  toNumber?: InputMaybe<StringProp>;
};


export type QueryFindRecipeNodePaginatedArgs = {
  id?: InputMaybe<StringProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  recipeId?: InputMaybe<NumberProp>;
  title?: InputMaybe<StringProp>;
  type?: InputMaybe<StringProp>;
};


export type QueryFindRocketcyberAccountsPaginatedArgs = {
  hierarchy?: InputMaybe<StringProp>;
  id?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<StringProp>;
  type?: InputMaybe<StringProp>;
};


export type QueryFindRocketcyberAgentsPaginatedArgs = {
  customerId?: InputMaybe<NumberProp>;
  family?: InputMaybe<StringProp>;
  hostname?: InputMaybe<StringProp>;
  id?: InputMaybe<StringProp>;
  ipv4Address?: InputMaybe<StringProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  macAddress?: InputMaybe<StringProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
  platform?: InputMaybe<StringProp>;
};


export type QueryFindRocketcyberIncidentsPaginatedArgs = {
  accountId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<StringProp>;
  title?: InputMaybe<StringProp>;
};


export type QueryFindScheduleInstancePaginatedArgs = {
  atTicketId?: InputMaybe<NumberProp>;
  available?: InputMaybe<BooleanProp>;
  azureCalendarId?: InputMaybe<StringProp>;
  conflictDetected?: InputMaybe<BooleanProp>;
  coreGroupId?: InputMaybe<NumberProp>;
  coreUserId?: InputMaybe<StringProp>;
  description?: InputMaybe<StringProp>;
  endDateTime?: InputMaybe<TimeQueryProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  lockedDateTime?: InputMaybe<BooleanProp>;
  lockedUser?: InputMaybe<BooleanProp>;
  order?: InputMaybe<SortScheduleInstancesArgs>;
  overidePlan?: InputMaybe<BooleanProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
  scheduleInstanceDay?: InputMaybe<StringProp>;
  schedulePlanId?: InputMaybe<NumberProp>;
  schedulerLog?: InputMaybe<StringProp>;
  startDateTime?: InputMaybe<TimeQueryProp>;
  title?: InputMaybe<StringProp>;
};


export type QueryFindSchedulePlanPaginatedArgs = {
  atTicketId?: InputMaybe<NumberProp>;
  available?: InputMaybe<BooleanProp>;
  blockSizeInMinutes?: InputMaybe<NumberProp>;
  coreGroupId?: InputMaybe<NumberProp>;
  coreUserId?: InputMaybe<StringProp>;
  description?: InputMaybe<StringProp>;
  desiredUserCount?: InputMaybe<NumberProp>;
  end_hour?: InputMaybe<NumberProp>;
  end_minute?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringProp>;
  optimalBlockSizeInMinutes?: InputMaybe<NumberProp>;
  order?: InputMaybe<SortSchedulePlansArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  splitableIntoBlocks?: InputMaybe<BooleanProp>;
  startTimeDay_daysOfMonth?: InputMaybe<Array<Scalars['Int']['input']>>;
  startTimeDay_daysOfWeek?: InputMaybe<Array<Scalars['String']['input']>>;
  startTimeDay_monthsOfTheYear?: InputMaybe<Array<Scalars['String']['input']>>;
  startTimeDay_year?: InputMaybe<NumberProp>;
  start_hour?: InputMaybe<NumberProp>;
  start_minute?: InputMaybe<NumberProp>;
  timezone?: InputMaybe<StringProp>;
};


export type QueryFindTicketTimelinePaginatedArgs = {
  atTicketId?: InputMaybe<NumberProp>;
  azureEmailId?: InputMaybe<StringProp>;
  coreTimeclockId?: InputMaybe<NumberProp>;
  date?: InputMaybe<TimeQueryProp>;
  formInstanceId?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SortTicketTimelinesArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  phonesCallId?: InputMaybe<NumberProp>;
  search?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<StringProp>;
};


export type QueryFindTodosTodoPaginatedArgs = {
  autotaskTicketId?: InputMaybe<NumberProp>;
  blockedByTodoId?: InputMaybe<NumberProp>;
  complete?: InputMaybe<BooleanProp>;
  completeAt?: InputMaybe<TimeQueryProp>;
  completeByUser?: InputMaybe<StringProp>;
  createdAt?: InputMaybe<TimeQueryProp>;
  createdByModuleId?: InputMaybe<StringProp>;
  createdByModuleName?: InputMaybe<StringProp>;
  createdByUser?: InputMaybe<StringProp>;
  description?: InputMaybe<StringProp>;
  dueDate?: InputMaybe<TimeQueryProp>;
  estimatedMinutes?: InputMaybe<NumberProp>;
  formInstanceId?: InputMaybe<NumberProp>;
  id?: InputMaybe<NumberProp>;
  isOnsite?: InputMaybe<BooleanProp>;
  isOutboundCall?: InputMaybe<BooleanProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  mergeFromTicketId?: InputMaybe<NumberProp>;
  nextActionTime?: InputMaybe<NumberProp>;
  nextActionType?: InputMaybe<StringProp>;
  noteType?: InputMaybe<StringProp>;
  order?: InputMaybe<SortTodosArgs>;
  outboundCallNumber?: InputMaybe<NumberProp>;
  page?: InputMaybe<Scalars['Int']['input']>;
  recipeInstanceId?: InputMaybe<NumberProp>;
  response?: InputMaybe<StringProp>;
  search?: InputMaybe<Scalars['String']['input']>;
  ticketBlocking?: InputMaybe<BooleanProp>;
  title?: InputMaybe<StringProp>;
  tripStopId?: InputMaybe<NumberProp>;
  updatedAt?: InputMaybe<TimeQueryProp>;
};


export type QueryGetAiChatByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetAiChatMessagesByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetActionLogActionByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetAutotaskConfigurationItemsByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetAutotaskContactsByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetAutotaskTicketStatusesByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetAutotaskTicketsByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetAzureLicenseByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetAzureTenantsByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAzureUsersByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCompanyByDomainArgs = {
  domain: Scalars['String']['input'];
};


export type QueryGetContractsAssignmentsByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetContractsContractsByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetContractsInstancesByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCoreCompaniesByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCoreContactByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCoreGroupByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCoreGroupsMembersByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCoreTagByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetCoreTagLinkByIdArgs = {
  name: Scalars['String']['input'];
};


export type QueryGetCoreUsersByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetDattormmDevicesByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetDattormmPatchingPoliciesByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetDattormmSitesByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetFeedbackInstanceByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetFeedbackScoreByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetFormsFormByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetFormsInstanceByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetPhonesCallLegsByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetPhonesCallsByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetRecipeNodeByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetRocketcyberAccountsByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetRocketcyberAgentsByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetRocketcyberIncidentsByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetScheduleInstanceByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetSchedulePlanByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetTicketTimelineByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetTodosTodoByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QuerySignSecureGetUrlArgs = {
  key: Scalars['String']['input'];
};


export type QuerySignSecurePutUrlArgs = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
};


export type QueryTimeclockArgs = {
  id: Scalars['Float']['input'];
};


export type QueryTimeclocksArgs = {
  end?: InputMaybe<TimeQueryProp>;
  fromRole?: InputMaybe<StringProp>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isPayroll?: InputMaybe<BooleanProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<TimeQueryProp>;
  ticket?: InputMaybe<GetTicketsArgs>;
  ticketId?: InputMaybe<Scalars['Int']['input']>;
  todoId?: InputMaybe<Scalars['Int']['input']>;
  user?: InputMaybe<GetUsersArgs>;
};


export type QueryUserstatusesArgs = {
  hasDispatch?: InputMaybe<BooleanProp>;
  isDispatchable?: InputMaybe<BooleanProp>;
  isOnCall?: InputMaybe<BooleanProp>;
  isTimwoodsClockedIn?: InputMaybe<BooleanProp>;
  isWorking?: InputMaybe<BooleanProp>;
  isWorkingClose?: InputMaybe<BooleanProp>;
  isWorkingDispatched?: InputMaybe<BooleanProp>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<TimeQueryProp>;
  user?: InputMaybe<GetUsersArgs>;
};

export type RecipeNode = {
  __typename?: 'RecipeNode';
  data: Scalars['JSON']['output'];
  id: Scalars['String']['output'];
  lastEditedUser?: Maybe<Scalars['String']['output']>;
  positionX: Scalars['Float']['output'];
  positionY: Scalars['Float']['output'];
  recipeId: Scalars['Float']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type RocketcyberAccounts = {
  __typename?: 'RocketcyberAccounts';
  coreCompanyId?: Maybe<Scalars['Float']['output']>;
  emails?: Maybe<Scalars['String']['output']>;
  hierarchy: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  status: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type RocketcyberAgents = {
  __typename?: 'RocketcyberAgents';
  accountPath: Scalars['String']['output'];
  agentVersion: Scalars['String']['output'];
  architecture: Scalars['String']['output'];
  build: Scalars['String']['output'];
  connectivity: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  customerId: Scalars['Float']['output'];
  dattormmDeviceId?: Maybe<Scalars['Float']['output']>;
  edition: Scalars['String']['output'];
  family: Scalars['String']['output'];
  hostname: Scalars['String']['output'];
  id: Scalars['String']['output'];
  ipv4Address: Scalars['String']['output'];
  macAddress: Scalars['String']['output'];
  platform: Scalars['String']['output'];
  release: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type RocketcyberIncidents = {
  __typename?: 'RocketcyberIncidents';
  accountId: Scalars['Float']['output'];
  autotaskTicketId?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['Date']['output'];
  description: Scalars['String']['output'];
  eventCount: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  publishedAt?: Maybe<Scalars['Date']['output']>;
  remediation: Scalars['String']['output'];
  resolvedAt?: Maybe<Scalars['Date']['output']>;
  status: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ScheduleInstance = {
  __typename?: 'ScheduleInstance';
  AutotaskTickets?: Maybe<AutotaskTickets>;
  AzureCalendar?: Maybe<AzureCalendar>;
  CoreGroup?: Maybe<CoreGroup>;
  CoreUser?: Maybe<CoreUsers>;
  SchedulePlan?: Maybe<SchedulePlan>;
  atTicketId?: Maybe<Scalars['Float']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  azureCalendarId?: Maybe<Scalars['String']['output']>;
  conflictDetected?: Maybe<Scalars['Boolean']['output']>;
  coreGroupId?: Maybe<Scalars['Float']['output']>;
  coreUserId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lockedDateTime?: Maybe<Scalars['Boolean']['output']>;
  lockedUser?: Maybe<Scalars['Boolean']['output']>;
  overidePlan?: Maybe<Scalars['Boolean']['output']>;
  scheduleInstanceDay?: Maybe<Scalars['String']['output']>;
  schedulePlanId?: Maybe<Scalars['Float']['output']>;
  schedulerLog?: Maybe<Scalars['String']['output']>;
  startDateTime?: Maybe<Scalars['DateTimeIso']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SchedulePlan = {
  __typename?: 'SchedulePlan';
  AutotaskTickets?: Maybe<AutotaskTickets>;
  CoreGroup?: Maybe<CoreGroup>;
  CoreUser?: Maybe<CoreUsers>;
  ScheduleInstances?: Maybe<Array<ScheduleInstance>>;
  atTicketId?: Maybe<Scalars['Float']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  blockSizeInMinutes?: Maybe<Scalars['Float']['output']>;
  coreGroupId?: Maybe<Scalars['Float']['output']>;
  coreUserId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  desiredUserCount?: Maybe<Scalars['Float']['output']>;
  end_hour?: Maybe<Scalars['Float']['output']>;
  end_minute?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  optimalBlockSizeInMinutes?: Maybe<Scalars['Float']['output']>;
  splitableIntoBlocks?: Maybe<Scalars['Boolean']['output']>;
  startTimeDay_daysOfMonth?: Maybe<Array<Scalars['Int']['output']>>;
  startTimeDay_daysOfWeek?: Maybe<Array<Scalars['String']['output']>>;
  startTimeDay_monthsOfTheYear?: Maybe<Array<Scalars['String']['output']>>;
  startTimeDay_year?: Maybe<Scalars['Float']['output']>;
  start_hour?: Maybe<Scalars['Float']['output']>;
  start_minute?: Maybe<Scalars['Float']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type SortActionLogActionsArgs = {
  actionData?: InputMaybe<SortOrders>;
  actionTime?: InputMaybe<SortOrders>;
  azureEmailId?: InputMaybe<SortOrders>;
  coreUserId?: InputMaybe<SortOrders>;
  databaseAction?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  loggedFunction?: InputMaybe<SortOrders>;
  module?: InputMaybe<SortOrders>;
  moduleIdNumber?: InputMaybe<SortOrders>;
  moduleIdString?: InputMaybe<SortOrders>;
  originalData?: InputMaybe<SortOrders>;
  ticketId?: InputMaybe<SortOrders>;
  userActionId?: InputMaybe<SortOrders>;
};

export type SortAutotaskConfigurationItemssArgs = {
  apiVendorID?: InputMaybe<SortOrders>;
  companyID?: InputMaybe<SortOrders>;
  companyLocationID?: InputMaybe<SortOrders>;
  configurationItemCategoryID?: InputMaybe<SortOrders>;
  configurationItemType?: InputMaybe<SortOrders>;
  contactID?: InputMaybe<SortOrders>;
  contractID?: InputMaybe<SortOrders>;
  contractServiceBundleID?: InputMaybe<SortOrders>;
  contractServiceID?: InputMaybe<SortOrders>;
  createDate?: InputMaybe<SortOrders>;
  createdByPersonID?: InputMaybe<SortOrders>;
  dailyCost?: InputMaybe<SortOrders>;
  dattoAvailableKilobytes?: InputMaybe<SortOrders>;
  dattoDeviceMemoryMegabytes?: InputMaybe<SortOrders>;
  dattoDrivesErrors?: InputMaybe<SortOrders>;
  dattoHostname?: InputMaybe<SortOrders>;
  dattoInternalIP?: InputMaybe<SortOrders>;
  dattoKernelVersionID?: InputMaybe<SortOrders>;
  dattoLastCheckInDateTime?: InputMaybe<SortOrders>;
  dattoNICSpeedKilobitsPerSecond?: InputMaybe<SortOrders>;
  dattoNumberOfAgents?: InputMaybe<SortOrders>;
  dattoNumberOfDrives?: InputMaybe<SortOrders>;
  dattoNumberOfVolumes?: InputMaybe<SortOrders>;
  dattoOSVersionID?: InputMaybe<SortOrders>;
  dattoOffsiteUsedBytes?: InputMaybe<SortOrders>;
  dattoPercentageUsed?: InputMaybe<SortOrders>;
  dattoProtectedKilobytes?: InputMaybe<SortOrders>;
  dattoRemoteIP?: InputMaybe<SortOrders>;
  dattoSerialNumber?: InputMaybe<SortOrders>;
  dattoUptimeSeconds?: InputMaybe<SortOrders>;
  dattoUsedKilobytes?: InputMaybe<SortOrders>;
  dattoZFSVersionID?: InputMaybe<SortOrders>;
  deviceNetworkingID?: InputMaybe<SortOrders>;
  domain?: InputMaybe<SortOrders>;
  domainExpirationDateTime?: InputMaybe<SortOrders>;
  domainLastUpdatedDateTime?: InputMaybe<SortOrders>;
  domainRegistrarID?: InputMaybe<SortOrders>;
  domainRegistrationDateTime?: InputMaybe<SortOrders>;
  hourlyCost?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  impersonatorCreatorResourceID?: InputMaybe<SortOrders>;
  installDate?: InputMaybe<SortOrders>;
  installedByContactID?: InputMaybe<SortOrders>;
  installedByID?: InputMaybe<SortOrders>;
  isActive?: InputMaybe<SortOrders>;
  lastActivityPersonID?: InputMaybe<SortOrders>;
  lastActivityPersonType?: InputMaybe<SortOrders>;
  lastModifiedTime?: InputMaybe<SortOrders>;
  location?: InputMaybe<SortOrders>;
  monthlyCost?: InputMaybe<SortOrders>;
  notes?: InputMaybe<SortOrders>;
  numberOfUsers?: InputMaybe<SortOrders>;
  parentConfigurationItemID?: InputMaybe<SortOrders>;
  perUseCost?: InputMaybe<SortOrders>;
  productID?: InputMaybe<SortOrders>;
  referenceNumber?: InputMaybe<SortOrders>;
  referenceTitle?: InputMaybe<SortOrders>;
  rmmDeviceAuditAntivirusStatusID?: InputMaybe<SortOrders>;
  rmmDeviceAuditArchitectureID?: InputMaybe<SortOrders>;
  rmmDeviceAuditBackupStatusID?: InputMaybe<SortOrders>;
  rmmDeviceAuditDescription?: InputMaybe<SortOrders>;
  rmmDeviceAuditDeviceTypeID?: InputMaybe<SortOrders>;
  rmmDeviceAuditDisplayAdaptorID?: InputMaybe<SortOrders>;
  rmmDeviceAuditDomainID?: InputMaybe<SortOrders>;
  rmmDeviceAuditExternalIPAddress?: InputMaybe<SortOrders>;
  rmmDeviceAuditHostname?: InputMaybe<SortOrders>;
  rmmDeviceAuditIPAddress?: InputMaybe<SortOrders>;
  rmmDeviceAuditLastUser?: InputMaybe<SortOrders>;
  rmmDeviceAuditMacAddress?: InputMaybe<SortOrders>;
  rmmDeviceAuditManufacturerID?: InputMaybe<SortOrders>;
  rmmDeviceAuditMemoryBytes?: InputMaybe<SortOrders>;
  rmmDeviceAuditMissingPatchCount?: InputMaybe<SortOrders>;
  rmmDeviceAuditMobileNetworkOperatorID?: InputMaybe<SortOrders>;
  rmmDeviceAuditMobileNumber?: InputMaybe<SortOrders>;
  rmmDeviceAuditModelID?: InputMaybe<SortOrders>;
  rmmDeviceAuditMotherboardID?: InputMaybe<SortOrders>;
  rmmDeviceAuditOperatingSystem?: InputMaybe<SortOrders>;
  rmmDeviceAuditPatchStatusID?: InputMaybe<SortOrders>;
  rmmDeviceAuditProcessorID?: InputMaybe<SortOrders>;
  rmmDeviceAuditSNMPContact?: InputMaybe<SortOrders>;
  rmmDeviceAuditSNMPLocation?: InputMaybe<SortOrders>;
  rmmDeviceAuditSNMPName?: InputMaybe<SortOrders>;
  rmmDeviceAuditServicePackID?: InputMaybe<SortOrders>;
  rmmDeviceAuditSoftwareStatusID?: InputMaybe<SortOrders>;
  rmmDeviceAuditStorageBytes?: InputMaybe<SortOrders>;
  rmmDeviceID?: InputMaybe<SortOrders>;
  rmmDeviceUID?: InputMaybe<SortOrders>;
  rmmOpenAlertCount?: InputMaybe<SortOrders>;
  serialNumber?: InputMaybe<SortOrders>;
  serviceBundleID?: InputMaybe<SortOrders>;
  serviceID?: InputMaybe<SortOrders>;
  serviceLevelAgreementID?: InputMaybe<SortOrders>;
  setupFee?: InputMaybe<SortOrders>;
  sourceChargeID?: InputMaybe<SortOrders>;
  sourceChargeType?: InputMaybe<SortOrders>;
  sslCommonName?: InputMaybe<SortOrders>;
  sslIssuedBy?: InputMaybe<SortOrders>;
  sslLocation?: InputMaybe<SortOrders>;
  sslOrganization?: InputMaybe<SortOrders>;
  sslOrganizationUnit?: InputMaybe<SortOrders>;
  sslSerialNumber?: InputMaybe<SortOrders>;
  sslSignatureAlgorithm?: InputMaybe<SortOrders>;
  sslSource?: InputMaybe<SortOrders>;
  sslValidFromDateTime?: InputMaybe<SortOrders>;
  sslValidUntilDateTime?: InputMaybe<SortOrders>;
  userDefinedFields?: InputMaybe<SortOrders>;
  vendorID?: InputMaybe<SortOrders>;
  warrantyExpirationDate?: InputMaybe<SortOrders>;
};

export type SortAutotaskContactssArgs = {
  additionalAddressInformation?: InputMaybe<SortOrders>;
  addressLine?: InputMaybe<SortOrders>;
  addressLine1?: InputMaybe<SortOrders>;
  alternatePhone?: InputMaybe<SortOrders>;
  apiVendorID?: InputMaybe<SortOrders>;
  bulkEmailOptOutTime?: InputMaybe<SortOrders>;
  city?: InputMaybe<SortOrders>;
  companyID?: InputMaybe<SortOrders>;
  companyLocationID?: InputMaybe<SortOrders>;
  coreCompanyId?: InputMaybe<SortOrders>;
  coreContactId?: InputMaybe<SortOrders>;
  countryID?: InputMaybe<SortOrders>;
  createDate?: InputMaybe<SortOrders>;
  emailAddress?: InputMaybe<SortOrders>;
  emailAddress2?: InputMaybe<SortOrders>;
  emailAddress3?: InputMaybe<SortOrders>;
  extension?: InputMaybe<SortOrders>;
  externalID?: InputMaybe<SortOrders>;
  facebookUrl?: InputMaybe<SortOrders>;
  faxNumber?: InputMaybe<SortOrders>;
  firstName?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  impersonatorCreatorResourceID?: InputMaybe<SortOrders>;
  isActive?: InputMaybe<SortOrders>;
  isOptedOutFromBulkEmail?: InputMaybe<SortOrders>;
  lastActivityDate?: InputMaybe<SortOrders>;
  lastModifiedDate?: InputMaybe<SortOrders>;
  lastName?: InputMaybe<SortOrders>;
  linkedInUrl?: InputMaybe<SortOrders>;
  middleInitial?: InputMaybe<SortOrders>;
  mobilePhone?: InputMaybe<SortOrders>;
  namePrefix?: InputMaybe<SortOrders>;
  nameSuffix?: InputMaybe<SortOrders>;
  note?: InputMaybe<SortOrders>;
  phone?: InputMaybe<SortOrders>;
  primaryContact?: InputMaybe<SortOrders>;
  receivesEmailNotifications?: InputMaybe<SortOrders>;
  roomNumber?: InputMaybe<SortOrders>;
  solicitationOptOut?: InputMaybe<SortOrders>;
  solicitationOptOutTime?: InputMaybe<SortOrders>;
  state?: InputMaybe<SortOrders>;
  surveyOptOut?: InputMaybe<SortOrders>;
  title?: InputMaybe<SortOrders>;
  twitterUrl?: InputMaybe<SortOrders>;
  userDefinedFields?: InputMaybe<SortOrders>;
  zipCode?: InputMaybe<SortOrders>;
};

export type SortAutotaskTicketStatusessArgs = {
  id?: InputMaybe<SortOrders>;
  isActive?: InputMaybe<SortOrders>;
  isSystem?: InputMaybe<SortOrders>;
  name?: InputMaybe<SortOrders>;
  parentValue?: InputMaybe<SortOrders>;
  sortOrder?: InputMaybe<SortOrders>;
};

export type SortAzureLicensesArgs = {
  azureUserId?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  skuId?: InputMaybe<SortOrders>;
  skuPartNumber?: InputMaybe<SortOrders>;
};

export type SortAzureUsersArgs = {
  accountEnabled?: InputMaybe<SortOrders>;
  authenticationMethods?: InputMaybe<SortOrders>;
  businessPhones?: InputMaybe<SortOrders>;
  coreContactId?: InputMaybe<SortOrders>;
  displayName?: InputMaybe<SortOrders>;
  givenName?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  isMfaRegistered?: InputMaybe<SortOrders>;
  jobTitle?: InputMaybe<SortOrders>;
  lastMfaCheckDate?: InputMaybe<SortOrders>;
  lastSynced?: InputMaybe<SortOrders>;
  mail?: InputMaybe<SortOrders>;
  mobilePhone?: InputMaybe<SortOrders>;
  officeLocation?: InputMaybe<SortOrders>;
  preferredLanguage?: InputMaybe<SortOrders>;
  surname?: InputMaybe<SortOrders>;
  tenantId?: InputMaybe<SortOrders>;
  userPrincipalName?: InputMaybe<SortOrders>;
};

export type SortContactsArgs = {
  active?: InputMaybe<SortOrders>;
  alternatePhone?: InputMaybe<SortOrders>;
  coreCompanyId?: InputMaybe<SortOrders>;
  coreLocationId?: InputMaybe<SortOrders>;
  email?: InputMaybe<SortOrders>;
  firstName?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  lastName?: InputMaybe<SortOrders>;
  mobilePhone?: InputMaybe<SortOrders>;
  phone?: InputMaybe<SortOrders>;
  phoneExtension?: InputMaybe<SortOrders>;
};

export type SortCoreUsersArgs = {
  active?: InputMaybe<SortOrders>;
  autotaskId?: InputMaybe<SortOrders>;
  autotaskLocationId?: InputMaybe<SortOrders>;
  bvoipExtention?: InputMaybe<SortOrders>;
  canContactCustomers?: InputMaybe<SortOrders>;
  coreCompanyId?: InputMaybe<SortOrders>;
  email?: InputMaybe<SortOrders>;
  firstName?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  isDispatchable?: InputMaybe<SortOrders>;
  isOnCall?: InputMaybe<SortOrders>;
  isRoleTier1?: InputMaybe<SortOrders>;
  isRoleTier2?: InputMaybe<SortOrders>;
  isRoleTier2FirstUp?: InputMaybe<SortOrders>;
  isRoleTriage?: InputMaybe<SortOrders>;
  isScheduler?: InputMaybe<SortOrders>;
  isSherpaChampion?: InputMaybe<SortOrders>;
  isTech?: InputMaybe<SortOrders>;
  lastName?: InputMaybe<SortOrders>;
  techTier?: InputMaybe<SortOrders>;
  theme?: InputMaybe<SortOrders>;
  timwoodsId?: InputMaybe<SortOrders>;
  useNewRank?: InputMaybe<SortOrders>;
  workDayEnd?: InputMaybe<SortOrders>;
  workDayStart?: InputMaybe<SortOrders>;
};

export type SortFormInstancesArgs = {
  autotaskTicketId?: InputMaybe<SortOrders>;
  complete?: InputMaybe<SortOrders>;
  context?: InputMaybe<SortOrders>;
  formId?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  recipeInstanceId?: InputMaybe<SortOrders>;
  todoId?: InputMaybe<SortOrders>;
  values?: InputMaybe<SortOrders>;
};

export type SortFormsArgs = {
  description?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  identifier?: InputMaybe<SortOrders>;
  name?: InputMaybe<SortOrders>;
};

export enum SortOrders {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortPhoneCallLegsArgs = {
  callProvider?: InputMaybe<SortOrders>;
  callProviderId?: InputMaybe<SortOrders>;
  coreContactId?: InputMaybe<SortOrders>;
  coreUserId?: InputMaybe<SortOrders>;
  endTime?: InputMaybe<SortOrders>;
  extention?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  phonesCallId?: InputMaybe<SortOrders>;
  recordingLocalPath?: InputMaybe<SortOrders>;
  startTime?: InputMaybe<SortOrders>;
  transcription?: InputMaybe<SortOrders>;
};

export type SortPhoneCallsArgs = {
  atTicketId?: InputMaybe<SortOrders>;
  callProvider?: InputMaybe<SortOrders>;
  callProviderId?: InputMaybe<SortOrders>;
  coreCompanyId?: InputMaybe<SortOrders>;
  coreContactId?: InputMaybe<SortOrders>;
  coreUserId?: InputMaybe<SortOrders>;
  direction?: InputMaybe<SortOrders>;
  endTime?: InputMaybe<SortOrders>;
  extention?: InputMaybe<SortOrders>;
  fromNumber?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  startTime?: InputMaybe<SortOrders>;
  toNumber?: InputMaybe<SortOrders>;
};

export type SortScheduleInstancesArgs = {
  atTicketId?: InputMaybe<SortOrders>;
  available?: InputMaybe<SortOrders>;
  azureCalendarId?: InputMaybe<SortOrders>;
  conflictDetected?: InputMaybe<SortOrders>;
  coreGroupId?: InputMaybe<SortOrders>;
  coreUserId?: InputMaybe<SortOrders>;
  description?: InputMaybe<SortOrders>;
  endDateTime?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  lockedDateTime?: InputMaybe<SortOrders>;
  lockedUser?: InputMaybe<SortOrders>;
  overidePlan?: InputMaybe<SortOrders>;
  scheduleInstanceDay?: InputMaybe<SortOrders>;
  schedulePlanId?: InputMaybe<SortOrders>;
  schedulerLog?: InputMaybe<SortOrders>;
  startDateTime?: InputMaybe<SortOrders>;
  title?: InputMaybe<SortOrders>;
};

export type SortSchedulePlansArgs = {
  atTicketId?: InputMaybe<SortOrders>;
  available?: InputMaybe<SortOrders>;
  blockSizeInMinutes?: InputMaybe<SortOrders>;
  coreGroupId?: InputMaybe<SortOrders>;
  coreUserId?: InputMaybe<SortOrders>;
  description?: InputMaybe<SortOrders>;
  desiredUserCount?: InputMaybe<SortOrders>;
  end_hour?: InputMaybe<SortOrders>;
  end_minute?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  name?: InputMaybe<SortOrders>;
  optimalBlockSizeInMinutes?: InputMaybe<SortOrders>;
  splitableIntoBlocks?: InputMaybe<SortOrders>;
  startTimeDay_daysOfMonth?: InputMaybe<SortOrders>;
  startTimeDay_daysOfWeek?: InputMaybe<SortOrders>;
  startTimeDay_monthsOfTheYear?: InputMaybe<SortOrders>;
  startTimeDay_year?: InputMaybe<SortOrders>;
  start_hour?: InputMaybe<SortOrders>;
  start_minute?: InputMaybe<SortOrders>;
  timezone?: InputMaybe<SortOrders>;
};

export type SortTicketTimelinesArgs = {
  atTicketId?: InputMaybe<SortOrders>;
  azureEmailId?: InputMaybe<SortOrders>;
  coreTimeclockId?: InputMaybe<SortOrders>;
  date?: InputMaybe<SortOrders>;
  formInstanceId?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  phonesCallId?: InputMaybe<SortOrders>;
  type?: InputMaybe<SortOrders>;
};

export type SortTicketsArgs = {
  id?: InputMaybe<SortOrders>;
  nextActionDate?: InputMaybe<SortOrders>;
  ticketNumber?: InputMaybe<SortOrders>;
};

export type SortTodosArgs = {
  autotaskTicketId?: InputMaybe<SortOrders>;
  blockedByTodoId?: InputMaybe<SortOrders>;
  complete?: InputMaybe<SortOrders>;
  completeAt?: InputMaybe<SortOrders>;
  completeByUser?: InputMaybe<SortOrders>;
  createdAt?: InputMaybe<SortOrders>;
  createdByModuleId?: InputMaybe<SortOrders>;
  createdByModuleName?: InputMaybe<SortOrders>;
  createdByUser?: InputMaybe<SortOrders>;
  description?: InputMaybe<SortOrders>;
  dueDate?: InputMaybe<SortOrders>;
  estimatedMinutes?: InputMaybe<SortOrders>;
  formInstanceId?: InputMaybe<SortOrders>;
  id?: InputMaybe<SortOrders>;
  isOnsite?: InputMaybe<SortOrders>;
  isOutboundCall?: InputMaybe<SortOrders>;
  mergeFromTicketId?: InputMaybe<SortOrders>;
  nextActionTime?: InputMaybe<SortOrders>;
  nextActionType?: InputMaybe<SortOrders>;
  noteType?: InputMaybe<SortOrders>;
  outboundCallNumber?: InputMaybe<SortOrders>;
  recipeInstanceId?: InputMaybe<SortOrders>;
  response?: InputMaybe<SortOrders>;
  ticketBlocking?: InputMaybe<SortOrders>;
  title?: InputMaybe<SortOrders>;
  tripStopId?: InputMaybe<SortOrders>;
  updatedAt?: InputMaybe<SortOrders>;
};

export type StringProp = {
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  like?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<Scalars['String']['input']>;
  notlike?: InputMaybe<Scalars['String']['input']>;
};

export type TicketTimeline = {
  __typename?: 'TicketTimeline';
  AutotaskTickets?: Maybe<AutotaskTickets>;
  AzureEmail?: Maybe<AzureEmail>;
  CoreTimeclock?: Maybe<CoreTimeclock>;
  FormInstance?: Maybe<FormsInstance>;
  PhoneCall?: Maybe<PhonesCalls>;
  atTicketId?: Maybe<Scalars['Float']['output']>;
  azureEmailId?: Maybe<Scalars['String']['output']>;
  coreTimeclockId?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['DateTimeIso']['output']>;
  formInstanceId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  phonesCallId?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type TimeQueryProp = {
  eq?: InputMaybe<Scalars['Date']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<Scalars['Date']['input']>>;
  like?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  not?: InputMaybe<Scalars['Date']['input']>;
  notlike?: InputMaybe<Scalars['String']['input']>;
  notnull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TodosAssignee = {
  __typename?: 'TodosAssignee';
  CoreGroup?: Maybe<CoreGroup>;
  Todo?: Maybe<TodosTodo>;
  coreGroupId?: Maybe<Scalars['Float']['output']>;
  coreUserId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  todoId?: Maybe<Scalars['Float']['output']>;
  user?: Maybe<CoreUsers>;
};

export type TodosTodo = {
  __typename?: 'TodosTodo';
  CompleteByUser?: Maybe<CoreUsers>;
  CreatedByUser?: Maybe<CoreUsers>;
  FormInstance?: Maybe<FormsInstance>;
  assignees?: Maybe<Array<TodosAssignee>>;
  autotaskTicketId?: Maybe<Scalars['Float']['output']>;
  blockedByTodoId?: Maybe<Scalars['Float']['output']>;
  complete?: Maybe<Scalars['Boolean']['output']>;
  completeAt?: Maybe<Scalars['DateTimeIso']['output']>;
  completeByUser?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTimeIso']['output']>;
  createdByCoreUserId?: Maybe<Scalars['String']['output']>;
  createdByModuleId?: Maybe<Scalars['String']['output']>;
  createdByModuleName?: Maybe<Scalars['String']['output']>;
  createdByUser?: Maybe<Scalars['String']['output']>;
  createdDateTime: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['DateTimeIso']['output']>;
  estimatedMinutes?: Maybe<Scalars['Float']['output']>;
  formInstanceId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  isOnsite?: Maybe<Scalars['Boolean']['output']>;
  isOutboundCall?: Maybe<Scalars['Boolean']['output']>;
  mergeFromTicketId?: Maybe<Scalars['Float']['output']>;
  nextActionTime?: Maybe<Scalars['Float']['output']>;
  nextActionType?: Maybe<Scalars['String']['output']>;
  noteType?: Maybe<Scalars['String']['output']>;
  outboundCallNumber?: Maybe<Scalars['Float']['output']>;
  recipeInstanceId?: Maybe<Scalars['Float']['output']>;
  response?: Maybe<Scalars['String']['output']>;
  ticketBlocking?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tripStopId?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeIso']['output']>;
  updatedByCoreUserId?: Maybe<Scalars['String']['output']>;
  updatedDateTime: Scalars['DateTime']['output'];
};

export type TranscribeArgs = {
  format?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  prompt?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type ActivePayrollTimeclocksQueryVariables = Exact<{
  coreUserId: Scalars['String']['input'];
}>;


export type ActivePayrollTimeclocksQuery = { __typename?: 'Query', timeclocks: { __typename?: 'PaginatedTimeclocks', data?: Array<{ __typename?: 'CoreTimeclock', id?: number | null, start?: any | null, end?: any | null, isPayroll?: boolean | null, coreUserId?: string | null }> | null } };

export type ActiveUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveUsersQuery = { __typename?: 'Query', findCoreUsersPaginated: { __typename?: 'PaginatedCoreUsers', data?: Array<{ __typename?: 'CoreUsers', id?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null, isRoleTriage?: boolean | null, isRoleTier1?: boolean | null, isRoleTier2FirstUp?: boolean | null, isRoleTier2?: boolean | null, isScheduler?: boolean | null, isOnCall?: boolean | null, bvoipExtention?: number | null, activeScheduleInstances?: Array<{ __typename?: 'ScheduleInstance', id?: number | null, title?: string | null, available?: boolean | null, schedulePlanId?: number | null, SchedulePlan?: { __typename?: 'SchedulePlan', name?: string | null } | null, AzureCalendar?: { __typename?: 'AzureCalendar', subject?: string | null } | null }> | null, activeTimeclocks?: Array<{ __typename?: 'CoreTimeclock', id?: number | null, start?: any | null, ticketId?: number | null, todoId?: number | null, coreUserId?: string | null, isDispatchedTicket?: boolean | null, message?: string | null, fromRole?: string | null, isPayroll?: boolean | null, ticket?: { __typename?: 'AutotaskTickets', ticketNumber: string, title: string, id?: number | null, autotaskTicketStatus?: { __typename?: 'AutotaskTicketStatuses', name: string } | null } | null }> | null }> | null } };

export type GetAllPhoneCallsQueryVariables = Exact<{
  startTime: Scalars['Date']['input'];
  endTime: Scalars['Date']['input'];
}>;


export type GetAllPhoneCallsQuery = { __typename?: 'Query', findPhonesCallsPaginated: { __typename?: 'PaginatedPhonesCalls', data?: Array<{ __typename?: 'PhonesCalls', id?: number | null, createdDateTime: any, startTime?: any | null, endTime?: any | null, direction?: string | null, fromNumber?: string | null, toNumber?: string | null, extention?: string | null, CoreCompany?: { __typename?: 'CoreCompanies', id?: number | null, name: string } | null, CoreUser?: { __typename?: 'CoreUsers', id?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null } | null, AutotaskTickets?: { __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, title: string } | null, PhoneCallLegs?: Array<{ __typename?: 'PhonesCallLegs', id?: number | null, startTime?: any | null, phonesCallId?: number | null, endTime?: any | null, extention?: string | null, CoreUser?: { __typename?: 'CoreUsers', id?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null } | null }> | null }> | null } };

export type GetTimePunchesQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Date']['input']>;
  end?: InputMaybe<Scalars['Date']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetTimePunchesQuery = { __typename?: 'Query', timeclocks: { __typename?: 'PaginatedTimeclocks', data?: Array<{ __typename?: 'CoreTimeclock', start?: any | null, end?: any | null, coreUserId?: string | null, ticket?: { __typename?: 'AutotaskTickets', id?: number | null, archived: boolean, ticketNumber: string } | null }> | null } };

export type SearchCallsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['Date']['input']>;
  endTime?: InputMaybe<Scalars['Date']['input']>;
  order?: InputMaybe<SortPhoneCallsArgs>;
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SearchCallsQuery = { __typename?: 'Query', findPhonesCallsPaginated: { __typename?: 'PaginatedPhonesCalls', totalCount: number, page: number, data?: Array<{ __typename?: 'PhonesCalls', id?: number | null, startTime?: any | null, endTime?: any | null, direction?: string | null, fromNumber?: string | null, toNumber?: string | null, extention?: string | null, PhoneCallLegs?: Array<{ __typename?: 'PhonesCallLegs', createdDateTime: any, createdByCoreUserId?: string | null, updatedDateTime: any, updatedByCoreUserId?: string | null, id?: number | null, extention?: string | null, startTime?: any | null, endTime?: any | null, recordingLocalPath?: string | null, transcription?: string | null, callProvider?: string | null, callProviderId?: number | null, downloadUrl?: string | null, phonesCallId?: number | null, coreUserId?: string | null, coreContactId?: number | null }> | null, CoreCompany?: { __typename?: 'CoreCompanies', name: string, defaultTicketLevel: string, defaultHourlyRate: number, billingConfigured: boolean, active: boolean, id?: number | null, dattormmSites?: number | null, contractsInstances?: number | null } | null }> | null } };

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteContactMutation = { __typename?: 'Mutation', deleteCoreContact: boolean };

export type FindRecipeNodePaginatedQueryVariables = Exact<{ [key: string]: never; }>;


export type FindRecipeNodePaginatedQuery = { __typename?: 'Query', findRecipeNodePaginated: { __typename?: 'PaginatedRecipeNode', data?: Array<{ __typename?: 'RecipeNode', id: string, recipeId: number, data: any }> | null } };

export type GetFormsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFormsQuery = { __typename?: 'Query', findFormsFormPaginated: { __typename?: 'PaginatedFormsForm', data?: Array<{ __typename?: 'FormsForm', id?: number | null, identifier: string, FormFields?: Array<{ __typename?: 'FormsFormField', name: string, id?: number | null, type: string }> | null }> | null } };

export type GetTicketsForHealthQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTicketsForHealthQuery = { __typename?: 'Query', findAutotaskTicketsPaginated: { __typename?: 'PaginatedAutotaskTickets', data?: Array<{ __typename?: 'AutotaskTickets', createdDateTime: any, id?: number | null, title: string, queueID?: number | null, status: number, nextActionDate?: any | null, nextActionTime?: number | null, nextActionType?: string | null, archived: boolean, userDefinedFields?: any | null, ticketNumber: string, autotaskTicketQueue?: { __typename?: 'AutotaskTicketQueues', name: string, id: number } | null, autotaskTicketStatus?: { __typename?: 'AutotaskTicketStatuses', name: string, id?: number | null } | null }> | null } };

export type CreateScheduleInstanceMutationVariables = Exact<{
  available: Scalars['Boolean']['input'];
  startDateTime?: InputMaybe<Scalars['Date']['input']>;
  endDateTime?: InputMaybe<Scalars['Date']['input']>;
  scheduleInstanceDay?: InputMaybe<Scalars['String']['input']>;
  overidePlan?: InputMaybe<Scalars['Boolean']['input']>;
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateScheduleInstanceMutation = { __typename?: 'Mutation', createScheduleInstance: { __typename?: 'ScheduleInstance', id?: number | null, scheduleInstanceDay?: string | null, startDateTime?: any | null, endDateTime?: any | null, available?: boolean | null, overidePlan?: boolean | null, description?: string | null } };

export type DeleteScheduleInstanceMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteScheduleInstanceMutation = { __typename?: 'Mutation', deleteScheduleInstance: boolean };

export type GetScheduleInstanceQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetScheduleInstanceQuery = { __typename?: 'Query', getScheduleInstanceById: { __typename?: 'ScheduleInstance', id?: number | null, title?: string | null, scheduleInstanceDay?: string | null, startDateTime?: any | null, endDateTime?: any | null, available?: boolean | null, description?: string | null, overidePlan?: boolean | null, conflictDetected?: boolean | null, schedulerLog?: string | null, lockedDateTime?: boolean | null, lockedUser?: boolean | null, CoreUser?: { __typename?: 'CoreUsers', id?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null } | null, AutotaskTickets?: { __typename?: 'AutotaskTickets', title: string } | null, SchedulePlan?: { __typename?: 'SchedulePlan', name?: string | null, description?: string | null } | null, AzureCalendar?: { __typename?: 'AzureCalendar', id?: string | null, subject?: string | null, mailbox: string, start: any, end: any, webLink?: string | null, bodyPreview: string } | null } };

export type GetAllGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllGroupsQuery = { __typename?: 'Query', findCoreGroupPaginated: { __typename?: 'PaginatedCoreGroup', data?: Array<{ __typename?: 'CoreGroup', id?: number | null, name: string }> | null } };

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUsersQuery = { __typename?: 'Query', findCoreUsersPaginated: { __typename?: 'PaginatedCoreUsers', data?: Array<{ __typename?: 'CoreUsers', id?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null, groups?: Array<{ __typename?: 'CoreGroupsMembers', group: { __typename?: 'CoreGroup', id?: number | null, name: string, description?: string | null } }> | null }> | null } };

export type GetCoreUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCoreUsersQuery = { __typename?: 'Query', findCoreUsersPaginated: { __typename?: 'PaginatedCoreUsers', data?: Array<{ __typename?: 'CoreUsers', id?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null, CoreCompany?: { __typename?: 'CoreCompanies', name: string, active: boolean, isOwnCompany: boolean, id?: number | null } | null }> | null } };

export type GetScheduleInstancesQueryVariables = Exact<{
  users?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  startDateTime: Scalars['Date']['input'];
  endDateTime: Scalars['Date']['input'];
}>;


export type GetScheduleInstancesQuery = { __typename?: 'Query', findScheduleInstancePaginated: { __typename?: 'PaginatedScheduleInstance', data?: Array<{ __typename?: 'ScheduleInstance', id?: number | null, startDateTime?: any | null, endDateTime?: any | null, available?: boolean | null, coreUserId?: string | null, coreGroupId?: number | null, title?: string | null, schedulePlanId?: number | null, overidePlan?: boolean | null, azureCalendarId?: string | null, scheduleInstanceDay?: string | null, lockedDateTime?: boolean | null, lockedUser?: boolean | null, conflictDetected?: boolean | null, schedulerLog?: string | null, CoreUser?: { __typename?: 'CoreUsers', email?: string | null } | null, SchedulePlan?: { __typename?: 'SchedulePlan', name?: string | null } | null, AzureCalendar?: { __typename?: 'AzureCalendar', subject?: string | null, mailbox: string, start: any, end: any, webLink?: string | null } | null }> | null } };

export type UpdateScheduleInstanceMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  coreUserId?: InputMaybe<Scalars['String']['input']>;
  startDateTime?: InputMaybe<Scalars['Date']['input']>;
  endDateTime?: InputMaybe<Scalars['Date']['input']>;
}>;


export type UpdateScheduleInstanceMutation = { __typename?: 'Mutation', updateScheduleInstance: { __typename?: 'ScheduleInstance', id?: number | null, coreUserId?: string | null, startDateTime?: any | null, endDateTime?: any | null } };

export type GetTodosQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTodosQuery = { __typename?: 'Query', findTodosTodoPaginated: { __typename?: 'PaginatedTodosTodo', data?: Array<{ __typename?: 'TodosTodo', id?: number | null, title?: string | null, dueDate?: any | null, description?: string | null, response?: string | null, complete?: boolean | null, createdAt?: any | null, assignees?: Array<{ __typename?: 'TodosAssignee', user?: { __typename?: 'CoreUsers', firstName?: string | null, lastName?: string | null } | null, CoreGroup?: { __typename?: 'CoreGroup', name: string } | null }> | null, CreatedByUser?: { __typename?: 'CoreUsers', email?: string | null, firstName?: string | null, lastName?: string | null } | null, CompleteByUser?: { __typename?: 'CoreUsers', email?: string | null, firstName?: string | null, lastName?: string | null } | null }> | null } };

export type GetChildTicketsQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetChildTicketsQuery = { __typename?: 'Query', getAutotaskTicketsById: { __typename?: 'AutotaskTickets', id?: number | null, ChildTickets?: Array<{ __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, title: string }> | null } };

export type GetFormByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetFormByIdQuery = { __typename?: 'Query', getFormsInstanceById: { __typename?: 'FormsInstance', values?: any | null, Form?: { __typename?: 'FormsForm', id?: number | null, name: string, identifier: string, description?: string | null, FormFields?: Array<{ __typename?: 'FormsFormField', id?: number | null, formId?: number | null, type: string, name: string, label: string, config?: any | null }> | null } | null } };

export type SearchCoreUsersQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type SearchCoreUsersQuery = { __typename?: 'Query', findCoreUsersPaginated: { __typename?: 'PaginatedCoreUsers', data?: Array<{ __typename?: 'CoreUsers', id?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null }> | null } };

export type SearchCoreGroupsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type SearchCoreGroupsQuery = { __typename?: 'Query', findCoreGroupPaginated: { __typename?: 'PaginatedCoreGroup', data?: Array<{ __typename?: 'CoreGroup', id?: number | null, name: string, description?: string | null }> | null } };

export type TicketFieldsFragment = { __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, status: number, title: string };

export type GetParentAndChildInfoQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetParentAndChildInfoQuery = { __typename?: 'Query', getAutotaskTicketsById: { __typename?: 'AutotaskTickets', coreCompanyId?: number | null, ParentTicket?: { __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, status: number, title: string, ParentTicket?: { __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, status: number, title: string } | null, ChildTickets?: Array<{ __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, status: number, title: string }> | null } | null, ChildTickets?: Array<{ __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, status: number, title: string, ChildTickets?: Array<{ __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, status: number, title: string, ChildTickets?: Array<{ __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, status: number, title: string }> | null }> | null }> | null } };

export type SetParentOnTicketMutationVariables = Exact<{
  childId: Scalars['Int']['input'];
  parentId: Scalars['Int']['input'];
}>;


export type SetParentOnTicketMutation = { __typename?: 'Mutation', updateAutotaskTickets: { __typename?: 'AutotaskTickets', id?: number | null, ParentTicket?: { __typename?: 'AutotaskTickets', id?: number | null } | null } };

export type GetUsersScheduleQueryVariables = Exact<{
  coreUserId: Scalars['String']['input'];
  scheduleInstanceDay: Scalars['String']['input'];
}>;


export type GetUsersScheduleQuery = { __typename?: 'Query', findScheduleInstancePaginated: { __typename?: 'PaginatedScheduleInstance', data?: Array<{ __typename?: 'ScheduleInstance', id?: number | null, scheduleInstanceDay?: string | null, available?: boolean | null, title?: string | null, description?: string | null, startDateTime?: any | null, endDateTime?: any | null, coreUserId?: string | null, conflictDetected?: boolean | null, schedulePlanId?: number | null, SchedulePlan?: { __typename?: 'SchedulePlan', name?: string | null } | null, AzureCalendar?: { __typename?: 'AzureCalendar', subject?: string | null, bodyPreview: string } | null, AutotaskTickets?: { __typename?: 'AutotaskTickets', title: string, ticketNumber: string, id?: number | null } | null }> | null } };

export type FindTicketTimelinePaginatedQueryVariables = Exact<{
  ticketId: Scalars['Float']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type FindTicketTimelinePaginatedQuery = { __typename?: 'Query', findTicketTimelinePaginated: { __typename?: 'PaginatedTicketTimeline', totalCount: number, page: number, limit: number, data?: Array<{ __typename?: 'TicketTimeline', id?: number | null, type?: string | null, date?: any | null, PhoneCall?: { __typename?: 'PhonesCalls', startTime?: any | null, id?: number | null, PhoneCallLegs?: Array<{ __typename?: 'PhonesCallLegs', id?: number | null, transcription?: string | null }> | null } | null, AzureEmail?: { __typename?: 'AzureEmail', id?: string | null, subject: string, bodyMarkdown?: string | null, bodyHtml?: string | null, createdDateTime: any, AzureEmailParticipants?: Array<{ __typename?: 'AzureEmailParticipatnt', id?: number | null, emailId?: string | null, userRole?: string | null, emailAddress?: string | null, name?: string | null }> | null, AzureEmailAttachments?: Array<{ __typename?: 'AzureEmailAttachment', id?: string | null, name?: string | null, contentType?: string | null, contentId?: string | null, emailId?: string | null, contentLocation?: string | null, isInline?: boolean | null }> | null } | null, FormInstance?: { __typename?: 'FormsInstance', id?: number | null, autotaskTicketId?: number | null, todoId?: number | null, complete: boolean, updated_at?: any | null, created_at?: any | null, recipeInstanceId?: number | null, context?: any | null, values?: any | null, Form?: { __typename?: 'FormsForm', description?: string | null, identifier: string, name: string, id?: number | null, FormFields?: Array<{ __typename?: 'FormsFormField', id?: number | null, formId?: number | null, type: string, name: string, label: string, config?: any | null }> | null } | null } | null, CoreTimeclock?: { __typename?: 'CoreTimeclock', message?: string | null, id?: number | null, start?: any | null, end?: any | null, user?: { __typename?: 'CoreUsers', firstName?: string | null, lastName?: string | null, email?: string | null } | null } | null }> | null } };

export type GetPhonesCallsForTimelineQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetPhonesCallsForTimelineQuery = { __typename?: 'Query', getPhonesCallsById: { __typename?: 'PhonesCalls', id?: number | null, startTime?: any | null, endTime?: any | null, direction?: string | null, fromNumber?: string | null, toNumber?: string | null, extention?: string | null, PhoneCallLegs?: Array<{ __typename?: 'PhonesCallLegs', id?: number | null, extention?: string | null, startTime?: any | null, endTime?: any | null, transcription?: string | null, downloadUrl?: string | null, recordingLocalPath?: string | null, CoreUser?: { __typename?: 'CoreUsers', email?: string | null, firstName?: string | null, lastName?: string | null, id?: string | null } | null, Contact?: { __typename?: 'CoreContact', firstName?: string | null, lastName?: string | null, email?: string | null } | null }> | null, CoreUser?: { __typename?: 'CoreUsers', email?: string | null, firstName?: string | null, lastName?: string | null } | null, CoreCompany?: { __typename?: 'CoreCompanies', name: string, id?: number | null } | null, Contact?: { __typename?: 'CoreContact', firstName?: string | null, lastName?: string | null, email?: string | null, id?: number | null } | null } };

export type FindAutotaskTicketsPaginatedQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  coreCompanyId?: InputMaybe<Scalars['Float']['input']>;
}>;


export type FindAutotaskTicketsPaginatedQuery = { __typename?: 'Query', findAutotaskTicketsPaginated: { __typename?: 'PaginatedAutotaskTickets', data?: Array<{ __typename?: 'AutotaskTickets', id?: number | null, ticketNumber: string, title: string, parentTicketId?: number | null, coreCompanyId?: number | null }> | null } };

export const TicketFieldsFragmentDoc = gql`
    fragment TicketFields on AutotaskTickets {
  id
  ticketNumber
  status
  title
}
    `;
export const ActivePayrollTimeclocksDocument = gql`
    query ActivePayrollTimeclocks($coreUserId: String!) {
  timeclocks(
    user: {id: {eq: $coreUserId}}
    end: {null: true}
    isPayroll: {eq: true}
  ) {
    data {
      id
      start
      end
      isPayroll
      coreUserId
    }
  }
}
    `;

/**
 * __useActivePayrollTimeclocksQuery__
 *
 * To run a query within a React component, call `useActivePayrollTimeclocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivePayrollTimeclocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivePayrollTimeclocksQuery({
 *   variables: {
 *      coreUserId: // value for 'coreUserId'
 *   },
 * });
 */
export function useActivePayrollTimeclocksQuery(baseOptions: Apollo.QueryHookOptions<ActivePayrollTimeclocksQuery, ActivePayrollTimeclocksQueryVariables> & ({ variables: ActivePayrollTimeclocksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivePayrollTimeclocksQuery, ActivePayrollTimeclocksQueryVariables>(ActivePayrollTimeclocksDocument, options);
      }
export function useActivePayrollTimeclocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivePayrollTimeclocksQuery, ActivePayrollTimeclocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivePayrollTimeclocksQuery, ActivePayrollTimeclocksQueryVariables>(ActivePayrollTimeclocksDocument, options);
        }
export function useActivePayrollTimeclocksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ActivePayrollTimeclocksQuery, ActivePayrollTimeclocksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ActivePayrollTimeclocksQuery, ActivePayrollTimeclocksQueryVariables>(ActivePayrollTimeclocksDocument, options);
        }
export type ActivePayrollTimeclocksQueryHookResult = ReturnType<typeof useActivePayrollTimeclocksQuery>;
export type ActivePayrollTimeclocksLazyQueryHookResult = ReturnType<typeof useActivePayrollTimeclocksLazyQuery>;
export type ActivePayrollTimeclocksSuspenseQueryHookResult = ReturnType<typeof useActivePayrollTimeclocksSuspenseQuery>;
export type ActivePayrollTimeclocksQueryResult = Apollo.QueryResult<ActivePayrollTimeclocksQuery, ActivePayrollTimeclocksQueryVariables>;
export const ActiveUsersDocument = gql`
    query ActiveUsers {
  findCoreUsersPaginated(
    limit: 300
    coreCompanyId: {eq: 4}
    active: {eq: true}
    order: {firstName: ASC}
  ) {
    data {
      id
      email
      firstName
      lastName
      isRoleTriage
      isRoleTier1
      isRoleTier2FirstUp
      isRoleTier2
      isScheduler
      isOnCall
      bvoipExtention
      activeScheduleInstances {
        id
        title
        SchedulePlan {
          name
        }
        AzureCalendar {
          subject
        }
        available
        schedulePlanId
      }
      activeTimeclocks {
        id
        start
        ticketId
        todoId
        coreUserId
        isDispatchedTicket
        message
        fromRole
        ticket {
          ticketNumber
          title
          id
          autotaskTicketStatus {
            name
          }
        }
        isPayroll
      }
    }
  }
}
    `;

/**
 * __useActiveUsersQuery__
 *
 * To run a query within a React component, call `useActiveUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveUsersQuery(baseOptions?: Apollo.QueryHookOptions<ActiveUsersQuery, ActiveUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveUsersQuery, ActiveUsersQueryVariables>(ActiveUsersDocument, options);
      }
export function useActiveUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveUsersQuery, ActiveUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveUsersQuery, ActiveUsersQueryVariables>(ActiveUsersDocument, options);
        }
export function useActiveUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ActiveUsersQuery, ActiveUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ActiveUsersQuery, ActiveUsersQueryVariables>(ActiveUsersDocument, options);
        }
export type ActiveUsersQueryHookResult = ReturnType<typeof useActiveUsersQuery>;
export type ActiveUsersLazyQueryHookResult = ReturnType<typeof useActiveUsersLazyQuery>;
export type ActiveUsersSuspenseQueryHookResult = ReturnType<typeof useActiveUsersSuspenseQuery>;
export type ActiveUsersQueryResult = Apollo.QueryResult<ActiveUsersQuery, ActiveUsersQueryVariables>;
export const GetAllPhoneCallsDocument = gql`
    query GetAllPhoneCalls($startTime: Date!, $endTime: Date!) {
  findPhonesCallsPaginated(
    limit: 100000
    startTime: {gte: $startTime, lte: $endTime}
  ) {
    data {
      id
      createdDateTime
      startTime
      endTime
      direction
      fromNumber
      toNumber
      extention
      CoreCompany {
        id
        name
      }
      CoreUser {
        id
        email
        firstName
        lastName
      }
      AutotaskTickets {
        id
        ticketNumber
        title
      }
      PhoneCallLegs {
        id
        startTime
        phonesCallId
        endTime
        extention
        CoreUser {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllPhoneCallsQuery__
 *
 * To run a query within a React component, call `useGetAllPhoneCallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPhoneCallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPhoneCallsQuery({
 *   variables: {
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useGetAllPhoneCallsQuery(baseOptions: Apollo.QueryHookOptions<GetAllPhoneCallsQuery, GetAllPhoneCallsQueryVariables> & ({ variables: GetAllPhoneCallsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPhoneCallsQuery, GetAllPhoneCallsQueryVariables>(GetAllPhoneCallsDocument, options);
      }
export function useGetAllPhoneCallsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPhoneCallsQuery, GetAllPhoneCallsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPhoneCallsQuery, GetAllPhoneCallsQueryVariables>(GetAllPhoneCallsDocument, options);
        }
export function useGetAllPhoneCallsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllPhoneCallsQuery, GetAllPhoneCallsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllPhoneCallsQuery, GetAllPhoneCallsQueryVariables>(GetAllPhoneCallsDocument, options);
        }
export type GetAllPhoneCallsQueryHookResult = ReturnType<typeof useGetAllPhoneCallsQuery>;
export type GetAllPhoneCallsLazyQueryHookResult = ReturnType<typeof useGetAllPhoneCallsLazyQuery>;
export type GetAllPhoneCallsSuspenseQueryHookResult = ReturnType<typeof useGetAllPhoneCallsSuspenseQuery>;
export type GetAllPhoneCallsQueryResult = Apollo.QueryResult<GetAllPhoneCallsQuery, GetAllPhoneCallsQueryVariables>;
export const GetTimePunchesDocument = gql`
    query GetTimePunches($start: Date, $end: Date, $coreUserId: String) {
  timeclocks(
    start: {lte: $start}
    end: {gte: $end}
    user: {id: {eq: $coreUserId}}
  ) {
    data {
      start
      end
      coreUserId
      ticket {
        id
        archived
        ticketNumber
      }
    }
  }
}
    `;

/**
 * __useGetTimePunchesQuery__
 *
 * To run a query within a React component, call `useGetTimePunchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimePunchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimePunchesQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      coreUserId: // value for 'coreUserId'
 *   },
 * });
 */
export function useGetTimePunchesQuery(baseOptions?: Apollo.QueryHookOptions<GetTimePunchesQuery, GetTimePunchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimePunchesQuery, GetTimePunchesQueryVariables>(GetTimePunchesDocument, options);
      }
export function useGetTimePunchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimePunchesQuery, GetTimePunchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimePunchesQuery, GetTimePunchesQueryVariables>(GetTimePunchesDocument, options);
        }
export function useGetTimePunchesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTimePunchesQuery, GetTimePunchesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTimePunchesQuery, GetTimePunchesQueryVariables>(GetTimePunchesDocument, options);
        }
export type GetTimePunchesQueryHookResult = ReturnType<typeof useGetTimePunchesQuery>;
export type GetTimePunchesLazyQueryHookResult = ReturnType<typeof useGetTimePunchesLazyQuery>;
export type GetTimePunchesSuspenseQueryHookResult = ReturnType<typeof useGetTimePunchesSuspenseQuery>;
export type GetTimePunchesQueryResult = Apollo.QueryResult<GetTimePunchesQuery, GetTimePunchesQueryVariables>;
export const SearchCallsDocument = gql`
    query SearchCalls($search: String, $startTime: Date, $endTime: Date, $order: SortPhoneCallsArgs, $page: Int, $limit: Int) {
  findPhonesCallsPaginated(
    search: $search
    page: $page
    limit: $limit
    startTime: {gte: $startTime}
    endTime: {lte: $endTime}
    order: $order
  ) {
    data {
      id
      PhoneCallLegs {
        createdDateTime
        createdByCoreUserId
        updatedDateTime
        updatedByCoreUserId
        id
        extention
        startTime
        endTime
        recordingLocalPath
        transcription
        callProvider
        callProviderId
        downloadUrl
        phonesCallId
        coreUserId
        coreContactId
      }
      startTime
      endTime
      direction
      fromNumber
      toNumber
      extention
      CoreCompany {
        name
        defaultTicketLevel
        defaultHourlyRate
        billingConfigured
        active
        id
        dattormmSites
        contractsInstances
      }
    }
    totalCount
    page
  }
}
    `;

/**
 * __useSearchCallsQuery__
 *
 * To run a query within a React component, call `useSearchCallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCallsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      order: // value for 'order'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchCallsQuery(baseOptions?: Apollo.QueryHookOptions<SearchCallsQuery, SearchCallsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCallsQuery, SearchCallsQueryVariables>(SearchCallsDocument, options);
      }
export function useSearchCallsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCallsQuery, SearchCallsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCallsQuery, SearchCallsQueryVariables>(SearchCallsDocument, options);
        }
export function useSearchCallsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchCallsQuery, SearchCallsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchCallsQuery, SearchCallsQueryVariables>(SearchCallsDocument, options);
        }
export type SearchCallsQueryHookResult = ReturnType<typeof useSearchCallsQuery>;
export type SearchCallsLazyQueryHookResult = ReturnType<typeof useSearchCallsLazyQuery>;
export type SearchCallsSuspenseQueryHookResult = ReturnType<typeof useSearchCallsSuspenseQuery>;
export type SearchCallsQueryResult = Apollo.QueryResult<SearchCallsQuery, SearchCallsQueryVariables>;
export const DeleteContactDocument = gql`
    mutation DeleteContact($id: Int!) {
  deleteCoreContact(id: $id)
}
    `;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, options);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const FindRecipeNodePaginatedDocument = gql`
    query FindRecipeNodePaginated {
  findRecipeNodePaginated(type: {eq: "start"}) {
    data {
      id
      recipeId
      data
    }
  }
}
    `;

/**
 * __useFindRecipeNodePaginatedQuery__
 *
 * To run a query within a React component, call `useFindRecipeNodePaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRecipeNodePaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRecipeNodePaginatedQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindRecipeNodePaginatedQuery(baseOptions?: Apollo.QueryHookOptions<FindRecipeNodePaginatedQuery, FindRecipeNodePaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindRecipeNodePaginatedQuery, FindRecipeNodePaginatedQueryVariables>(FindRecipeNodePaginatedDocument, options);
      }
export function useFindRecipeNodePaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindRecipeNodePaginatedQuery, FindRecipeNodePaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindRecipeNodePaginatedQuery, FindRecipeNodePaginatedQueryVariables>(FindRecipeNodePaginatedDocument, options);
        }
export function useFindRecipeNodePaginatedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindRecipeNodePaginatedQuery, FindRecipeNodePaginatedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindRecipeNodePaginatedQuery, FindRecipeNodePaginatedQueryVariables>(FindRecipeNodePaginatedDocument, options);
        }
export type FindRecipeNodePaginatedQueryHookResult = ReturnType<typeof useFindRecipeNodePaginatedQuery>;
export type FindRecipeNodePaginatedLazyQueryHookResult = ReturnType<typeof useFindRecipeNodePaginatedLazyQuery>;
export type FindRecipeNodePaginatedSuspenseQueryHookResult = ReturnType<typeof useFindRecipeNodePaginatedSuspenseQuery>;
export type FindRecipeNodePaginatedQueryResult = Apollo.QueryResult<FindRecipeNodePaginatedQuery, FindRecipeNodePaginatedQueryVariables>;
export const GetFormsDocument = gql`
    query GetForms {
  findFormsFormPaginated(limit: 1000) {
    data {
      id
      identifier
      FormFields {
        name
        id
        type
      }
    }
  }
}
    `;

/**
 * __useGetFormsQuery__
 *
 * To run a query within a React component, call `useGetFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFormsQuery(baseOptions?: Apollo.QueryHookOptions<GetFormsQuery, GetFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormsQuery, GetFormsQueryVariables>(GetFormsDocument, options);
      }
export function useGetFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormsQuery, GetFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormsQuery, GetFormsQueryVariables>(GetFormsDocument, options);
        }
export function useGetFormsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetFormsQuery, GetFormsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFormsQuery, GetFormsQueryVariables>(GetFormsDocument, options);
        }
export type GetFormsQueryHookResult = ReturnType<typeof useGetFormsQuery>;
export type GetFormsLazyQueryHookResult = ReturnType<typeof useGetFormsLazyQuery>;
export type GetFormsSuspenseQueryHookResult = ReturnType<typeof useGetFormsSuspenseQuery>;
export type GetFormsQueryResult = Apollo.QueryResult<GetFormsQuery, GetFormsQueryVariables>;
export const GetTicketsForHealthDocument = gql`
    query GetTicketsForHealth {
  findAutotaskTicketsPaginated(status: {not: 5}, limit: 10000) {
    data {
      createdDateTime
      id
      title
      queueID
      status
      nextActionDate
      nextActionTime
      nextActionType
      archived
      userDefinedFields
      autotaskTicketQueue {
        name
        id
      }
      ticketNumber
      autotaskTicketStatus {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useGetTicketsForHealthQuery__
 *
 * To run a query within a React component, call `useGetTicketsForHealthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketsForHealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketsForHealthQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTicketsForHealthQuery(baseOptions?: Apollo.QueryHookOptions<GetTicketsForHealthQuery, GetTicketsForHealthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTicketsForHealthQuery, GetTicketsForHealthQueryVariables>(GetTicketsForHealthDocument, options);
      }
export function useGetTicketsForHealthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTicketsForHealthQuery, GetTicketsForHealthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTicketsForHealthQuery, GetTicketsForHealthQueryVariables>(GetTicketsForHealthDocument, options);
        }
export function useGetTicketsForHealthSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTicketsForHealthQuery, GetTicketsForHealthQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTicketsForHealthQuery, GetTicketsForHealthQueryVariables>(GetTicketsForHealthDocument, options);
        }
export type GetTicketsForHealthQueryHookResult = ReturnType<typeof useGetTicketsForHealthQuery>;
export type GetTicketsForHealthLazyQueryHookResult = ReturnType<typeof useGetTicketsForHealthLazyQuery>;
export type GetTicketsForHealthSuspenseQueryHookResult = ReturnType<typeof useGetTicketsForHealthSuspenseQuery>;
export type GetTicketsForHealthQueryResult = Apollo.QueryResult<GetTicketsForHealthQuery, GetTicketsForHealthQueryVariables>;
export const CreateScheduleInstanceDocument = gql`
    mutation CreateScheduleInstance($available: Boolean!, $startDateTime: Date, $endDateTime: Date, $scheduleInstanceDay: String, $overidePlan: Boolean, $coreUserId: String, $title: String, $description: String) {
  createScheduleInstance(
    available: $available
    startDateTime: $startDateTime
    endDateTime: $endDateTime
    scheduleInstanceDay: $scheduleInstanceDay
    overidePlan: $overidePlan
    coreUserId: $coreUserId
    title: $title
    description: $description
  ) {
    id
    scheduleInstanceDay
    startDateTime
    endDateTime
    available
    overidePlan
    description
  }
}
    `;
export type CreateScheduleInstanceMutationFn = Apollo.MutationFunction<CreateScheduleInstanceMutation, CreateScheduleInstanceMutationVariables>;

/**
 * __useCreateScheduleInstanceMutation__
 *
 * To run a mutation, you first call `useCreateScheduleInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleInstanceMutation, { data, loading, error }] = useCreateScheduleInstanceMutation({
 *   variables: {
 *      available: // value for 'available'
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      scheduleInstanceDay: // value for 'scheduleInstanceDay'
 *      overidePlan: // value for 'overidePlan'
 *      coreUserId: // value for 'coreUserId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateScheduleInstanceMutation(baseOptions?: Apollo.MutationHookOptions<CreateScheduleInstanceMutation, CreateScheduleInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScheduleInstanceMutation, CreateScheduleInstanceMutationVariables>(CreateScheduleInstanceDocument, options);
      }
export type CreateScheduleInstanceMutationHookResult = ReturnType<typeof useCreateScheduleInstanceMutation>;
export type CreateScheduleInstanceMutationResult = Apollo.MutationResult<CreateScheduleInstanceMutation>;
export type CreateScheduleInstanceMutationOptions = Apollo.BaseMutationOptions<CreateScheduleInstanceMutation, CreateScheduleInstanceMutationVariables>;
export const DeleteScheduleInstanceDocument = gql`
    mutation DeleteScheduleInstance($id: Int!) {
  deleteScheduleInstance(id: $id)
}
    `;
export type DeleteScheduleInstanceMutationFn = Apollo.MutationFunction<DeleteScheduleInstanceMutation, DeleteScheduleInstanceMutationVariables>;

/**
 * __useDeleteScheduleInstanceMutation__
 *
 * To run a mutation, you first call `useDeleteScheduleInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduleInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduleInstanceMutation, { data, loading, error }] = useDeleteScheduleInstanceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScheduleInstanceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScheduleInstanceMutation, DeleteScheduleInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScheduleInstanceMutation, DeleteScheduleInstanceMutationVariables>(DeleteScheduleInstanceDocument, options);
      }
export type DeleteScheduleInstanceMutationHookResult = ReturnType<typeof useDeleteScheduleInstanceMutation>;
export type DeleteScheduleInstanceMutationResult = Apollo.MutationResult<DeleteScheduleInstanceMutation>;
export type DeleteScheduleInstanceMutationOptions = Apollo.BaseMutationOptions<DeleteScheduleInstanceMutation, DeleteScheduleInstanceMutationVariables>;
export const GetScheduleInstanceDocument = gql`
    query GetScheduleInstance($id: Int!) {
  getScheduleInstanceById(id: $id) {
    id
    title
    scheduleInstanceDay
    startDateTime
    endDateTime
    available
    description
    overidePlan
    conflictDetected
    schedulerLog
    lockedDateTime
    lockedUser
    CoreUser {
      id
      email
      firstName
      lastName
    }
    AutotaskTickets {
      title
    }
    SchedulePlan {
      name
      description
    }
    AzureCalendar {
      id
      subject
      mailbox
      start
      end
      webLink
      bodyPreview
    }
  }
}
    `;

/**
 * __useGetScheduleInstanceQuery__
 *
 * To run a query within a React component, call `useGetScheduleInstanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduleInstanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduleInstanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetScheduleInstanceQuery(baseOptions: Apollo.QueryHookOptions<GetScheduleInstanceQuery, GetScheduleInstanceQueryVariables> & ({ variables: GetScheduleInstanceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduleInstanceQuery, GetScheduleInstanceQueryVariables>(GetScheduleInstanceDocument, options);
      }
export function useGetScheduleInstanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduleInstanceQuery, GetScheduleInstanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduleInstanceQuery, GetScheduleInstanceQueryVariables>(GetScheduleInstanceDocument, options);
        }
export function useGetScheduleInstanceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetScheduleInstanceQuery, GetScheduleInstanceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetScheduleInstanceQuery, GetScheduleInstanceQueryVariables>(GetScheduleInstanceDocument, options);
        }
export type GetScheduleInstanceQueryHookResult = ReturnType<typeof useGetScheduleInstanceQuery>;
export type GetScheduleInstanceLazyQueryHookResult = ReturnType<typeof useGetScheduleInstanceLazyQuery>;
export type GetScheduleInstanceSuspenseQueryHookResult = ReturnType<typeof useGetScheduleInstanceSuspenseQuery>;
export type GetScheduleInstanceQueryResult = Apollo.QueryResult<GetScheduleInstanceQuery, GetScheduleInstanceQueryVariables>;
export const GetAllGroupsDocument = gql`
    query GetAllGroups {
  findCoreGroupPaginated {
    data {
      id
      name
    }
  }
}
    `;

/**
 * __useGetAllGroupsQuery__
 *
 * To run a query within a React component, call `useGetAllGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllGroupsQuery, GetAllGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllGroupsQuery, GetAllGroupsQueryVariables>(GetAllGroupsDocument, options);
      }
export function useGetAllGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllGroupsQuery, GetAllGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllGroupsQuery, GetAllGroupsQueryVariables>(GetAllGroupsDocument, options);
        }
export function useGetAllGroupsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllGroupsQuery, GetAllGroupsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllGroupsQuery, GetAllGroupsQueryVariables>(GetAllGroupsDocument, options);
        }
export type GetAllGroupsQueryHookResult = ReturnType<typeof useGetAllGroupsQuery>;
export type GetAllGroupsLazyQueryHookResult = ReturnType<typeof useGetAllGroupsLazyQuery>;
export type GetAllGroupsSuspenseQueryHookResult = ReturnType<typeof useGetAllGroupsSuspenseQuery>;
export type GetAllGroupsQueryResult = Apollo.QueryResult<GetAllGroupsQuery, GetAllGroupsQueryVariables>;
export const GetAllUsersDocument = gql`
    query GetAllUsers {
  findCoreUsersPaginated(
    limit: 1000
    email: {like: "coulee.tech"}
    active: {eq: true}
  ) {
    data {
      id
      email
      firstName
      lastName
      groups {
        group {
          id
          name
          description
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
      }
export function useGetAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export function useGetAllUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
        }
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersSuspenseQueryHookResult = ReturnType<typeof useGetAllUsersSuspenseQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<GetAllUsersQuery, GetAllUsersQueryVariables>;
export const GetCoreUsersDocument = gql`
    query GetCoreUsers {
  findCoreUsersPaginated(active: {eq: true}, coreCompanyId: {eq: 4}) {
    data {
      id
      email
      firstName
      lastName
      CoreCompany {
        name
        active
        isOwnCompany
        id
      }
    }
  }
}
    `;

/**
 * __useGetCoreUsersQuery__
 *
 * To run a query within a React component, call `useGetCoreUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoreUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoreUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCoreUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetCoreUsersQuery, GetCoreUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCoreUsersQuery, GetCoreUsersQueryVariables>(GetCoreUsersDocument, options);
      }
export function useGetCoreUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoreUsersQuery, GetCoreUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCoreUsersQuery, GetCoreUsersQueryVariables>(GetCoreUsersDocument, options);
        }
export function useGetCoreUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCoreUsersQuery, GetCoreUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCoreUsersQuery, GetCoreUsersQueryVariables>(GetCoreUsersDocument, options);
        }
export type GetCoreUsersQueryHookResult = ReturnType<typeof useGetCoreUsersQuery>;
export type GetCoreUsersLazyQueryHookResult = ReturnType<typeof useGetCoreUsersLazyQuery>;
export type GetCoreUsersSuspenseQueryHookResult = ReturnType<typeof useGetCoreUsersSuspenseQuery>;
export type GetCoreUsersQueryResult = Apollo.QueryResult<GetCoreUsersQuery, GetCoreUsersQueryVariables>;
export const GetScheduleInstancesDocument = gql`
    query GetScheduleInstances($users: [String!], $startDateTime: Date!, $endDateTime: Date!) {
  findScheduleInstancePaginated(
    coreUserId: {in: $users}
    startDateTime: {lte: $endDateTime}
    endDateTime: {gte: $startDateTime}
    limit: 10000
  ) {
    data {
      id
      startDateTime
      endDateTime
      available
      coreUserId
      coreGroupId
      title
      schedulePlanId
      overidePlan
      azureCalendarId
      scheduleInstanceDay
      lockedDateTime
      lockedUser
      CoreUser {
        email
      }
      conflictDetected
      overidePlan
      schedulerLog
      SchedulePlan {
        name
      }
      AzureCalendar {
        subject
        mailbox
        start
        end
        webLink
      }
    }
  }
}
    `;

/**
 * __useGetScheduleInstancesQuery__
 *
 * To run a query within a React component, call `useGetScheduleInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduleInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduleInstancesQuery({
 *   variables: {
 *      users: // value for 'users'
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *   },
 * });
 */
export function useGetScheduleInstancesQuery(baseOptions: Apollo.QueryHookOptions<GetScheduleInstancesQuery, GetScheduleInstancesQueryVariables> & ({ variables: GetScheduleInstancesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScheduleInstancesQuery, GetScheduleInstancesQueryVariables>(GetScheduleInstancesDocument, options);
      }
export function useGetScheduleInstancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScheduleInstancesQuery, GetScheduleInstancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScheduleInstancesQuery, GetScheduleInstancesQueryVariables>(GetScheduleInstancesDocument, options);
        }
export function useGetScheduleInstancesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetScheduleInstancesQuery, GetScheduleInstancesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetScheduleInstancesQuery, GetScheduleInstancesQueryVariables>(GetScheduleInstancesDocument, options);
        }
export type GetScheduleInstancesQueryHookResult = ReturnType<typeof useGetScheduleInstancesQuery>;
export type GetScheduleInstancesLazyQueryHookResult = ReturnType<typeof useGetScheduleInstancesLazyQuery>;
export type GetScheduleInstancesSuspenseQueryHookResult = ReturnType<typeof useGetScheduleInstancesSuspenseQuery>;
export type GetScheduleInstancesQueryResult = Apollo.QueryResult<GetScheduleInstancesQuery, GetScheduleInstancesQueryVariables>;
export const UpdateScheduleInstanceDocument = gql`
    mutation UpdateScheduleInstance($id: Int!, $coreUserId: String, $startDateTime: Date, $endDateTime: Date) {
  updateScheduleInstance(
    id: $id
    coreUserId: $coreUserId
    startDateTime: $startDateTime
    endDateTime: $endDateTime
    overidePlan: true
  ) {
    id
    coreUserId
    startDateTime
    endDateTime
  }
}
    `;
export type UpdateScheduleInstanceMutationFn = Apollo.MutationFunction<UpdateScheduleInstanceMutation, UpdateScheduleInstanceMutationVariables>;

/**
 * __useUpdateScheduleInstanceMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleInstanceMutation, { data, loading, error }] = useUpdateScheduleInstanceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      coreUserId: // value for 'coreUserId'
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *   },
 * });
 */
export function useUpdateScheduleInstanceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScheduleInstanceMutation, UpdateScheduleInstanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScheduleInstanceMutation, UpdateScheduleInstanceMutationVariables>(UpdateScheduleInstanceDocument, options);
      }
export type UpdateScheduleInstanceMutationHookResult = ReturnType<typeof useUpdateScheduleInstanceMutation>;
export type UpdateScheduleInstanceMutationResult = Apollo.MutationResult<UpdateScheduleInstanceMutation>;
export type UpdateScheduleInstanceMutationOptions = Apollo.BaseMutationOptions<UpdateScheduleInstanceMutation, UpdateScheduleInstanceMutationVariables>;
export const GetTodosDocument = gql`
    query GetTodos {
  findTodosTodoPaginated(
    limit: 1000000
    noteType: {eq: "question"}
    createdAt: {gte: "2024-12-30", lte: "2025-01-05"}
  ) {
    data {
      id
      title
      dueDate
      description
      response
      complete
      assignees {
        user {
          firstName
          lastName
        }
        CoreGroup {
          name
        }
      }
      createdAt
      CreatedByUser {
        email
        firstName
        lastName
      }
      CompleteByUser {
        email
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetTodosQuery__
 *
 * To run a query within a React component, call `useGetTodosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTodosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTodosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTodosQuery(baseOptions?: Apollo.QueryHookOptions<GetTodosQuery, GetTodosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTodosQuery, GetTodosQueryVariables>(GetTodosDocument, options);
      }
export function useGetTodosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTodosQuery, GetTodosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTodosQuery, GetTodosQueryVariables>(GetTodosDocument, options);
        }
export function useGetTodosSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTodosQuery, GetTodosQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTodosQuery, GetTodosQueryVariables>(GetTodosDocument, options);
        }
export type GetTodosQueryHookResult = ReturnType<typeof useGetTodosQuery>;
export type GetTodosLazyQueryHookResult = ReturnType<typeof useGetTodosLazyQuery>;
export type GetTodosSuspenseQueryHookResult = ReturnType<typeof useGetTodosSuspenseQuery>;
export type GetTodosQueryResult = Apollo.QueryResult<GetTodosQuery, GetTodosQueryVariables>;
export const GetChildTicketsDocument = gql`
    query GetChildTickets($id: Int!) {
  getAutotaskTicketsById(id: $id) {
    id
    ChildTickets {
      id
      ticketNumber
      title
    }
  }
}
    `;

/**
 * __useGetChildTicketsQuery__
 *
 * To run a query within a React component, call `useGetChildTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChildTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChildTicketsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChildTicketsQuery(baseOptions: Apollo.QueryHookOptions<GetChildTicketsQuery, GetChildTicketsQueryVariables> & ({ variables: GetChildTicketsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChildTicketsQuery, GetChildTicketsQueryVariables>(GetChildTicketsDocument, options);
      }
export function useGetChildTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChildTicketsQuery, GetChildTicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChildTicketsQuery, GetChildTicketsQueryVariables>(GetChildTicketsDocument, options);
        }
export function useGetChildTicketsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetChildTicketsQuery, GetChildTicketsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetChildTicketsQuery, GetChildTicketsQueryVariables>(GetChildTicketsDocument, options);
        }
export type GetChildTicketsQueryHookResult = ReturnType<typeof useGetChildTicketsQuery>;
export type GetChildTicketsLazyQueryHookResult = ReturnType<typeof useGetChildTicketsLazyQuery>;
export type GetChildTicketsSuspenseQueryHookResult = ReturnType<typeof useGetChildTicketsSuspenseQuery>;
export type GetChildTicketsQueryResult = Apollo.QueryResult<GetChildTicketsQuery, GetChildTicketsQueryVariables>;
export const GetFormByIdDocument = gql`
    query GetFormById($id: Int!) {
  getFormsInstanceById(id: $id) {
    Form {
      id
      name
      identifier
      description
      FormFields {
        id
        formId
        type
        name
        label
        config
      }
    }
    values
  }
}
    `;

/**
 * __useGetFormByIdQuery__
 *
 * To run a query within a React component, call `useGetFormByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFormByIdQuery(baseOptions: Apollo.QueryHookOptions<GetFormByIdQuery, GetFormByIdQueryVariables> & ({ variables: GetFormByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormByIdQuery, GetFormByIdQueryVariables>(GetFormByIdDocument, options);
      }
export function useGetFormByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormByIdQuery, GetFormByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormByIdQuery, GetFormByIdQueryVariables>(GetFormByIdDocument, options);
        }
export function useGetFormByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetFormByIdQuery, GetFormByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetFormByIdQuery, GetFormByIdQueryVariables>(GetFormByIdDocument, options);
        }
export type GetFormByIdQueryHookResult = ReturnType<typeof useGetFormByIdQuery>;
export type GetFormByIdLazyQueryHookResult = ReturnType<typeof useGetFormByIdLazyQuery>;
export type GetFormByIdSuspenseQueryHookResult = ReturnType<typeof useGetFormByIdSuspenseQuery>;
export type GetFormByIdQueryResult = Apollo.QueryResult<GetFormByIdQuery, GetFormByIdQueryVariables>;
export const SearchCoreUsersDocument = gql`
    query SearchCoreUsers($search: String) {
  findCoreUsersPaginated(search: $search, email: {like: "%coulee.tech"}) {
    data {
      id
      email
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useSearchCoreUsersQuery__
 *
 * To run a query within a React component, call `useSearchCoreUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCoreUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCoreUsersQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchCoreUsersQuery(baseOptions?: Apollo.QueryHookOptions<SearchCoreUsersQuery, SearchCoreUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCoreUsersQuery, SearchCoreUsersQueryVariables>(SearchCoreUsersDocument, options);
      }
export function useSearchCoreUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCoreUsersQuery, SearchCoreUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCoreUsersQuery, SearchCoreUsersQueryVariables>(SearchCoreUsersDocument, options);
        }
export function useSearchCoreUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchCoreUsersQuery, SearchCoreUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchCoreUsersQuery, SearchCoreUsersQueryVariables>(SearchCoreUsersDocument, options);
        }
export type SearchCoreUsersQueryHookResult = ReturnType<typeof useSearchCoreUsersQuery>;
export type SearchCoreUsersLazyQueryHookResult = ReturnType<typeof useSearchCoreUsersLazyQuery>;
export type SearchCoreUsersSuspenseQueryHookResult = ReturnType<typeof useSearchCoreUsersSuspenseQuery>;
export type SearchCoreUsersQueryResult = Apollo.QueryResult<SearchCoreUsersQuery, SearchCoreUsersQueryVariables>;
export const SearchCoreGroupsDocument = gql`
    query SearchCoreGroups($search: String) {
  findCoreGroupPaginated(name: {like: $search}) {
    data {
      id
      name
      description
    }
  }
}
    `;

/**
 * __useSearchCoreGroupsQuery__
 *
 * To run a query within a React component, call `useSearchCoreGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCoreGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCoreGroupsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchCoreGroupsQuery(baseOptions?: Apollo.QueryHookOptions<SearchCoreGroupsQuery, SearchCoreGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCoreGroupsQuery, SearchCoreGroupsQueryVariables>(SearchCoreGroupsDocument, options);
      }
export function useSearchCoreGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCoreGroupsQuery, SearchCoreGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCoreGroupsQuery, SearchCoreGroupsQueryVariables>(SearchCoreGroupsDocument, options);
        }
export function useSearchCoreGroupsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchCoreGroupsQuery, SearchCoreGroupsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchCoreGroupsQuery, SearchCoreGroupsQueryVariables>(SearchCoreGroupsDocument, options);
        }
export type SearchCoreGroupsQueryHookResult = ReturnType<typeof useSearchCoreGroupsQuery>;
export type SearchCoreGroupsLazyQueryHookResult = ReturnType<typeof useSearchCoreGroupsLazyQuery>;
export type SearchCoreGroupsSuspenseQueryHookResult = ReturnType<typeof useSearchCoreGroupsSuspenseQuery>;
export type SearchCoreGroupsQueryResult = Apollo.QueryResult<SearchCoreGroupsQuery, SearchCoreGroupsQueryVariables>;
export const GetParentAndChildInfoDocument = gql`
    query GetParentAndChildInfo($id: Int!) {
  getAutotaskTicketsById(id: $id) {
    coreCompanyId
    ParentTicket {
      ...TicketFields
      ParentTicket {
        ...TicketFields
      }
      ChildTickets {
        ...TicketFields
      }
    }
    ChildTickets {
      ...TicketFields
      ChildTickets {
        ...TicketFields
        ChildTickets {
          ...TicketFields
        }
      }
    }
  }
}
    ${TicketFieldsFragmentDoc}`;

/**
 * __useGetParentAndChildInfoQuery__
 *
 * To run a query within a React component, call `useGetParentAndChildInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentAndChildInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentAndChildInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetParentAndChildInfoQuery(baseOptions: Apollo.QueryHookOptions<GetParentAndChildInfoQuery, GetParentAndChildInfoQueryVariables> & ({ variables: GetParentAndChildInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParentAndChildInfoQuery, GetParentAndChildInfoQueryVariables>(GetParentAndChildInfoDocument, options);
      }
export function useGetParentAndChildInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParentAndChildInfoQuery, GetParentAndChildInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParentAndChildInfoQuery, GetParentAndChildInfoQueryVariables>(GetParentAndChildInfoDocument, options);
        }
export function useGetParentAndChildInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetParentAndChildInfoQuery, GetParentAndChildInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetParentAndChildInfoQuery, GetParentAndChildInfoQueryVariables>(GetParentAndChildInfoDocument, options);
        }
export type GetParentAndChildInfoQueryHookResult = ReturnType<typeof useGetParentAndChildInfoQuery>;
export type GetParentAndChildInfoLazyQueryHookResult = ReturnType<typeof useGetParentAndChildInfoLazyQuery>;
export type GetParentAndChildInfoSuspenseQueryHookResult = ReturnType<typeof useGetParentAndChildInfoSuspenseQuery>;
export type GetParentAndChildInfoQueryResult = Apollo.QueryResult<GetParentAndChildInfoQuery, GetParentAndChildInfoQueryVariables>;
export const SetParentOnTicketDocument = gql`
    mutation SetParentOnTicket($childId: Int!, $parentId: Int!) {
  updateAutotaskTickets(id: $childId, parentTicketId: $parentId) {
    id
    ParentTicket {
      id
    }
  }
}
    `;
export type SetParentOnTicketMutationFn = Apollo.MutationFunction<SetParentOnTicketMutation, SetParentOnTicketMutationVariables>;

/**
 * __useSetParentOnTicketMutation__
 *
 * To run a mutation, you first call `useSetParentOnTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetParentOnTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setParentOnTicketMutation, { data, loading, error }] = useSetParentOnTicketMutation({
 *   variables: {
 *      childId: // value for 'childId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useSetParentOnTicketMutation(baseOptions?: Apollo.MutationHookOptions<SetParentOnTicketMutation, SetParentOnTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetParentOnTicketMutation, SetParentOnTicketMutationVariables>(SetParentOnTicketDocument, options);
      }
export type SetParentOnTicketMutationHookResult = ReturnType<typeof useSetParentOnTicketMutation>;
export type SetParentOnTicketMutationResult = Apollo.MutationResult<SetParentOnTicketMutation>;
export type SetParentOnTicketMutationOptions = Apollo.BaseMutationOptions<SetParentOnTicketMutation, SetParentOnTicketMutationVariables>;
export const GetUsersScheduleDocument = gql`
    query GetUsersSchedule($coreUserId: String!, $scheduleInstanceDay: String!) {
  findScheduleInstancePaginated(
    coreUserId: {eq: $coreUserId}
    scheduleInstanceDay: {eq: $scheduleInstanceDay}
  ) {
    data {
      id
      scheduleInstanceDay
      available
      title
      description
      startDateTime
      endDateTime
      coreUserId
      conflictDetected
      SchedulePlan {
        name
      }
      AzureCalendar {
        subject
        bodyPreview
      }
      AutotaskTickets {
        title
        ticketNumber
        id
      }
      schedulePlanId
    }
  }
}
    `;

/**
 * __useGetUsersScheduleQuery__
 *
 * To run a query within a React component, call `useGetUsersScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersScheduleQuery({
 *   variables: {
 *      coreUserId: // value for 'coreUserId'
 *      scheduleInstanceDay: // value for 'scheduleInstanceDay'
 *   },
 * });
 */
export function useGetUsersScheduleQuery(baseOptions: Apollo.QueryHookOptions<GetUsersScheduleQuery, GetUsersScheduleQueryVariables> & ({ variables: GetUsersScheduleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersScheduleQuery, GetUsersScheduleQueryVariables>(GetUsersScheduleDocument, options);
      }
export function useGetUsersScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersScheduleQuery, GetUsersScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersScheduleQuery, GetUsersScheduleQueryVariables>(GetUsersScheduleDocument, options);
        }
export function useGetUsersScheduleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUsersScheduleQuery, GetUsersScheduleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersScheduleQuery, GetUsersScheduleQueryVariables>(GetUsersScheduleDocument, options);
        }
export type GetUsersScheduleQueryHookResult = ReturnType<typeof useGetUsersScheduleQuery>;
export type GetUsersScheduleLazyQueryHookResult = ReturnType<typeof useGetUsersScheduleLazyQuery>;
export type GetUsersScheduleSuspenseQueryHookResult = ReturnType<typeof useGetUsersScheduleSuspenseQuery>;
export type GetUsersScheduleQueryResult = Apollo.QueryResult<GetUsersScheduleQuery, GetUsersScheduleQueryVariables>;
export const FindTicketTimelinePaginatedDocument = gql`
    query FindTicketTimelinePaginated($ticketId: Float!, $search: String, $page: Int, $type: [String!]) {
  findTicketTimelinePaginated(
    atTicketId: {eq: $ticketId}
    limit: 15
    page: $page
    search: $search
    order: {date: DESC}
    type: {in: $type}
  ) {
    totalCount
    page
    limit
    data {
      id
      type
      date
      PhoneCall {
        startTime
        id
        PhoneCallLegs {
          id
          transcription
        }
      }
      AzureEmail {
        id
        subject
        bodyMarkdown
        bodyHtml
        createdDateTime
        AzureEmailParticipants {
          id
          emailId
          userRole
          emailAddress
          name
        }
        AzureEmailAttachments {
          id
          name
          contentType
          contentId
          emailId
          contentLocation
          isInline
        }
      }
      FormInstance {
        id
        autotaskTicketId
        todoId
        complete
        updated_at
        created_at
        recipeInstanceId
        Form {
          FormFields {
            id
            formId
            type
            name
            label
            config
          }
          description
          identifier
          name
          id
        }
        context
        values
      }
      CoreTimeclock {
        message
        id
        user {
          firstName
          lastName
          email
        }
        start
        end
      }
    }
  }
}
    `;

/**
 * __useFindTicketTimelinePaginatedQuery__
 *
 * To run a query within a React component, call `useFindTicketTimelinePaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTicketTimelinePaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTicketTimelinePaginatedQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      search: // value for 'search'
 *      page: // value for 'page'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useFindTicketTimelinePaginatedQuery(baseOptions: Apollo.QueryHookOptions<FindTicketTimelinePaginatedQuery, FindTicketTimelinePaginatedQueryVariables> & ({ variables: FindTicketTimelinePaginatedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindTicketTimelinePaginatedQuery, FindTicketTimelinePaginatedQueryVariables>(FindTicketTimelinePaginatedDocument, options);
      }
export function useFindTicketTimelinePaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindTicketTimelinePaginatedQuery, FindTicketTimelinePaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindTicketTimelinePaginatedQuery, FindTicketTimelinePaginatedQueryVariables>(FindTicketTimelinePaginatedDocument, options);
        }
export function useFindTicketTimelinePaginatedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindTicketTimelinePaginatedQuery, FindTicketTimelinePaginatedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindTicketTimelinePaginatedQuery, FindTicketTimelinePaginatedQueryVariables>(FindTicketTimelinePaginatedDocument, options);
        }
export type FindTicketTimelinePaginatedQueryHookResult = ReturnType<typeof useFindTicketTimelinePaginatedQuery>;
export type FindTicketTimelinePaginatedLazyQueryHookResult = ReturnType<typeof useFindTicketTimelinePaginatedLazyQuery>;
export type FindTicketTimelinePaginatedSuspenseQueryHookResult = ReturnType<typeof useFindTicketTimelinePaginatedSuspenseQuery>;
export type FindTicketTimelinePaginatedQueryResult = Apollo.QueryResult<FindTicketTimelinePaginatedQuery, FindTicketTimelinePaginatedQueryVariables>;
export const GetPhonesCallsForTimelineDocument = gql`
    query GetPhonesCallsForTimeline($id: Int!) {
  getPhonesCallsById(id: $id) {
    id
    startTime
    endTime
    direction
    fromNumber
    toNumber
    extention
    PhoneCallLegs {
      id
      extention
      startTime
      endTime
      transcription
      downloadUrl
      recordingLocalPath
      CoreUser {
        email
        firstName
        lastName
        id
      }
      Contact {
        firstName
        lastName
        email
      }
    }
    CoreUser {
      email
      firstName
      lastName
    }
    CoreCompany {
      name
      id
    }
    Contact {
      firstName
      lastName
      email
      id
    }
  }
}
    `;

/**
 * __useGetPhonesCallsForTimelineQuery__
 *
 * To run a query within a React component, call `useGetPhonesCallsForTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhonesCallsForTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhonesCallsForTimelineQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPhonesCallsForTimelineQuery(baseOptions: Apollo.QueryHookOptions<GetPhonesCallsForTimelineQuery, GetPhonesCallsForTimelineQueryVariables> & ({ variables: GetPhonesCallsForTimelineQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPhonesCallsForTimelineQuery, GetPhonesCallsForTimelineQueryVariables>(GetPhonesCallsForTimelineDocument, options);
      }
export function useGetPhonesCallsForTimelineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhonesCallsForTimelineQuery, GetPhonesCallsForTimelineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPhonesCallsForTimelineQuery, GetPhonesCallsForTimelineQueryVariables>(GetPhonesCallsForTimelineDocument, options);
        }
export function useGetPhonesCallsForTimelineSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPhonesCallsForTimelineQuery, GetPhonesCallsForTimelineQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPhonesCallsForTimelineQuery, GetPhonesCallsForTimelineQueryVariables>(GetPhonesCallsForTimelineDocument, options);
        }
export type GetPhonesCallsForTimelineQueryHookResult = ReturnType<typeof useGetPhonesCallsForTimelineQuery>;
export type GetPhonesCallsForTimelineLazyQueryHookResult = ReturnType<typeof useGetPhonesCallsForTimelineLazyQuery>;
export type GetPhonesCallsForTimelineSuspenseQueryHookResult = ReturnType<typeof useGetPhonesCallsForTimelineSuspenseQuery>;
export type GetPhonesCallsForTimelineQueryResult = Apollo.QueryResult<GetPhonesCallsForTimelineQuery, GetPhonesCallsForTimelineQueryVariables>;
export const FindAutotaskTicketsPaginatedDocument = gql`
    query FindAutotaskTicketsPaginated($search: String, $coreCompanyId: Float) {
  findAutotaskTicketsPaginated(
    search: $search
    status: {not: 5}
    parentTicketId: {null: true}
    coreCompanyId: {eq: $coreCompanyId}
  ) {
    data {
      id
      ticketNumber
      title
      parentTicketId
      coreCompanyId
    }
  }
}
    `;

/**
 * __useFindAutotaskTicketsPaginatedQuery__
 *
 * To run a query within a React component, call `useFindAutotaskTicketsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAutotaskTicketsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAutotaskTicketsPaginatedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      coreCompanyId: // value for 'coreCompanyId'
 *   },
 * });
 */
export function useFindAutotaskTicketsPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<FindAutotaskTicketsPaginatedQuery, FindAutotaskTicketsPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAutotaskTicketsPaginatedQuery, FindAutotaskTicketsPaginatedQueryVariables>(FindAutotaskTicketsPaginatedDocument, options);
      }
export function useFindAutotaskTicketsPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAutotaskTicketsPaginatedQuery, FindAutotaskTicketsPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAutotaskTicketsPaginatedQuery, FindAutotaskTicketsPaginatedQueryVariables>(FindAutotaskTicketsPaginatedDocument, options);
        }
export function useFindAutotaskTicketsPaginatedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindAutotaskTicketsPaginatedQuery, FindAutotaskTicketsPaginatedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindAutotaskTicketsPaginatedQuery, FindAutotaskTicketsPaginatedQueryVariables>(FindAutotaskTicketsPaginatedDocument, options);
        }
export type FindAutotaskTicketsPaginatedQueryHookResult = ReturnType<typeof useFindAutotaskTicketsPaginatedQuery>;
export type FindAutotaskTicketsPaginatedLazyQueryHookResult = ReturnType<typeof useFindAutotaskTicketsPaginatedLazyQuery>;
export type FindAutotaskTicketsPaginatedSuspenseQueryHookResult = ReturnType<typeof useFindAutotaskTicketsPaginatedSuspenseQuery>;
export type FindAutotaskTicketsPaginatedQueryResult = Apollo.QueryResult<FindAutotaskTicketsPaginatedQuery, FindAutotaskTicketsPaginatedQueryVariables>;