import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, Grid, IconButton, Typography } from "@mui/material"
import { Handle, Position, useEdges, useEdgesState, useNodes } from "reactflow"
import { useNodeContext } from "../../useNodeContext";
import { useCallback, useEffect, useState } from "react";
import { z } from "zod";
import { JsonSchema7Type, zodToJsonSchema } from "zod-to-json-schema";
import { FilterSetting } from "./types";
import { useNodeDataProperty, useRecipie } from "../../../../providers/Recipies.provider";
import { v4 as uuidv4 } from 'uuid';
import { FilterItem } from "./Components/FilterItem";
import { Icon } from "@iconify/react";

const contextJson = ( context : z.ZodType ) => {
 return zodToJsonSchema( context , { $refStrategy : 'none'} );
}

type FunctionManipulator<T = any > = (data: T) => T ;


export const FilterNode = ({ id , data } : {id : string , data : any  } ) => {
  const filters : FilterSetting[]  = data.filters || [];
  const [ matchType , setMatchType ] = useNodeDataProperty( id , 'matchType' , 'any');
  const { updateNodeData } = useRecipie();
    const { getContext } = useNodeContext( id );
    const nodes = useNodes();
    const edges = useEdges();


    const [ context, setContext ] = useState<JsonSchema7Type>( contextJson( getContext() ) );
    useEffect( () => {
      const newContext = getContext();
      setContext( contextJson( newContext ) );
    }, [
      JSON.stringify( { nodes , edges})
    ]);
    const setFilters = ( newFilters : FilterSetting[] | FunctionManipulator<FilterSetting[]>) => {
      if( typeof newFilters === 'function' ){
        updateNodeData( id , ( data : Record<string,any> ) => {
          return { ...data , filters: newFilters( data.filters ) }
        } );
      } else {
        updateNodeData( id , {filters : newFilters } );
      }
    }
   
    const addFilter = (  ) => {
      const id = uuidv4();
      setFilters( op =>{
        return [...(op ||[] as FilterSetting[]) , { id , field: '' , operator: 'exists' , value: '' , type: 'object' }]
      });
    }
    const updateFilter = (id : string , value : FunctionManipulator<Partial<FilterSetting>> | Partial<FilterSetting> ) => {
      if( typeof value === 'function' ){
        setFilters( ef => ef.map( _ => _.id === id ? { ..._ , ...value( _ ) } : _ ));
      } else {
        setFilters( ef => ef.map( _ => _.id === id ? { ..._ , ...value } : _ ));
      }
      setFilters( ef => ef.map( _ => _.id === id ? { ..._ , ...value } : _ ));
     } 
    const removeFilter = ( id : string ) => {
      setFilters( ef => ef.filter( _ => _.id !== id ));

    }
    const handleCopy = () => {
      navigator.clipboard.writeText( JSON.stringify( { defaultData : data , id : 'filter' , title : data.title } , null , 2 ) );
    }
    return <Box>
        <Card sx={{maxWidth: '700px' , width: '700px' , position: 'relative'}}>
            <CardHeader className="drag-handle" title="Filter Node"     action={
          <IconButton aria-label="copy" onClick={handleCopy}>
            <Icon icon="mdi:content-copy" />
          </IconButton>
        } />
            <CardContent>
              <Box sx={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center' , mb: 2}}>
                <Typography variant="h6">Match Method</Typography>
                <ButtonGroup>
                    <Button size="small"  variant="contained" color={matchType === 'any' ? 'secondary' : 'inherit'} onClick={() => setMatchType('any')} >Any</Button>
                    <Button size="small"  variant="contained" color={matchType === 'all' ? 'secondary' : 'inherit'} onClick={() => setMatchType('all')} >All</Button>
                  </ButtonGroup>
                  <Button size="small"  variant="contained" color="primary" onClick={addFilter} >Add Filter</Button>
              </Box>
                
                
                <Box sx={{position: 'absolute' , bottom: '60px' , right: 6}}>
                  <Typography variant="caption">Match</Typography>
                </Box>
                <Grid container spacing={2} sx={{mt: 1 , paddingRight: 6}}>
                  {filters.map( filter => <FilterItem removeFilter={removeFilter} key={filter.id} filterSetting={filter} onChange={updateFilter} context={context} /> )}
                </Grid>
                <Box sx={{position: 'absolute' , bottom: '28px' , right: 6}}>
                  <Typography variant="caption">No Match</Typography>
                </Box>      
            </CardContent>
        </Card>
        <Handle
          type="target"
          position={Position.Left}
          id={`handle-target`}
        />
          <Handle
            type="source"
            position={Position.Right}
            id={`handle-source-pass`}
            style={{ bottom: 64 , top: 'auto' }}
          />
            <Handle
            type="source"
            position={Position.Right}
            id={`handle-source-fail`}
            style={{ bottom: 32 , top: 'auto' }}
          />
          
    </Box>
}
