
import {  createContext, useContext } from "react";
export interface KeycloakProviderContext {
    offline?: boolean;
    checkOnline?: () => Promise<boolean>;
    login: () => void;
    logout: () => void;
    token?: string;
    ready?: boolean;
    tokenParsed?: Record<string,any>;
}
export const KeycloakContext = createContext<KeycloakProviderContext>({
    offline: true,
    login: () => {},
    logout: () => {},
    checkOnline: () => Promise.resolve(false),
});
export const useKeycloak = () => useContext(KeycloakContext);

export const _KEYCLOAK_URL = "https://auth.timwoods.app/auth/"
export const KEYCLOAK_URL = "https://login.enlightenedmsp.com/auth/"

export const KEYCLOAK_REALM = "enlightenedmsp"

