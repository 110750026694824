import { Box, Button, TextField, Typography, useTheme, MenuItem } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormField, AlertFormFieldConfig, isAlertFormField } from "../../../../coretypes/formfields";
import { FormFieldType } from "./FormFieldType";
import { useApi } from "../../../providers/Api/Api";

export const AlertFormField: FC<{
    field: FormField,
    onFieldChange?: (field: Partial<FormField> & Pick<FormField, 'id'>) => void
    onFieldSave?: (field: Partial<FormField> & Pick<FormField, 'id'>) => void
    onFieldDelete?: (field: FormField) => void
    disableAutosave?: boolean
    controlled?: boolean
}> = ({ field, onFieldChange, onFieldDelete, disableAutosave, controlled, onFieldSave }) => {
    const [formFieldDirty, setFormFieldDirty] = useState(false);
    const [formFieldCore, setFormFieldCore] = useState(field);

    const theme = useTheme();
    const formField = controlled ? field : formFieldCore;
    const api = useApi();

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (formFieldDirty && !disableAutosave) {
                setFormFieldDirty(false);
                api.patch(`/forms/field/${formField.id}`, formField);
                onFieldSave && onFieldSave(formField);
            }
        }, 2000);
        return () => clearTimeout(timeout);
    }, [formFieldDirty, disableAutosave]);

    const updateFormParam = (key: keyof FormField, value: any) => {
        setFormFieldDirty(true);
        if (field) {
            if (key === 'name') {
                value = value.toLowerCase().replace(/ /g, '_');
            }
            onFieldChange && onFieldChange({ id: formField.id, [key]: value } as Partial<FormField> & Pick<FormField, 'id'>);
            setFormFieldCore(currentField => ({ ...currentField, [key]: value }));
        }
    }

    const updateFormConfigParam = <K extends keyof AlertFormFieldConfig>(
        key: K,
        value: AlertFormFieldConfig[K]
    ) => {
        if (!isAlertFormField(formField)) return;
        
        setFormFieldDirty(true);
        const newConfig = {
            ...formField.config,
            [key]: value
        } as AlertFormFieldConfig;
        
        onFieldChange?.({
            id: formField.id,
            config: newConfig
        });
        
        setFormFieldCore(currentField => ({
            ...currentField,
            config: newConfig
        }));
    };

    const deleteField = () => {
        if (disableAutosave) {
            onFieldDelete && onFieldDelete(formField);
            return;
        }
        api.delete(`/forms/field/${formField.id}`).then(() => {
            onFieldDelete && onFieldDelete(formField);
        });
    }

    return <Box sx={{
        display: 'flex', flexDirection: 'column', gap: 2, margin: 2, padding: 2,
        border: '1px solid', borderColor: formFieldDirty ? theme.palette.warning.light : '#777',
        borderRadius: 2
    }}>
        <Typography variant="h6">Alert Field</Typography>
        <FormFieldType type={formField.type} onChange={e => updateFormParam('type', e.target.value)} />
        <TextField
            value={formField.name}
            label="Name"
            onChange={e => updateFormParam('name', e.target.value)}
        />
        <TextField
            value={formField.label}
            label="Label"
            onChange={e => updateFormParam('label', e.target.value)}
        />
        <TextField
            value={formField?.config?.severity || 'info'}
            label="Severity"
            onChange={e => updateFormConfigParam('severity', e.target.value as AlertFormFieldConfig['severity'])}
            select
        >
            <MenuItem value="error">Error</MenuItem>
            <MenuItem value="warning">Warning</MenuItem>
            <MenuItem value="info">Info</MenuItem>
            <MenuItem value="success">Success</MenuItem>
        </TextField>
        <TextField
            value={formField?.config?.defaultValue || ''}
            label="Default Value"
            onChange={e => updateFormConfigParam('defaultValue', e.target.value)}
        />
        <Box><Button color="error" onClick={() => deleteField()}>Delete</Button></Box>
    </Box>;
};

