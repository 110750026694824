import { FC, useEffect } from "react"
import { FormFieldText } from "./FormFieldText"
import { FormFieldTextArea } from "./FormFieldTextArea"
import { FormField as TFormField } from "../../../../coretypes/formfields"
import { FormPhoneNumber } from "./FormPhoneNumber"
import { FormFieldAlert } from "./FormFieldAlert"
import { FormFieldEnum } from "./FormFieldEnum"

export const CoreFormField : FC<{ readonly?: boolean, viewMode?: boolean, field : TFormField , value : any , onChange: ( value : any ) => void }> = ({ readonly, viewMode, field , value , onChange }) => {
    switch( field.type){
        case "TEXT" : return <FormFieldText readonly={readonly} viewMode={viewMode} field={field} value={value} onChange={onChange} />
        case "TEXTAREA" : return <FormFieldTextArea readonly={readonly} viewMode={viewMode} field={field} value={value} onChange={onChange} />
        case "PHONE_NUMBER" : return <FormPhoneNumber readonly={readonly} viewMode={viewMode} field={field} value={value} onChange={onChange} />
        case "ALERT" : return <FormFieldAlert readonly={readonly} viewMode={viewMode} field={field} value={value} onChange={onChange} />    
        case "ENUM" : return <FormFieldEnum readonly={readonly} viewMode={viewMode} field={field} value={value} onChange={onChange} />
    }       
    return <>NOT YET IMPLEMENTED</>
}

