import { useState } from "react";
import { useApi } from "../react-submodules/providers/Api/Api";
import { Button, Paper } from "@mui/material";
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'
import { HtmlView } from "../react-submodules/components/HtmlView";
import { ChatBubble } from "../react-submodules/components/ChatBubble/ChatBubble";
import { useGetTodosQuery } from "../generated/graphql";


export const Test = () => {
    const api = useApi();
    

    
    const { data , refetch } = useGetTodosQuery();
    const handleClick = async () => {
        const response = await api.get('/azure/account/test');
        refetch();
        console.log( response );
    }
    return <>
        <Button onClick={ handleClick }>Test</Button>
        <pre>{ JSON.stringify( data?.findTodosTodoPaginated?.data || [], null, 2 ) }</pre>
    </>
}