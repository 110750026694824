
import { Box, IconButton, PaletteColor, useTheme } from "@mui/material"

import { IconButtonProps } from "@mui/material"
import { useMemo } from "react";




export const ContainedIconButton = (props: IconButtonProps) => {
    const theme = useTheme();
    const {sx , ...rest} = props;
    const palette = useMemo(()=>{
        if( theme.palette.hasOwnProperty(props.color || 'default')){
            return theme.palette[( props.color || 'default') as keyof typeof theme.palette] as PaletteColor
        }
        return theme.palette.primary
    },[props.color])
    return <Box sx={{display: 'flex' , alignItems: 'center' , justifyContent: 'center'}}><IconButton {...rest} sx={{...sx , 
        backgroundColor: palette.main , 
        color: palette.contrastText , 
        boxShadow: theme.shadows[2], 
        aspectRatio: '1/1',
        '&:hover': {backgroundColor: palette.dark , color: palette.contrastText}}} >{props.children}</IconButton>
        </Box>
}