import { Edge, Node } from "reactflow"
import { GetContext } from "../../types"
import { getParentContext } from "../utils";
import { JsonSchema7ArrayType, JsonSchema7NumberType, JsonSchema7ObjectType, JsonSchema7StringType, JsonSchema7Type } from "zod-to-json-schema";
import { FormItem } from "../../useNodeContext";
export const getFilterContext : GetContext = ( nodeId: string , nodes: Node[] , edges: Edge[] , forms: FormItem[] ) => {
    return getParentContext( nodeId , nodes , edges , forms );
}
export const isJsonSchemaObjectType = ( context: JsonSchema7Type ) : context is JsonSchema7ObjectType => {
    // @ts-ignore
    return context.type === "object";
}
export const isJsonSchemaStringType = ( context: JsonSchema7Type ) : context is JsonSchema7StringType => {
    // @ts-ignore
    return context.type === "string";
}
export const isJsonSchemaNumberType = ( context: JsonSchema7Type ) : context is JsonSchema7NumberType => {
    // @ts-ignore
    return context.type === "number";
}
export const isJsonSchemaArrayType = ( context: JsonSchema7Type ) : context is JsonSchema7ArrayType => {
    // @ts-ignore
    return context.type === "array";
}