import { Box, Button, IconButton, InputAdornment, MenuItem, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";

import { FormField } from "../../../../coretypes/formfields";
import { FormFieldType } from "./FormFieldType";
import { Icon } from "@iconify/react";
import { useApi } from "../../../providers/Api/Api";

export const PhoneNumberFormField : FC<{
    field : FormField,
    onFieldChange? : ( field : Partial<FormField> & Pick<FormField , 'id'> ) => void
    onFieldSave? : ( field : Partial<FormField> & Pick<FormField , 'id'> ) => void
    onFieldDelete? : ( field : FormField ) => void
    disableAutosave? : boolean
    controlled? : boolean
}> = ({ field , onFieldChange , onFieldDelete , disableAutosave , controlled , onFieldSave }) => {
    const [ formFieldDirty , setFormFieldDirty ] = useState(false);
    const [ formFieldCore , setFormFieldCore ] = useState(field);

    const theme = useTheme();
    const formField = controlled ? field :  formFieldCore;
    const api = useApi();
    useEffect( () => {
        const timeout = setTimeout( () => {
            if( formFieldDirty && !disableAutosave ){
                setFormFieldDirty(false);
                api.patch(`/forms/field/${formField.id}` , formField);
                onFieldSave && onFieldSave( formField );
            }
        } , 2000 );
        return () => clearTimeout(timeout);
    } , [ formFieldDirty , disableAutosave ]);

    const updateFormParam = (key: keyof FormField , value: any) => {
        setFormFieldDirty(true);
        if ( field ) {
            if( key === 'name'){
                value = value.toLowerCase().replace(/ /g, '_');
            }
            onFieldChange && onFieldChange( { id : formField.id , [key]: value } as Partial<FormField> & Pick<FormField , 'id'> );
            setFormFieldCore( currentField => { return  { ...currentField , [key]: value } } );
        }
    }

    const updateFormConfigParam = (key: keyof FormField['config'] , value: any) => {
        setFormFieldDirty(true);
        if ( field ) {
            if( key === 'name'){
                value = value.toLowerCase().replace(/ /g, '_');
            }
            onFieldChange && onFieldChange( { id : formField.id , config : { ...formField.config , [key]: value } } as Partial<FormField> & Pick<FormField , 'id'> );
            setFormFieldCore( currentField => { return  { ...currentField , config : { ...currentField.config , [key]: value } } });
        }
    }
    const deleteField = () => {
        if( disableAutosave ){
            onFieldDelete && onFieldDelete( formField );
            return;
        }
        api.delete(`/forms/field/${formField.id}`).then( () => {
            onFieldDelete && onFieldDelete( formField );
        });
    }
    return <Box sx={{
        display: 'flex' , flexDirection: 'column' , gap: 2 , margin: 2 , padding: 2, border : '1px solid', borderColor: formFieldDirty ? theme.palette.warning.light : '#777', borderRadius: 2
    }}>
        <Typography variant="h6">
            Phone Number
        </Typography>
        <FormFieldType type={formField.type} onChange={e => updateFormParam('type' , e.target.value)} />
        <TextField
            value={formField.name}
            label="Name"
            onChange={e => updateFormParam('name' , e.target.value)}
        />
        <TextField
            value={formField.label}
            label="Label"
            onChange={e => updateFormParam('label' , e.target.value)}
        />
        <TextField
            value={formField?.config?.defaultValue || ''}
            label="Default Value"
            onChange={e => updateFormConfigParam('defaultValue' , e.target.value)}
        />
        <TextField
            value={formField?.config?.defaultValueInterpreter || ''}
            label="Default Value Interpreter"
            onChange={e => updateFormConfigParam('defaultValueInterpreter' , e.target.value)}
            select 
            
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" sx={{marginRight: '16px'}}>
                        <Tooltip title={<Box>
                            <Typography variant="body2">Tells the backend how to interpret the default value</Typography>
                            <Typography variant="caption">DIRECT - The default value is used directly</Typography>
                            <Typography variant="caption">HANDLEBARS - The default value is used as a handlebars template with the context variables availble {`{{ticket.title}}`}. See <a href="https://handlebarsjs.com/guide/" target="handlebarsjs" >https://handlebarsjs.com/guide/</a></Typography>
                        </Box>}><IconButton color="info">
                            <Icon icon="ic:round-info" />
                        </IconButton>
                        </Tooltip>
                    </InputAdornment>
                ),
            }}
        >
            <MenuItem value="DIRECT">DIRECT</MenuItem>
            <MenuItem value="HANDLEBARS">HANDLEBARS</MenuItem>
        </TextField>
        <Box><Button color="error" onClick={() => deleteField() }>Delete</Button></Box>
    </Box>;
};

