import { FC } from "react";
import { FormField, EnumOption } from "../../../../coretypes/formfields";
import { MenuItem, TextField } from "@mui/material";

export const FormFieldEnum: FC<{
    readonly?: boolean,
    viewMode?: boolean,
    field: FormField,
    value: any,
    onChange: (value: any) => void
}> = ({ readonly, viewMode, field, value, onChange }) => {
    return (
        <TextField
            select
            fullWidth
            label={field.label}
            value={value || field.config?.defaultValue || ''}
            onChange={e => onChange(e.target.value)}
            disabled={readonly}
            InputProps={{
                readOnly: viewMode
            }}
        >
            {field.config?.options?.map((option: EnumOption, index: number) => (
                <MenuItem key={index} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
}; 