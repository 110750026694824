export type FormFieldTypes = 'TEXT' | 'TEXTAREA' | 'PHONE_NUMBER' | 'ALERT' | 'ALERT' | 'ENUM';

export type EnumOption = {
  label: string;
  value: string;
}

export type TextFormFieldConfig = {
  disabled?: boolean | undefined;
  defaultValue?: string | undefined;
  defaultValueInterpreter?: 'DIRECT' | 'HANDLEBARS';
  processedDefaultValue?: string;
};
export type TextAreaFormFieldConfig = TextFormFieldConfig & {
  rows?: number;
};

export type PhoneNumberFormFieldConfig = TextFormFieldConfig & {
  };

export type AlertFormFieldConfig = TextFormFieldConfig & {
  severity?: 'error' | 'warning' | 'info' | 'success';
};


export type PhoneNumberFormField = {
  id: number;
  formId: number;
  type: 'PHONE_NUMBER';
  name: string;
  label: string;
  config: PhoneNumberFormFieldConfig;
};

export type AlertFormField = {
  id: number;
  formId: number;
  type: 'ALERT';
  name: string;
  label: string;
  config: AlertFormFieldConfig;
};
export type TextFormField = {
  id: number;
  formId: number;
  type: 'TEXT';
  name: string;
  label: string;
  config: TextFormFieldConfig;
};
export type TextAreaFormField = {
  id: number;
  formId: number;
  type: 'TEXTAREA';
  name: string;
  label: string;
  config: TextAreaFormFieldConfig;
};
export type BaseFormField<T extends Record<string, any> = Record<string, any>> = {
  id: number;
  formId: number;
  type: FormFieldTypes;
  name: string;
  label: string;
  config: Record<string, any>;
};

export type EnumFormFieldConfig = TextFormFieldConfig & {
  options?: EnumOption[];
};

export type EnumFormField = {
  id: number;
  formId: number;
  type: 'ENUM';
  name: string;
  label: string;
  config: EnumFormFieldConfig;
};

export type FormField = BaseFormField<TextFormField> | BaseFormField<TextAreaFormField> | BaseFormField<PhoneNumberFormField> | BaseFormField<AlertFormField> | BaseFormField<EnumFormField> | BaseFormField<AlertFormField>;

export function isTextFormField(field: FormField): field is TextFormField {
  return field.type === 'TEXT';
}

export function isTextAreaFormField(field: FormField): field is TextAreaFormField {
  return field.type === 'TEXTAREA';
}
export function isPhoneNumberFormField(field: FormField): field is PhoneNumberFormField {
  return field.type === 'PHONE_NUMBER';
}

export function isAlertFormField(field: FormField): field is AlertFormField {
  return field.type === 'ALERT';
}

export function isEnumFormField(field: FormField): field is EnumFormField {
  return field.type === 'ENUM';
}