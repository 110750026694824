import { Box, Button, List, ListItem, ListItemText, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { CoreTimeclock, useTimeclock } from "../../react-submodules/providers/Timeclock";
import { FC, useEffect, useMemo, useState } from "react";

import { QueueTicket } from "../../react-submodules/types/ticketqueue";
import { useQueueParams } from "../../react-submodules/hooks/useQueueParams";
import { Icon } from "@iconify/react";

const PunchItem: FC<{ punch: CoreTimeclock , now : number , selected : boolean }> = ({ punch , now , selected }) => {
    const theme = useTheme();
    const { changeTicket } = useQueueParams();
    // const { } = useTicketApi();
    const punchTime = useMemo( () => {
        if( !punch ) return null;
        const start = punch?.start ? new Date( punch.start ).getTime() : 0;
        const secondsFull = (now - start) / 1000;
        const seconds = secondsFull < 0 ? 0 : secondsFull;
        const minutes = Math.floor( seconds / 60 ) % 60;
        const hours = Math.floor( seconds / 60 / 60 );
        if( hours ) {
            return `${hours}h ${minutes}m`
        }
        return `${minutes}m`;
      } , [ punch?.start , now ] );

      return <Button sx={{ marginRight: 1, marginBottom: 1}} onClick={()=>{
        changeTicket(`${punch.ticketId}`);
    }} color={selected ? 'info': 'primary' } variant="contained" >{`${punchTime} - ${punch.at_ticketNumber} : ${punch?.at_title?.substring(0, 20)}${punch?.at_title?.length > 20 ? '...' : ''}`}</Button>

}


export const TicketSidepanel: FC<{ ticket: QueueTicket | null }> = ({ ticket }) => {
    const { activePunches } = useTimeclock();
    const [ now , setNow ] = useState( new Date().getTime() );
    useEffect( () => {
        const interval = setInterval( () => {
            setNow( new Date().getTime() );
        }, 15000);
        return () => {
            clearInterval( interval );
        }
    }, []);
    return <Box  >
        { activePunches.filter( p => p.isPayroll !== 1 && p.isPayroll !== true ).map( p => <PunchItem now={now} key={p.id} punch={p} selected={p.ticketId === ticket?.id} />
        ) }
    </Box >
}