import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, ButtonGroup, Card, Chip, Grid, Tab, Typography } from "@mui/material";
import { AutotaskTicketNotes, AutotaskTimeEntries, AutotaskTodo } from "../../types/autotask.dto";
import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";

import { AutotaskTodoItem } from "./AutotaskTodoItem";
import { BadgeAndName } from "../TicketPartials/BadgeAndName";
import { Billable } from "../TicketPartials/Billable";
import { CompanyTicketList } from "../CompanyTicketList/CompanyTicketList";
import { DevicePreview } from "../DevicePreview/DevicePreview";
import { Icon, InlineIcon } from "@iconify/react";
import { MarkdownView } from "../../MarkdownView";
import { NewTicketAlert } from "./NewTicketAlert";
import { NextActionDate } from "../TicketPartials/NextActionDate";
import { Priority } from "../TicketPartials/Priority";
import { Queue } from "../TicketPartials/Queue";
import { QueueTicket } from "../../types/ticketqueue";
import { Status } from "../TicketPartials/Status";
import { TTodosTodo } from "../../types/todos.dto";
import { TicketHeader } from "../TicketPartials/TicketHeader";
import { TicketNoteGrid } from "../TicketNoteGrid/TicketNoteGrid";
import { TicketProvider } from "../../providers/TicketProvider";
import { TicketQuickActions } from "../TicketQuickActions/TicketQuickActions";
import { TodoSidebar } from "../TodoSidebar/TodoSidebar";
import { useApi } from "../../providers/Api/Api";
import { useUser } from "../../providers/User";
import { TeamsSidebar } from "../TeamsSidebar/TeamsSidebar";
import { CoreTag, CoreTagLink } from "../../types/core";
import { AnyBadge, keysFromName } from "../AnyBadge";
import { getColorFromString } from "../../utils/colors";
import { TAG_COLOR_NAMESPACE } from "../../../const";
import { AzureEmail, isAzureEmail } from "../../types/azure";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { LabeledBox } from "../LabeldBox";
import { AttachmentChip } from "../AttachmentChip";
import { CoreTimeclock } from "../../providers/Timeclock";
import { ChatList } from "../ChatList/ChatList";
import { gql, useQuery } from "@apollo/client";
import { LINK_ICON } from "../../constances/icons";
import { useHeaderStore } from "../../providers/Header";
import { useMounted } from "../../hooks/useMounted";
import { Timeline } from "./Timeline/Timeline";
import { ParentTicketButton } from "../ParentTicketButton/ParentTicketButton";
import { ChildTicketButtons } from "../ChildTicketButtons/ChildTicketButtons";
import { useTicketApi } from "../../providers/TicketApiProvider";

const StyledAccordion: FC<PropsWithChildren & { label: string, AccordionDetailsProps?: any }> = ({ children, label, AccordionDetailsProps }) => {
    const [expanded, setExpanded] = useState(true);
    return <Accordion
        TransitionProps={{
            timeout: 100
        }}
        expanded={expanded}
        onChange={() => { setExpanded(!expanded) }}
        sx={{
            '& .MuiAccordionSummary-root': {
                minHeight: '40px',
                '&.Mui-expanded': {
                    minHeight: '40px',
                },
            },
            '& .MuiAccordionSummary-content': {
                margin: '8px 0',
                '&.Mui-expanded': {
                    margin: '8px 0',
                },
            },
            '& .MuiAccordionDetails-root': {
                padding: '8px 16px',
            },
        }}
    >
        <AccordionSummary>
            <Typography variant="body2">
                <Icon icon={expanded ? 'tabler:chevron-up' : 'tabler:chevron-down'} style={{ verticalAlign: 'middle' }} />
                &nbsp; {label}
            </Typography>
        </AccordionSummary>
        <AccordionDetails {...AccordionDetailsProps}>{children}</AccordionDetails>
    </Accordion>
}

const StyledTab: FC<PropsWithChildren & { label: string, AccordionDetailsProps?: any , sx?: any }> = ({ children, label, AccordionDetailsProps , sx }) => {
    //const [expanded, setExpanded] = useState(true);
    return <TabPanel value={label} sx={sx}>{children}</TabPanel>
}

const GET_TAGS = gql`
query GetTags($page: Int, $limit: Int , $autotaskTicketId: Float) {
  findCoreTagPaginated(page: $page, limit: $limit , autotaskTicketId: { eq : $autotaskTicketId }) {
    data {
      id
      name
      tagLink {
        link
      }
    }
  }
}
`

type TagQueryData = {
    findCoreTagPaginated: {
        data: Array<
            Pick<CoreTag, 'id' | 'name' > & {
                tagLink: Pick<CoreTagLink, 'link'>
            }
        >
    }
}


export const TicketFullCard: FC<{
    ticket: QueueTicket,
    onClick?: (ticket: QueueTicket) => any
    onClose?: () => any
}> = ({ ticket, onClose }) => {
    const loaded = useMounted();
    
    const { setTitle , title } = useHeaderStore();
    const [notes, setNotes] = useState<Array<AutotaskTicketNotes | AutotaskTimeEntries | AzureEmail | CoreTimeclock >>([]);
    const [refresh, setRefresh] = useState(1);
    
    const { data : tagData } = useQuery<TagQueryData>(GET_TAGS , { variables: { page: 0, limit: 100 , autotaskTicketId: ticket.id } })
    const tags = tagData?.findCoreTagPaginated?.data || [];
    const [ reset , setReset ] = useState(0);
    useEffect( () => {
        if( reset > 0 ){
            setReset( 0 );
        }
    } , [reset]);
    const [autotaskTodos, setAutotaskTodos] = useState<Array<AutotaskTodo>>([]);
    const [markdownDescription, setMarkdownDescription] = useState(true);

    
    const [tab , setTab] = useState("AI Summary");
    const api = useApi();
    useEffect(() => {
        if( tab !== "Timeline-Old" ){
            return;
        }
        let loaded = true;
        if (api.ready === false) return;
        setAutotaskTodos([]);
        setNotes([]);

        Promise.all([
            api.get(`/autotask/ticket/${ticket.id}/notes`), api.get(`/autotask/ticket/${ticket.id}/times`),  api.get(`/azure/emails/search` , {
            autotaskTicketId: ticket.id
        }), api.get(`/core/timeclock/search` , {
            ticketId: ticket.id
        }) ]).then(([notes, times,  emails, timeclock ]) => {
            if (loaded) {
                setNotes([...notes.data, ...times.data, ...emails.data, ...timeclock.data]);
                // setTags(tags.data);
            }
        })
        return () => {
            loaded = false;
        }
    }, [api.ready, ticket.id, refresh , tab ]);
    const attachments = useMemo( () => {
        const emails = notes.filter( n => isAzureEmail( n)) as AzureEmail[];
        return emails.map( e => e.attachments).flat().filter( a => a.isInline === false);
    } , [JSON.stringify(notes)])

    const activeAutotaskTodos = autotaskTodos.filter(todo => todo.completedDate === null);
    const completeAutotaskTodos = autotaskTodos.filter(todo => todo.completedDate !== null);
    const [ headerExpanded , setHeaderExpanded ] = useState(false);
    useEffect(() => {
        if( !loaded() ) return;
        if( ticket  ){
            setTitle(`EMSP - ${ticket?.companies_companyName} - ${ticket?.title} - ${ticket?.ticketNumber}`);
        } else {
            setTitle('EMSP - Ticket Queue');
        } 
        return ()=>{
            setTitle('Enlightened MSP');
        }
    }, [ticket?.companies_companyName , ticket?.title , ticket?.ticketNumber]);
    return <TicketProvider refresh={refresh} ticket={ticket}><Card variant="outlined" sx={{ height: '100%', position: 'relative' , display: 'flex', flexDirection: 'column'  }}>

        <TicketHeader title={`${ticket.ticketNumber} - ${ticket.title}`} status={ticket.status}  />
        <NewTicketAlert />
        <Grid container sx={{ height: '100%'  }} >
        <Grid sm={4} sx={{ borderRight: '1px solid #555', overflow: 'auto', height: 'calc( 100vh - 200px)' }} item>
            
            <LabeledBox label="Next Action Note" sx={{margin: 2}} >
                <Box ><MarkdownView >{ticket.userDefinedFields?.NAN_NextActionNote || ""}</MarkdownView></Box>
            </LabeledBox>
            <ParentTicketButton ticketId={ticket.id} />
            <ChildTicketButtons ticketId={ticket.id} />
                {ticket?.configurationItemID !== null && <DevicePreview />}

                <TodoSidebar />
                <TeamsSidebar />
                {activeAutotaskTodos.length > 0 && <StyledAccordion label="Active Todos" AccordionDetailsProps={{ sx: { background: "#eee" } }}><Box sx={{ padding: 2 }}>

                    {activeAutotaskTodos.map(todo => {
                        return <AutotaskTodoItem todo={todo} key={todo.id} />
                    })}

                </Box></StyledAccordion>}

                {ticket.statusId === 1 && <StyledAccordion label="Simalar Ticket List" AccordionDetailsProps={{ sx: { background: "#eee" } }}>
                    <CompanyTicketList companyID={ticket.companyID} contactID={ticket?.contactID} />
                </StyledAccordion>}

                {completeAutotaskTodos.length > 0 && <StyledAccordion label="Complete Todos" AccordionDetailsProps={{ sx: { background: "#eee" } }}><Box sx={{ padding: 2 }}>
                    {completeAutotaskTodos.map(todo => {
                        return <AutotaskTodoItem todo={todo} key={todo.id} />
                    })}
                </Box></StyledAccordion>}
            </Grid>
            <Grid sx={{ maxHeight: 'calc(100vh - 225px)' , display: 'flex', flexDirection: 'column' , height: '100%', position: 'relative' }} sm={8} item>
                <Box sx={{ flexShrink: 0 }}>
                <Accordion expanded={headerExpanded} onChange={() => { setHeaderExpanded(!headerExpanded) }}>
                    <AccordionSummary sx={{ padding: 0 , backgroundColor: '#eee' , paddingLeft: 2 }}>
                        
                        <Icon icon={headerExpanded ? 'fluent-mdl2:caret-solid-down' : 'fluent-mdl2:caret-solid-right'  } style={{ verticalAlign: 'middle' }} />
                        <Typography variant="body2" sx={{ paddingLeft: 2 }}>Information Panel</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' , paddingLeft: 2 }}>
                        <Status iconOnly status={ticket.status} />
                        <Queue iconOnly queue={ticket.queue || undefined} />
                        <AnyBadge size="small" name={ticket.companies_companyName || ''} colorkey={`${ticket.companyID}` || undefined}  />
                        <AnyBadge size="small" name={`${ticket.contacts_firstName} ${ticket.contacts_lastName}`} colorkey={ticket.contacts_emailAddress || undefined}  />
                        <Priority priority={ticket.priority} iconOnly />
                        <Billable billable={ticket.billable} iconOnly />
                        <AnyBadge size="small" name={ticket.assigned_tech_name || 'Un Assigned'} colorkey={`${ticket.assigned_tech_email}` || 'no-tech'}  />
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2} sx={{ paddingBottom: 2, margin: 0, width: 'calc(100% - 20px)' }}>
                            <Grid item xs={12} sm={6} md={4} lg={3} ><Status status={ticket.status} /></Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} ><Queue queue={ticket.queue || undefined} /></Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} >
                                <BadgeAndName phone={ticket.companies_phone} displayName={ticket.companies_companyName || ''} colorkey={`${ticket.companyID}` || undefined} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} >
                                <BadgeAndName 
                                    phone={ticket.contacts_phone} 
                                    phoneExtension={ticket.contacts_extension}
                                    cell={ticket.contacts_mobilePhone}
                                    email={ticket.contacts_emailAddress} displayName={`${ticket.contacts_firstName} ${ticket.contacts_lastName}`} colorkey={ticket.contacts_emailAddress || undefined} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} ><NextActionDate nextActionDate={ticket.nextActionDate} nextActionTime={ticket.nextActionTime} nextActionType={ticket.nextActionType} /></Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} ><Priority priority={ticket.priority} /></Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} ><Billable billable={ticket.billable} /></Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} ><BadgeAndName displayName={ticket.assigned_tech_name || ''} colorkey={`${ticket.assigned_tech_email}` || undefined} /></Grid>
                        </Grid>
                </AccordionDetails>
                </Accordion>
                </Box>
                <TabContext  value={tab}>
                <TabList 
                        onChange={(e , v) => { setTab(v) }} 
                        
                        centered 
                        variant="standard" 
                        textColor="secondary"
                        sx={{
                            
                            minHeight: '30px',
                            height: '30px',
                            backgroundColor: '#f0f0f0', // Light gray background
                            borderRadius: '4px 4px 0 0', // Rounded top corners
                            '& .MuiTab-root': {
                                color: '#333', // Dark text for contrast
                                fontSize: '12px',
                                minHeight: '30px',
                                height: '30px',
                                '&.Mui-selected': {
                                    color: 'secondary.contrastText', // Use primary color for selected tab
                                    backgroundColor: 'secondary.main', // White background for selected tab
                                },
                            },
                        }}
                    >
                        <Tab label="AI Summary" value="AI Summary" />
                        <Tab label="AI Chat" value="AI Chat" onClick={()=> setReset( 1)} />
                        <Tab label="Description" value="Description" />
                        <Tab label="Timeline" value="Timeline" />
                        <Tab label="Timeline - Old" value="Timeline-Old" />
                        { attachments.length > 0 && <Tab label="Attachments" value="Attachments" /> }
                    </TabList>
                
                    <Box sx={{ flexGrow: 1, margin: 0, position: 'relative', height: '100%'}}>
                
                    
                    <StyledTab label="Attachments">
                        <Box>
                            {attachments.map(a => {
                                return <AttachmentChip attachment={a} key={a.id} />
                            })}
                        </Box>
                    </StyledTab>
                    <StyledTab label="AI Summary"  sx={{overflow: 'auto' , height: '90%' , width: 'calc( ((70vw ) / 12) * 8 )'}} >
                        <Alert severity="info">This is a summary of the ticket generated by AI. It might be missleading and/or not 100% accurate </Alert>
                        
                        <Box>

                            {tags.map(tag => {
                                return <Chip key={tag.id}
                                icon={ tag.tagLink?.link ? <InlineIcon icon={LINK_ICON} /> : undefined}
                                onClick={tag.tagLink?.link ? () => {
                                    if( tag.tagLink?.link ){
                                        window.open(tag.tagLink.link, `TAG_${tag.name}`);
                                    }
                                } : undefined}
                                sx={{
                                    margin: 0.5,
                                    backgroundColor: getColorFromString(tag.name, TAG_COLOR_NAMESPACE)
                                }} size="small" label={`${tag.name}`} />
                            })}
                        </Box>
                        <MarkdownView>{ticket.aisummary || ""}</MarkdownView>
                    </StyledTab>
                    <StyledTab label="AI Chat" sx={{ height: '100%' }}>
                        { reset === 0 && <ChatList ticketId={ticket.id || undefined} /> }
                    </StyledTab>
                    
                    <StyledTab label="Description"  sx={{overflow: 'auto' , height: '98%' , width: 'calc( ((70vw ) / 12) * 8 )'}} >
                        <MarkdownView>{ticket.description}</MarkdownView>
                    </StyledTab>
                    <StyledTab label="Timeline" sx={{overflow: 'auto' , height: 'calc(100% - 75px)' , width: 'calc( ((100vw - 340px) / 12) * 8 )'}} >
                        <Timeline ticketId={ticket.id || 0} />
                    </StyledTab>
                    <StyledTab label="Timeline-Old" AccordionDetailsProps={{
                        sx: {  height: '100%'  }
                    }} sx={{overflow: 'auto' , height: '100%' , width: 'calc( ((100vw - 340px) / 12) * 8 )'}}><TicketNoteGrid notes={notes} /></StyledTab>
                    
                    <Grid container spacing={2}>

                        <Grid item sm={12}></Grid>
                        <Grid item sm={12}></Grid>
                        <Grid item sm={12}></Grid>
                        {onClose && <Grid item sm={12}>
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <Box flexGrow={1}></Box>
                                <Box flexGrow={0}><Button size="small" onClick={() => { onClose && onClose() }}>close</Button></Box>
                                <Box flexGrow={1}></Box>
                            </Box>
                        </Grid>}
                    </Grid>
                </Box>
                </TabContext>
            </Grid>
            
        </Grid>
    </Card></TicketProvider>
}