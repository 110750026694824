import { z } from 'zod';

export type TBillable = 'Managed' | 'Hourly' | 'TBD' | null;

export type QueueTicket = {
    "fromRole"?: "my_ticket" | "triage" | 'tier-1' | 'tier-2' | "tier-2-1stup";
    "contacts_emailAddress": string | null ;
    "todoId"?: number | null;
    "contacts_firstName": string | null ;
    importanceRank?: number | null;
    "contacts_lastName": string | null ;
    "contacts_phone": string | null ;
    "contacts_extension": string | null ;
    "contacts_mobilePhone": string | null ;
    "atdevice_referenceTitle": null | string ;
    "atdevice_rmmDeviceID": null | string ;
    dispatched_id: string | null;
    dispatched_coreUserId: string | null;
    dispatched_ticketId: string | null;
    dispatched_dispatchedAt: string | null;
    dispatched_lastSeen: string | null;
    dispatched_accepted: string | null;
    dispatched_acceptedAt: string | null;
    dispatched_active: string | null;
    dispatched_fromRole: string | null;
    aisummary: string | null;
    dispatched_user_id: string | null;
    dispatched_user_timwoodsId: string | null;
    dispatched_user_theme: string | null;
    dispatched_user_email: string | null;
    dispatched_user_firstName: string | null;
    dispatched_user_lastName: string | null;
    dispatched_user_canContactCustomers: string | null;
    dispatched_user_isTech: string | null;
    dispatched_user_techTier: string | null;
    dispatched_user_autotaskId: string | null;
    dispatched_user_isDispatchable: string | null;
    dispatched_user_isRoleTriage: string | null;
    dispatched_user_isRoleTier1: string | null;
    dispatched_user_isRoleTier2FirstUp: string | null;
    dispatched_user_isRoleTier2: string | null;
    dispatched_user_isScheduler: string | null;
    dispatched_user_useNewRank: string | null;
    dispatched_user_bvoipExtention: string | null;
    autotaskUsersClockedIn: string | null;
    ticketId: string | null;
    coreUsersClockedIn: string | null;
    "companies_coreCompanyId": number | null ;
    "companies_companyName": string | null ;
    "companies_phone": string | null ;
    "id": number | null ;
    "title": string ;
    "description": string ;
    "status": string ;
    "statusId" : number | null;
    "queueID": number | null ;
    "assignedResourceID": number | null ;
    "companyLocationID": number | null;
    "companyID": number | null;
    "contactID": number | null;
    "ticketNumber": string | null ;
    "dueDateTime": string | null ;
    "completedByResourceID": null,
    "createdByContactID": null,
    "createDate": string | null ;
    "lastActivityDate": string | null ;
    "contactlessStart" : boolean | null;
    "lastActivityResourceID": number | null;
    "userDefinedFields": {
        "Impact": string | null ;
        "Rating": string | null ;
        "Auditor": string | null ;
        "Urgency": string | null ;
        "LINKED_SOP": string | null ;
        "ReportNote": string | null ;
        "PIA_CATEGORY": string | null,
        "SOP_VERSION": string | null ;
        "ImportantInfo": string | null;
        'Billable': 'Managed' | 'Hourly' | 'TBD' | null;
        "StoryPoints": string | null ;
        "AssignedRank": string | null ;
        "ClosedByTech": string | null ;
        "CORE_SPEEDCODE": string | null ;
        "NextActionDate": string | null ;
        "PreviousRating": string | null ;
        "TimwoodsTicket": string | null ;
        "TimwoodsPriority": string | null ;
        "NAN_NextActionNote": string | null ;
        "DomainConfiguration": string | null ;
        "DrivesConfiguration": string | null ;
        "On-siteWorkRequired": string | null ;
        "On-siteWorkEstimatedHours": string | null ;
        "NextActionTime": string | null;
        "NAD_Flexability": 'FLEXABLE' | 'FIRM' | null;
        "PIA_SERVICEABLE": string | null;
        "PIA_SUBCATEGORY": string | null;
        "CONTACTLESS_START": string | null;
        "PIA_CLIENT_ENABLED": string | null;
        "ENLIGHTENED_TICKET_CLASS": string | null;
    },
    archived: number;
    "nextActionDate": string|  null,
    nextActionNote: string | null;
    "queue": string | null ;
    "assigned_tech_email": string | null ;
    "assigned_tech_name": string | null ;
    'priority': number | null;
    'source': number | null;
    'ticketType': number | null;
    'ticketCategory': number | null; 
    'subIssueType': number | null;
    'billable': TBillable;
    'nextActionTime' : number | null;
    'nextActionType' : 'FLEXABLE' | 'FIRM' | null;
    "configurationItemID": number | null;
    autotaskTodos?: Array<any>
    "device": {
        "id": number | null;
        "uid": string | null;
        "siteId": number | null;
        "siteUid": string | null;
        "siteName": string | null;
        "hostname": string | null;
        "intIpAddress": string | null;
        "operatingSystem": string | null;
        "lastLoggedInUser": null | string;
        "domain": null | string;
        "cagVersion": string | null;
        "displayVersion": string | null;
        "extIpAddress": string | null;
        "description": string | null;
        "a64Bit": number | null;
        "rebootRequired": number | null;
        "online":  number | null;
        "suspended": number | null;
        "deleted": number | null;
        "lastSeen": string | null;
        "lastReboot": string | null;
        "lastAuditDate": string | null;
        "creationDate": string | null;
        "snmpEnabled": number | null;
        "deviceClass": string | null;
        "portalUrl": string | null;
        "warrantyDate": null | string;
        "softwareStatus": string | null;
        "category": string | null;
        "type": string | null;
        "udf1": null | string;
        "udf2": null | string;
        "udf3": null | string;
        "udf4": null | string;
        "udf5": null | string;
        "udf6": null | string;
        "udf7": null | string;
        "udf8": null | string;
        "udf9": null | string;
        "udf10": null | string;
        "udf11": null | string;
        "udf12": null | string;
        "udf13": null | string;
        "udf14": null | string;
        "udf15": null | string;
        "udf16": null | string;
        "udf17": null | string;
        "udf18": null | string;
        "udf19": null | string;
        "udf20": null |  string;
        "udf21": null | string;
        "udf22": null | string;
        "udf23": null | string;
        "udf24": null | string;
        "udf25": null | string;
        "udf26": null  | string;
        "udf27": null | string;
        "udf28": null | string;
        "udf29": null | string;
        "udf30": null | string;
        "antivirusProduct": null | string;
        "antivirusStatus": null | string;
        "patchStatus": null | string;
        "patchesApprovedPending": number | null;
        "patchesNotApproved": number | null;
        "patchesInstalled": number | null;
        "archived": number | null;
        "syncId": null | string;
        
    },
}

export type AutocompleteCompany = {
    id: number;
    companyName: string;
    coreCompanyId: string;
    phone?: string;
    label: string;
    value: number;
}

export type AutocompleteContact =  {
    "id": number;
    "emailAddress": string | null;
    "firstName": string | null;
    "lastName": string | null;
    "mobilePhone": string | null;
    "phone": string | null;
    "company": {
        "id":  number | null;
        "coreCompanyId": number | null;
        "companyName":  string | null;
        "companyNumber":  string | null;
    },
    "label":  string;
    "value": number;
}

export type AutotaskResource = {
    "id": number;
    "accountingReferenceID": string;
    "dateFormat": string;
    "defaultServiceDeskRoleID": null | number,
    "email": string;
    "email2": string;
    "email3": string;
    "emailTypeCode": string;
    "emailTypeCode2": null | string,
    "emailTypeCode3": null | string,
    "firstName": string;
    "gender": null | string;
    "greeting": null| string;
    "hireDate": string;
    "homePhone": string;
    "initials": string;
    "internalCost": number;
    "isActive": boolean;
    "lastName": string;
    "licenseType": number;
    "locationID": number;
    "middleName": string;
    "mobilePhone": string;
    "numberFormat":string;
    "officeExtension": string;
    "officePhone": string;
    "payrollType": number;
    "resourceType": string;
    "suffix": null,
    "surveyResourceRating": null,
    "timeFormat": string;
    "title": string;
    "travelAvailabilityPct": null,
    "userName": string;
    "userType": number;
    "user_id": string;
    "user_theme": string;
    "user_email": string;
    "user_canContactCustomers": number | null;
    "user_isTech": number | null;
    "user_techTier": number | null;
    "user_autotaskId": number | null;
    "user_isDispatchable": number | null;
    "user_isRoleTriage": number | null;
    "user_isRoleTier1": number | null;
    "user_isRoleTier2FirstUp": number | null;
    "user_isRoleTier2": number | null;
}




const TBillableSchema = z.enum(['Managed', 'Hourly', 'TBD']).nullable();

const UserDefinedFieldsSchema = z.object({
  Impact: z.string().nullable(),
  Rating: z.string().nullable(),
  Auditor: z.string().nullable(),
  Urgency: z.string().nullable(),
  LINKED_SOP: z.string().nullable(),
  ReportNote: z.string().nullable(),
  PIA_CATEGORY: z.string().nullable(),
  SOP_VERSION: z.string().nullable(),
  ImportantInfo: z.string().nullable(),
  Billable: TBillableSchema,
  StoryPoints: z.string().nullable(),
  AssignedRank: z.string().nullable(),
  ClosedByTech: z.string().nullable(),
  CORE_SPEEDCODE: z.string().nullable(),
  NextActionDate: z.string().nullable(),
  PreviousRating: z.string().nullable(),
  TimwoodsTicket: z.string().nullable(),
  TimwoodsPriority: z.string().nullable(),
  NAN_NextActionNote: z.string().nullable(),
  DomainConfiguration: z.string().nullable(),
  DrivesConfiguration: z.string().nullable(),
  'On-siteWorkRequired': z.string().nullable(),
  'On-siteWorkEstimatedHours': z.string().nullable(),
  NextActionTime: z.string().nullable(),
  NAD_Flexability: z.enum(['FLEXABLE', 'FIRM']).nullable(),
  PIA_SERVICEABLE: z.string().nullable(),
  PIA_SUBCATEGORY: z.string().nullable(),
  CONTACTLESS_START: z.string().nullable(),
  PIA_CLIENT_ENABLED: z.string().nullable(),
  ENLIGHTENED_TICKET_CLASS: z.string().nullable(),
});

const DeviceSchema = z.object({
  id: z.number().nullable(),
  uid: z.string().nullable(),
  siteId: z.number().nullable(),
  siteUid: z.string().nullable(),
  siteName: z.string().nullable(),
  hostname: z.string().nullable(),
  intIpAddress: z.string().nullable(),
  operatingSystem: z.string().nullable(),
  lastLoggedInUser: z.string().nullable(),
  domain: z.string().nullable(),
  cagVersion: z.string().nullable(),
  displayVersion: z.string().nullable(),
  extIpAddress: z.string().nullable(),
  description: z.string().nullable(),
  a64Bit: z.number().nullable(),
  rebootRequired: z.number().nullable(),
  online: z.number().nullable(),
  suspended: z.number().nullable(),
  deleted: z.number().nullable(),
  lastSeen: z.string().nullable(),
  lastReboot: z.string().nullable(),
  lastAuditDate: z.string().nullable(),
  creationDate: z.string().nullable(),
  snmpEnabled: z.number().nullable(),
  deviceClass: z.string().nullable(),
  portalUrl: z.string().nullable(),
  warrantyDate: z.string().nullable(),
  softwareStatus: z.string().nullable(),
  category: z.string().nullable(),
  type: z.string().nullable(),
  // ... other fields ...
  archived: z.number().nullable(),
  syncId: z.string().nullable(),
});

export const QueueTicketSchema = z.object({
  fromRole: z.enum(['my_ticket', 'triage', 'tier-1', 'tier-2', 'tier-2-1stup']).optional(),
  contacts_emailAddress: z.string().nullable(),
  contacts_firstName: z.string().nullable(),
  importanceRank: z.number().nullable().optional(),
  contacts_lastName: z.string().nullable(),
  contacts_phone: z.string().nullable(),
  atdevice_referenceTitle: z.string().nullable(),
  atdevice_rmmDeviceID: z.string().nullable(),
  dispatched_id: z.string().nullable(),
  dispatched_coreUserId: z.string().nullable(),
  dispatched_ticketId: z.string().nullable(),
  dispatched_dispatchedAt: z.string().nullable(),
  dispatched_lastSeen: z.string().nullable(),
  dispatched_accepted: z.string().nullable(),
  dispatched_acceptedAt: z.string().nullable(),
  dispatched_active: z.string().nullable(),
  dispatched_fromRole: z.string().nullable(),
  aisummary: z.string().nullable(),
  dispatched_user_id: z.string().nullable(),
  dispatched_user_timwoodsId: z.string().nullable(),
  dispatched_user_theme: z.string().nullable(),
  dispatched_user_email: z.string().nullable(),
  dispatched_user_firstName: z.string().nullable(),
  dispatched_user_lastName: z.string().nullable(),
  dispatched_user_canContactCustomers: z.string().nullable(),
  dispatched_user_isTech: z.string().nullable(),
  dispatched_user_techTier: z.string().nullable(),
  dispatched_user_autotaskId: z.string().nullable(),
  dispatched_user_isDispatchable: z.string().nullable(),
  dispatched_user_isRoleTriage: z.string().nullable(),
  dispatched_user_isRoleTier1: z.string().nullable(),
  dispatched_user_isRoleTier2FirstUp: z.string().nullable(),
  dispatched_user_isRoleTier2: z.string().nullable(),
  dispatched_user_isScheduler: z.string().nullable(),
  dispatched_user_useNewRank: z.string().nullable(),
  dispatched_user_bvoipExtention: z.string().nullable(),
  autotaskUsersClockedIn: z.string().nullable(),
  ticketId: z.string().nullable(),
  coreUsersClockedIn: z.string().nullable(),
  companies_coreCompanyId: z.number().nullable(),
  companies_companyName: z.string().nullable(),
  companies_phone: z.string().nullable(),
  id: z.number().nullable(),
  title: z.string(),
  description: z.string(),
  status: z.string(),
  statusId: z.number().nullable(),
  queueID: z.number().nullable(),
  assignedResourceID: z.number().nullable(),
  companyLocationID: z.number().nullable(),
  companyID: z.number().nullable(),
  contactID: z.number().nullable(),
  ticketNumber: z.string().nullable(),
  dueDateTime: z.string().nullable(),
  completedByResourceID: z.null(),
  createdByContactID: z.null(),
  createDate: z.string().nullable(),
  lastActivityDate: z.string().nullable(),
  contactlessStart: z.boolean().nullable(),
  lastActivityResourceID: z.number().nullable(),
  userDefinedFields: UserDefinedFieldsSchema,
  archived: z.number(),
  nextActionDate: z.string().nullable(),
  nextActionNote: z.string().nullable(),
  queue: z.string().nullable(),
  assigned_tech_email: z.string().nullable(),
  assigned_tech_name: z.string().nullable(),
  priority: z.number().nullable(),
  source: z.number().nullable(),
  ticketType: z.number().nullable(),
  ticketCategory: z.number().nullable(),
  subIssueType: z.number().nullable(),
  billable: TBillableSchema,
  nextActionTime: z.number().nullable(),
  nextActionType: z.enum(['FLEXABLE', 'FIRM']).nullable(),
  configurationItemID: z.number().nullable(),
  autotaskTodos: z.array(z.any()).optional(),
  device: DeviceSchema,
});

// ... rest of the existing code ...