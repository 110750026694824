import {  createContext, useContext } from "react";


export type CoreTimeclock = {
    id: number;
    start: string;  
    end: string | null;
    lastActive: Date;
    ticketId: number | null;
    coreUserId : string;
    autotaskUserId : number | null;
    message : string;
    at_ticketNumber: string;
    at_title: string;
    isDispatchedTicket: boolean;
    user_email: string;
    user_firstName: string;
    user_lastName: string;
    isPayroll: boolean | null | 1 | 0;
}

interface TimeclockProvider {
    activePunches: CoreTimeclock[];
    allPunches: CoreTimeclock[];
    otherUsersPunches: CoreTimeclock[];
    ready: boolean
    refreshing: boolean
    refresh: () => any
}
export const TimeclockContext = createContext<TimeclockProvider>({
    activePunches: [],
    allPunches: [],
    otherUsersPunches: [],
    ready: false,
    refreshing: false,
    refresh: () => {}
});

export const useTimeclock = () => useContext( TimeclockContext );