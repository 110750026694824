import { FC } from "react";
import { FormField, FormFieldTypes } from "../../../coretypes/formfields";
import { GetFormByIdQuery, useGetFormByIdQuery } from "../../../generated/graphql";
import { CoreForm } from "../CoreForm/CoreForm";

type GqlFormField = NonNullable<NonNullable<GetFormByIdQuery['getFormsInstanceById']['Form']>['FormFields']>[number];
const gqlFormFieldToFormField = (gqlFormField: GqlFormField): FormField => {
    return {
        id: gqlFormField.id || 0,
        formId: gqlFormField.formId || 0,
        type: gqlFormField.type as FormFieldTypes,
        name: gqlFormField.name,
        label: gqlFormField.label,
        config: gqlFormField.config
    }
}

export const FormView : FC<{
    formInstanceId: number;
}> = ({ formInstanceId }) => {
    const { data, loading, error } = useGetFormByIdQuery({
        variables: {
            id: Number(formInstanceId)
        }
    });
    const formFields = (data?.getFormsInstanceById?.Form?.FormFields || [] ).map(gqlFormFieldToFormField); 
    const values = data?.getFormsInstanceById?.values || {};
    const form = data?.getFormsInstanceById?.Form || {};
    if(!form) return null;
    return <CoreForm readonly viewMode form={{formFields}} values={values} />
}