export type GetScheduleInstanceColorInstance = {
    available? : boolean| null;
    schedulePlanId? : number| null;
    azureCalendarId? : string| null;
    conflictDetected? : boolean| null;
    coreUserId? : string| null;
    AzureCalendar?: Record<string,any> | null;
    SchedulePlan?: Record<string,any> | null;
}
/*
    https://colorffy.com/color-scheme-generator?color=769D3E
    #3e9d95
    #663e9d
    #3e759d
*/

export const AVAILABLE_COLOR = '#B2B29E';
export const CONFLICT_COLOR = '#9d3e46';
export const AZURE_COLOR = '#3e759d';
export const AZURE_COLOR_HOVER = '#6490b0';
export const SCHEDULE_COLOR = '#769D3E';
export const SCHEDULE_COLOR_HOVER = '#91b064';
export const UNSET_COLOR = '#3e9d95';
export const getScheduleInstanceColor = ( instance : GetScheduleInstanceColorInstance  & Record<string,any>) => {
    
    if( instance.available ){
        return AVAILABLE_COLOR;
    }
    if( instance.conflictDetected || !instance.coreUserId ){
        return  CONFLICT_COLOR;
    }
    if( instance.AzureCalendar){
        return AZURE_COLOR;
    }
    if( instance.SchedulePlan?.name ){
        return SCHEDULE_COLOR;
    }
    return UNSET_COLOR;
}
